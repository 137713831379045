import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Image, Text } from 'wikr-core-components';

// img
import phoneWithRecipes from './img/phoneWithRecipes.webp';
import { ReactComponent as Chief } from './img/chief.svg';

const MobileAppCook = () => {
    const { t } = useTranslation();

    return (
        <S.MobileAppCookBlock borderRadius={24} mb={48}>
            <S.ImgWrap>
                <Image isForceVisible once maxWidth={240} alt="app" src={phoneWithRecipes} />
            </S.ImgWrap>
            <Text center type="h5" medium mb={12} text={t('myFastingPlan.appCook.title')} />
            <S.SmallOval>
                <Chief />
                <Text text={t('myFastingPlan.appCook.recipes')} />
            </S.SmallOval>
        </S.MobileAppCookBlock>
    );
};

export default MobileAppCook;
