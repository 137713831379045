import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from 'api';

// actions
import { fetchWeightStatistic, setWeightStatistic, setWeightStatisticStatus } from './actions';
import { notifyError } from '../notifications/actions';
import * as actionTypes from './actionTypes';

// types
import { UserWeightStatistic } from 'types/user/userApiInterface';

function* getWeightStatistic({ payload }: ReturnType<typeof fetchWeightStatistic>) {
    yield put(setWeightStatisticStatus(true));
    const { fromDate } = payload;

    try {
        const response: { items: UserWeightStatistic[] } = yield call(api.user.getWeightStatistic, fromDate);

        yield put(setWeightStatistic(response.items));
        yield put(setWeightStatisticStatus(false));
    } catch (error) {
        notifyError('getWeightStatistic error');
        yield put(setWeightStatisticStatus(false));
    }
}

export default function* watchDashboard() {
    yield all([takeLatest(actionTypes.FETCH_WEIGHT_STATISTIC, getWeightStatistic)]);
}
