import styled from 'styled-components';
import { Box, Text } from 'wikr-core-components';
import { animated } from '@react-spring/web';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const DiscoverBlock = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 16px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 120px 16px;
    }
`;

export const ContentAnimatedWrap = styled(animated.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled(Text)`
    text-align: center;
    margin-bottom: 24px;
    max-width: 818px;

    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        margin-bottom: 32px;
    }
`;
