import { uaParser } from 'wikr-core-analytics';

const UAParser = new uaParser();

export const isSafari = () => {
    const userAgent = UAParser.setUA(window.navigator.userAgent);
    const deviceBrowser = userAgent?.getBrowser().name;

    return deviceBrowser === 'Safari' || deviceBrowser === 'Mobile Safari';
};
