import { Trans } from 'react-i18next';
import { useInView } from '@react-spring/web';

// components
import * as S from './styled';
import { Image } from 'wikr-core-components';

export const DiscoverBlock = ({ title, img }: { title: string; img: string }) => {
    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
        }),
        {
            rootMargin: '-40% 0%',
        }
    );

    return (
        <S.DiscoverBlock backgroundColor="surface-secondary">
            <S.ContentAnimatedWrap ref={ref} style={springs}>
                <S.Title type="h2" medium>
                    <Trans i18nKey={title} components={{ br: <br /> }} />
                </S.Title>
                <Image once isForceVisible maxWidth={300} alt="discoverImg" src={img} />
            </S.ContentAnimatedWrap>
        </S.DiscoverBlock>
    );
};
