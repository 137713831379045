import React from 'react';
import { Image, Text } from 'wikr-core-components';
import * as S from '../styled';
import { useTranslation } from 'react-i18next';

// components
import OutlinedButton from 'components/OutlinedButton';
import FilledButton from 'components/FilledButton';
import DeleteInput from './DeleteInput';

// img
import loader from '../img/loader.svg';

// types
import { IDeleteModal } from '../types';

interface DeleteValidationProps {
    captions: IDeleteModal['captions'];
    isLoading: boolean;
    isValid: boolean;
    setIsValid: (status: boolean) => void;
    onDiscard: () => void;
    onSubmit: () => void;
}

const DeleteValidation = ({ captions, isLoading, isValid, setIsValid, onDiscard, onSubmit }: DeleteValidationProps) => {
    const { t } = useTranslation();

    const placeholderText = t('account.settings.deletionModal.input.placeholder');

    return (
        <>
            <Text type="h5" center color="text-main" text={captions.title} mb={12} />

            {isLoading ? (
                <S.ImageContainer>
                    <Image maxWidth={24} center alt="loader" src={loader} mb={24} isForceVisible />
                </S.ImageContainer>
            ) : (
                <>
                    <Text type="medium-text" center color="text-secondary" text={captions.description} mb={32} />
                    <DeleteInput setIsValidate={setIsValid} label={placeholderText} placeholder={placeholderText} />

                    <S.BtnsContainer>
                        <OutlinedButton
                            isDisplayFlex
                            justifyContent="center"
                            size="small"
                            onClick={onDiscard}
                            text={captions.discardButton}
                            dataLocator="deleteAccountCancelBtn"
                        />
                        <FilledButton
                            isDisplayFlex
                            justifyContent="center"
                            size="small"
                            disabled={!isValid}
                            onClick={onSubmit}
                            text={captions.submitButton}
                            dataLocator="deleteAccountConfirmBtn"
                        />
                    </S.BtnsContainer>
                </>
            )}
        </>
    );
};

export default DeleteValidation;
