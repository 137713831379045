import * as S from './styled';
import { Image, Text } from 'wikr-core-components';

interface AppPreviewBlockProps {
    title: string;
    image: string;
}

const AppPreviewBlock = ({ title, image }: AppPreviewBlockProps) => {
    return (
        <S.AppPreviewContainer>
            <Text text={title} type="h6" medium mb={16} center />
            <Image alt="product" src={image} maxWidth={376} />
        </S.AppPreviewContainer>
    );
};

export default AppPreviewBlock;
