import { useTranslation } from 'react-i18next';

// components
import { Text } from 'wikr-core-components';
import BlockPreview from 'components/BlockPreview';

// styled
import * as S from './styled';

// types
import { IBlockById, IBlockItem } from 'types/insights';

const InsightCategoryBlockPreview = ({
    isPreviewLayoutLoading,
    block,
    blockPreviewLayout,
}: {
    isPreviewLayoutLoading: boolean;
    block: IBlockItem;
    blockPreviewLayout: IBlockById | null;
}) => {
    const { t } = useTranslation();

    return (
        <S.InsightCategoryBlockContainer>
            <S.InsightCategoryBlockMainWithBackGround background={block.color}>
                <S.InsightCategoryBlockText>
                    <Text text={t('insights.story')} color="text-secondary" uppercase type="overline" mb={12} />
                    <Text text={block.name} type="h6" />
                </S.InsightCategoryBlockText>
            </S.InsightCategoryBlockMainWithBackGround>
            <S.InsightCategoryBlockPreviewWrap>
                <BlockPreview
                    isPreviewLayoutLoading={isPreviewLayoutLoading}
                    articles={blockPreviewLayout?.articles || []}
                    blockPreviewColor={blockPreviewLayout?.color || ''}
                    skeletonColor={block.color}
                />
            </S.InsightCategoryBlockPreviewWrap>
        </S.InsightCategoryBlockContainer>
    );
};

export default InsightCategoryBlockPreview;
