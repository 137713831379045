import React from 'react';
import ContentLoader from 'react-content-loader';
import { withTheme } from 'styled-components';
import { Theme } from 'wikr-core-components';

// components
import { SkeletonBlock } from './styled';

const BLOCKS_AMOUNT = 11;

const BlocksSkeleton = ({ theme }: { theme: Theme }) => {
    return (
        <>
            {Array.from(Array(BLOCKS_AMOUNT).keys()).map((_, index) => (
                <SkeletonBlock key={index}>
                    <ContentLoader
                        foregroundColor={theme['surface-main']}
                        backgroundColor={theme['surface-default']}
                        style={{ width: '100%', height: '100%' }}
                        animate
                    >
                        <rect x="0" y="0" width="100%" height="100%" />
                    </ContentLoader>
                </SkeletonBlock>
            ))}
        </>
    );
};

export default withTheme(BlocksSkeleton);
