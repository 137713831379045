import React from 'react';
import { useTranslation } from 'react-i18next';

// store
import { selectCurrentUser } from 'store/user/selectors';

// hooks
import { useSelector } from 'hooks/store';

// helpers
import { getFormattedDateByCountry } from 'helpers/date';
import { getPriceFromCents } from 'helpers/prices';
import { replaceUnderscoreToDash } from 'helpers/utils';

// components
import { Text } from 'wikr-core-components';
import StatusIcon from './StatusIcon';
import PriceRow from './PriceRow';

// styled
import * as S from './styled';

// types
import { IUpcomingProductProps } from '../types';
import { UserStore } from 'types/store/userStore';

const UpcomingProduct = ({ isTabExpanded, upcomingProduct }: IUpcomingProductProps) => {
    const { t } = useTranslation();

    const currentUser = useSelector(selectCurrentUser) as UserStore;

    const autoRenewalDate = getFormattedDateByCountry(upcomingProduct.started_from, currentUser?.country);

    return (
        <S.GeneralInfo mb={0} mt={24}>
            <S.Status>
                <StatusIcon isActive={false} />
                <Text type="caption" color="text-secondary" text={t('subscription.status.upcoming')} />
                <Text type="caption" color="text-secondary" text=":" />
            </S.Status>
            <PriceRow
                price={`${getPriceFromCents(upcomingProduct.amount)} ${upcomingProduct.currency}`}
                period={upcomingProduct.subscription_period}
                subscriptionType={t('subscription.type.subscription')}
            />
            {isTabExpanded && (
                <S.DateInfo>
                    <Text type="caption" color="text-secondary" text={t('subscription.text.autoRenewalOn')} />
                    <Text type="caption" color="text-secondary">
                        {replaceUnderscoreToDash(autoRenewalDate)}
                    </Text>
                </S.DateInfo>
            )}
        </S.GeneralInfo>
    );
};

export default UpcomingProduct;
