import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const GreenTipBlock = styled(Box)`
    display: flex;
    align-items: center;
    max-width: 402px;
    width: 100%;
    background: rgba(203, 224, 222, 0.5);
`;

export const CheckSVGWrap = styled.div`
    margin-right: 16px;
`;
