import React from 'react';
import * as S from './styled';
import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

interface LifeTimeBadgeProps {
    isAbsolute?: boolean;
}

const LifeTimeBadge = ({ isAbsolute = false }: LifeTimeBadgeProps) => {
    const { t } = useTranslation();

    return (
        <S.DiscountWrapper isAbsolute={isAbsolute}>
            <S.DiscountTextWrapper>
                <Text
                    text={t('subscription.cancellation.lifeTimeAccessQuestion.lifetime')}
                    type="overline"
                    bold
                    center
                    ellipsis={1}
                    inline
                />
            </S.DiscountTextWrapper>
        </S.DiscountWrapper>
    );
};

export default LifeTimeBadge;
