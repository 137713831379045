import styled from 'styled-components';
import { Box, Text } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

import { CONTENT_1 } from 'constants/customContentColors';

export const WasThisHelpfulBlock = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px 80px;

    ${mediaQueriesGrid.tablet} {
        padding: 0 32px 80px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 0 64px 160px;
    }
`;

export const ContentContainer = styled(Box)`
    background-color: ${CONTENT_1};
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 20px;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
        padding: 64px 20px 80px;
    }
`;

export const Title = styled(Text)`
    font-size: 24px;
    line-height: 32px;

    ${mediaQueriesGrid.desktop} {
        font-size: 36px;
        line-height: 44px;
    }
`;

export const OptionsWrap = styled(Box)`
    display: flex;
`;
