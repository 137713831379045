/* eslint-disable max-lines */
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

// config
import config from 'config';

// services
import sentry from 'services/Sentry/SentryInstance';

// types
import { RequestOptions } from './types/request';
import { ErrorResponse } from './types/response';

// constants
import { USER_SIGNATURE_URL, FRONT_HASH_URL } from './constants/urls';
import { SENTRY_AXIOS, ERROR_LEVELS } from 'sentry-utils';

// helpers
import { getToken, setToken } from './utils/tokenManagement';
import { isAxiosError } from './utils/axiosHandlers';
import { getLanguage } from './utils/languageManagment';

const PLATFORM_CODE = config?.PLATFORM_CODE || 3;

export default class ApiClientMethods {
    apiUrl: string;
    apiVersion: string;
    apiKey: string;
    isSandbox: boolean;
    isPrivate: boolean;
    instance: AxiosInstance;
    requiredHeaders: string[];
    exclusionEndpoints: string[];

    constructor({ apiUrl = config.API_URL, isSandbox = false, isPrivate = false }) {
        this.apiUrl = apiUrl;
        this.apiVersion = config.API_VERSION;
        this.apiKey = config.API_KEY;
        this.isSandbox = isSandbox;
        this.isPrivate = isPrivate;
        this.instance = this.createAxiosInstance();
        this.requiredHeaders = ['version', 'x-api-key', 'language', 'platform'];
        this.exclusionEndpoints = [USER_SIGNATURE_URL, FRONT_HASH_URL];
    }

    createAxiosInstance() {
        const axiosInstance: AxiosInstance = axios.create({
            baseURL: this.apiUrl,
        });

        axiosInstance.defaults.headers.common['version'] = this.apiVersion;
        axiosInstance.defaults.headers.common['x-api-key'] = this.apiKey;
        axiosInstance.defaults.headers.common['language'] = getLanguage();
        axiosInstance.defaults.headers.common['platform'] = PLATFORM_CODE;

        return axiosInstance;
    }

    createTmpOptions<RequestData>(options: RequestOptions<RequestData>, token: string | null) {
        const tmpOptions = { ...options, url: `/${options.url}` };

        tmpOptions.params = tmpOptions.params || {};
        tmpOptions.headers = {
            ...tmpOptions.headers,
            'Content-Type': 'application/json',
            ...(token && this.isPrivate && { token }),
            ...(this.isSandbox && { sandbox: true }),
        };

        return tmpOptions;
    }

    headerIsMissing(common: AxiosRequestHeaders) {
        return this.requiredHeaders.some((header) => !common[header]);
    }

    async get<ResponseData>(url: string, params?: Record<string, string>, headers?: Record<string, string | boolean>) {
        return this.request<unknown, ResponseData>({
            url,
            params,
            headers,
            method: 'GET',
            data: {},
        });
    }

    async post<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData, ResponseData>({
            url,
            method: 'POST',
            data: payload,
        });
    }

    async put<RequestData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData>({
            url,
            method: 'PUT',
            data: payload,
        });
    }

    async patch<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData, ResponseData>({
            url,
            method: 'PATCH',
            data: payload,
        });
    }

    async delete<RequestData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData>({
            url,
            method: 'DELETE',
            data: payload,
        });
    }

    async request<RequestData, ResponseData = never>(options: RequestOptions<RequestData>): Promise<ResponseData> {
        const token = getToken();

        if (!token && this.isPrivate) {
            sentry.logError(new Error('Token is required for private method'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
                label: 'Token Required',
            });

            return Promise.reject('Token is required for private method');
        }

        this.instance.defaults.headers.common['language'] = getLanguage();

        if (token) this.instance.defaults.headers.common['token'] = token;

        const { common } = this.instance.defaults.headers;

        if (this.headerIsMissing(common)) {
            sentry.logError(new Error('Required header is missing'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
                ...common,
            });

            return Promise.reject('Required header is missing');
        }

        const tmpOptions = this.createTmpOptions(options, token);

        try {
            const response = await this.instance(tmpOptions);

            if (response.headers.token) {
                setToken(response.headers.token);
                response.data.token = response.headers.token;
            }
            if (response.headers.country) response.data.country = response.headers.country;

            return Promise.resolve(response.data);
        } catch (error) {
            const typedError = error as ErrorResponse;
            const errorResponse = typedError?.response;

            if (errorResponse.status >= 500) {
                sentry.logError(typedError, SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, { ...tmpOptions, ...errorResponse });
            }

            if (!isAxiosError(typedError)) {
                sentry.logError(new Error('Untracked error'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
                    ...tmpOptions,
                    ...errorResponse,
                });

                return Promise.reject('Untracked error');
            }

            if (!errorResponse?.data) {
                sentry.logError(
                    new Error('Axios error is tracked, but response is missing'),
                    SENTRY_AXIOS,
                    ERROR_LEVELS.CRITICAL,
                    { ...tmpOptions, ...errorResponse }
                );

                return Promise.reject('Axios error is tracked, but response is missing');
            }

            return Promise.reject(errorResponse?.data || 'Untracked error');
        }
    }
}
