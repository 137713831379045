import styled from 'styled-components';

// components
import { Box } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';

export const ContentWrap = styled(Box)`
    width: 100%;
    max-width: 333px;
    margin: 0 auto 51px;
    border: 3px solid ${color('on-primary')};
    border-radius: 16px;
    display: flex;
`;

export const ImgWrap = styled.div`
    & > div > img {
        height: 84px;
        width: 84px;
    }
`;

export const TextWrap = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 0 23px 0 0;
`;
