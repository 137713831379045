import Base from './Base';

// types
import { IDiscountSubscription, ISubscription, IUpdateSubscription } from 'types/subscription';

import { SubscriptionCancelResponse } from 'api/types/response';
import { CancelSubscriptionRequest } from 'api/types/request';

class SubscriptionsApi extends Base {
    getSubscriptions = async <Response = ISubscription>(): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.get<Response>('user/subscription');
    };

    unsubscribe = async <Data = CancelSubscriptionRequest, Response = SubscriptionCancelResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<Data, Response>('solidgate/subscription/cancel', payload);
    };

    getDiscountSubscriptions = async <Response = IDiscountSubscription>(payload: {
        external_id: string;
    }): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<typeof payload, Response>('solidgate/subscription/discount', payload);
    };

    updateSubscription = async <Response = IDiscountSubscription>(
        payload: IUpdateSubscription
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<typeof payload, Response>('solidgate/subscription/switch', payload);
    };
}

export default SubscriptionsApi;
