import React from 'react';

// components
import { Text } from 'wikr-core-components';

// styled
import * as S from './styled';

interface DescriptionProps {
    hours: string;
    meaning: string;
}

const Description = ({ hours, meaning }: DescriptionProps) => {
    return (
        <S.Description>
            <Text type="small-text" bold text={hours} />
            <Text type="medium-text" text={meaning} />
        </S.Description>
    );
};

export default Description;
