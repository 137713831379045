import { CategoryContainer } from '../CategoryList/styled';

// helpers
import { getBlocksSize } from 'pages/insights-main/helpers';

// components
import CategoryBlockSkeleton from '../CategoryBlock/CategoryBlockSkeleton';

interface CategoryListProps {
    blocks: number;
    insightIndex: number;
}

function CategoryListSkeleton({ blocks, insightIndex }: CategoryListProps) {
    const blockSize = getBlocksSize(insightIndex);

    return (
        <CategoryContainer blockSize={blockSize}>
            {Array.from(Array(blocks).keys()).map((item, index) => (
                <CategoryBlockSkeleton index={index} blockSize={blockSize} key={index} />
            ))}
        </CategoryContainer>
    );
}

export default CategoryListSkeleton;
