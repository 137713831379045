import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// analytics
import { sendAnalytichInsightCardClick } from 'services/analytics/trackers/dashboard';

// router
import routes from 'router/routes';

// hooks
import { useDispatch } from 'hooks';

// redux
import { fetchBlockPreview } from 'store/insights/actions';

export const useDashboardInsights = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [currentBlockId, setCurrentBlockId] = useState<number | null>(null);

    const getHandleBlockClick = (insightId: number) => (blockId: number) => {
        sendAnalytichInsightCardClick(insightId, blockId);
        setCurrentBlockId(blockId);
        dispatch(fetchBlockPreview(blockId));

        navigate(`${routes.PRIVATE.DASHBOARD.path}/${insightId}/${blockId}`);
    };

    return {
        currentBlockId,
        setCurrentBlockId,
        getHandleBlockClick,
    };
};
