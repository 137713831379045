import { useEffect } from 'react';
import dayjs from 'dayjs';

// hooks
import { useDispatch } from 'hooks';

// redux
import { fetchWeightStatistic } from 'store/dashboard/actions';
// constants
import { DATE_FORMAT_ASIAN_TYPE } from 'constants/dateFormats';

export const useWeightStatistic = () => {
    const dispatch = useDispatch();

    const firstDayOfWeek = dayjs().day(1);

    useEffect(() => {
        dispatch(fetchWeightStatistic(firstDayOfWeek.format(DATE_FORMAT_ASIAN_TYPE)));
    }, []);
};
