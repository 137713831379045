import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Box } from 'wikr-core-components';

// components
import OverlineButton from 'components/OverlineButton';
import Modal from 'components/Modal';

// constants
import { ANSWERS } from './config';

// styled
import * as S from './styled';

interface SurveyModalProps {
    title: string;
    onSubmit: (answer: string) => void;
    onDiscard: () => void;
}

const SurveyModal = ({ title, onSubmit, onDiscard }: SurveyModalProps) => {
    const { t } = useTranslation();

    const [answer, setAnswer] = useState<string | null>(null);

    const handleSelectReason = (shortKey: string) => () => setAnswer(shortKey);

    const handleDiscard = () => {
        setAnswer(null);
        onDiscard();
    };

    return (
        <Modal isOpen>
            <S.ReasonContainer paddingBottom={4}>
                <Text type="h5" color="text-main" center mb={24} text={title} />
                <Box mb={32}>
                    {ANSWERS.map(({ title, value }) => (
                        <S.ReasonItem
                            key={value}
                            onClick={handleSelectReason(value)}
                            isSelected={value === answer}
                            data-locator="option"
                        >
                            <Text type="medium-text" bold text={t(title)} />
                        </S.ReasonItem>
                    ))}
                </Box>

                <Button
                    disabled={!answer}
                    onClick={() => answer && onSubmit(answer)}
                    mb={20}
                    text={t('basics.continue')}
                    dataLocator="obContinue"
                />
                <OverlineButton
                    text={t('subscription.cancelDialog.discard')}
                    onClick={handleDiscard}
                    dataLocator="keepSubBtn"
                />
            </S.ReasonContainer>
        </Modal>
    );
};

export default memo(SurveyModal);
