import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const Container = styled(Box)`
    width: 100%;
    padding: 32px 16px 160px;

    ${mediaQueries.laptop} {
        padding: 32px 32px 80px;
    }

    ${mediaQueries.theSmallestDesktop} {
        padding: 32px 32px 160px;
    }
`;

export const ContentContainer = styled(Box)`
    max-width: 844px;
    margin: 0 auto;

    ${mediaQueries.theSmallestDesktop} {
        max-width: 1028px;
    }
`;
