import { useTranslation } from 'react-i18next';

// hooks
import { useSelector } from 'hooks';

// redux
import { selectUserFirstName } from 'store/user/selectors';

// image
import accessGranted from './img/accessGranted.svg';
import phone from './img/phone.webp';
import arrowDown from './img/arrowDown.svg';
import weights from './img/weights.webp';
import clock from './img/clock.webp';

// components
import { Image } from 'wikr-core-components';

// styled
import * as S from './styled';

const SUBTITLES = ['welcome.welcomeBlock.subtitle.list.item1', 'welcome.welcomeBlock.subtitle.list.item2'];

interface WelcomeBlockProps {
    onClick: () => void;
}

export function WelcomeBlock({ onClick }: WelcomeBlockProps) {
    const { t } = useTranslation();
    const firstName = useSelector(selectUserFirstName);

    return (
        <S.Container>
            <S.BlockContainer>
                <S.LeftImage alt="left" src={weights} />
                <S.RightImage alt="right" src={clock} />

                <S.AppealTitle
                    type="large-text"
                    medium
                    center
                    // @ts-ignore
                    dataLocator="welcomeText"
                    text={t('welcome.welcomeBlock.appealTitle', { firstName })}
                />
                <S.Title type="h2" medium center text={t('welcome.welcomeBlock.title')} mb={32} />
                {SUBTITLES.map((key) => (
                    <S.SubTitleWrapper key={key}>
                        <Image once isForceVisible alt="Granted" src={accessGranted} maxWidth={16} />
                        <S.SubTitle type="large-text" text={t(key)} />
                    </S.SubTitleWrapper>
                ))}
                <S.ScrollBtn data-locator="scrollDownButton" onClick={onClick}>
                    <S.BtnImage alt="phone" src={phone} />
                    <S.BtnText type="large-text" text={t('welcome.welcomeBlock.scrollBtn')} />
                    <S.BtnArrow isForceVisible once alt="arrow" src={arrowDown} />
                </S.ScrollBtn>
            </S.BlockContainer>
        </S.Container>
    );
}
