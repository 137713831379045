import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// hooks
import { useSelector } from 'hooks';

// components
import Modal from 'components/Modal';
import ProductCard from './components/ProductCard';
import OutlinedButton from 'components/OutlinedButton';

// styled
import * as S from './styled';

// config
import { getAppPreviewConfig } from './config';

const MODAL_OVERRIDDEN_STYLES = {
    content: {
        height: '100%',
        width: '100%',
        borderRadius: '0px',
        maxWidth: '100%',
        background: '#F2EFEE',
        padding: '0',
        overflow: 'scroll',
    },
    overlay: { padding: '0' },
};

interface CheckOurAppsModalProps {
    onContinue: (projectName: string) => void;
    onExpand: (projectName: string) => void;
    onCancelSubscription: () => void;
}

const CheckOurAppsModal = ({ onContinue, onCancelSubscription, onExpand }: CheckOurAppsModalProps) => {
    const { t } = useTranslation();
    const gender = useSelector(({ user }) => user.gender);

    const config = getAppPreviewConfig(t, gender);

    return (
        <Modal isOpen style={MODAL_OVERRIDDEN_STYLES}>
            <S.Container>
                <Text type="h5" mb={12} center>
                    <Trans i18nKey="subscription.cancellation.checkOurApps.title" components={{ br: <br /> }} />
                </Text>
                <Text text={t('subscription.cancellation.checkOurApps.subtitle')} type="medium-text" mb={24} center />
                <S.ProductsContainer>
                    {config.map(({ header, rating, appPreviewData, programs, programsDisclaimer, profits }, index) => (
                        <ProductCard
                            key={index}
                            header={header}
                            rating={rating}
                            appPreviewData={appPreviewData}
                            programs={programs}
                            programsDisclaimer={programsDisclaimer}
                            profits={profits}
                            onContinue={onContinue}
                            onExpand={onExpand}
                        />
                    ))}
                </S.ProductsContainer>
                <OutlinedButton
                    onClick={onCancelSubscription}
                    text={t('subscription.cancellation.checkOurApps.сontinueСanceling.button')}
                    mb={20}
                    dataLocator="cancelSubTrueBtn"
                    isBackgroundWhite
                />
            </S.Container>
        </Modal>
    );
};

export default memo(CheckOurAppsModal);
