import { animated } from '@react-spring/web';

// styled
import styled from 'styled-components';

// components
import { Text } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const SmallBenefit = styled(animated.div)<{ isLastBenefit: boolean }>`
    width: 100%;
    margin-bottom: 48px;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${mediaQueriesGrid.tablet} {
        &:nth-of-type(1) {
            padding: 0 360px 0 0;
        }

        &:nth-of-type(2) {
            padding: 0 180px;
        }

        &:nth-of-type(3) {
            padding: 0 0 0 360px;
        }
    }

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 0;
        width: ${({ isLastBenefit }) => (isLastBenefit ? '66%' : '33%')};

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
            padding: 0 56px;
        }

        &:nth-of-type(2) {
            margin-top: 140px;
        }

        &:nth-of-type(3) {
            margin-top: 280px;
        }
    }
`;

export const Subtitle = styled.div<{ withMB: boolean }>`
    padding: 12px 16px;
    background-color: ${color('surface-main')};
    margin-bottom: 20px;
    border-radius: 12px;
`;

export const DescriptionsWrap = styled.div`
    width: 100%;
`;

export const DescriptionNumber = styled(Text)`
    margin-right: 6px;
`;

export const OneDescriptionWrap = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;
