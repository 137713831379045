import styled, { css } from 'styled-components';

// theme
import mediaQueriesGrid from 'theme/mediaQueriesGrid';
import { color } from 'theme/selectors';

// components
import { Box } from 'wikr-core-components';

export const ComponentWrap = styled(Box)`
    width: 100%;
    border-radius: 24px;
    margin-bottom: 16px;

    ${mediaQueriesGrid.tablet} {
        border-radius: 32px;
        margin-bottom: 20px;
    }

    ${mediaQueriesGrid.largeTablet} {
        display: flex;
        min-height: 144px;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
    }
`;

export const Header = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Footer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const OneDay = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const SvgWrap = styled.div<{ filled: boolean }>`
    width: 24px;
    height: 24px;
    margin-bottom: 4px;

    ${({ filled }) =>
        filled &&
        css`
            > svg > g > path {
                fill: ${color('primary-default')};
            }
        `}

    ${mediaQueriesGrid.tablet} {
        width: 40px;
        height: 40px;
    }
`;
