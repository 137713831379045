import React from 'react';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import { Text } from 'wikr-core-components';

// components
import { CheckCircle, ErrorIcon } from 'components/Icon';

// constants
import { NOTIFY_STATUSES } from 'constants/notifications';

const Message = ({ message, dataLocator }: { message: string; dataLocator?: string }) => (
    // @ts-ignore
    <Text type="small-text" color="text-secondary" dataLocator={dataLocator}>
        <Trans i18nKey={message} />
    </Text>
);

const showNotify = ({ message, status = NOTIFY_STATUSES.DEFAULT }: { message: string; status: NOTIFY_STATUSES }) => {
    if (status === NOTIFY_STATUSES.SUCCESS) {
        return toast.success(<Message message={message} dataLocator="successAlertbox" />, {
            icon: <CheckCircle />,
        });
    }
    if (status === NOTIFY_STATUSES.ERROR) {
        return toast.error(<Message message={message} dataLocator="failedAlertbox" />, {
            icon: <ErrorIcon />,
        });
    }

    return toast(<Message message={message} />);
};

export default showNotify;
