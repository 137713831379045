// components
import CancellationReasonModal from './components/CancellationReasonModal';

// hooks
import { useSelector, useCancelSubscription } from 'hooks';

// helpers
import { useCancelSubscriptionModals } from 'hooks/subscriptions/useCancelSubscriptionModals';

// redux
import { selectSubscriptions } from 'store/subscriptions/selectors';
const CancellationReasonModalContainer = () => {
    const { isDiscountLoading } = useSelector(selectSubscriptions);
    const { discardCancellation } = useCancelSubscription();
    const { cancelSubscriptionHandler } = useCancelSubscriptionModals();

    return (
        <CancellationReasonModal
            isOpen
            isLoading={isDiscountLoading}
            onSubmit={cancelSubscriptionHandler}
            onDiscard={discardCancellation}
            onClose={discardCancellation}
        />
    );
};

export default CancellationReasonModalContainer;
