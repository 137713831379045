import styled from 'styled-components';

// components
import { Box, Image } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 16px;
    flex: 1;
    width: 100%;

    ${mediaQueriesGrid.tablet} {
        flex-direction: row;
    }
`;

export const Banner = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0;
    background: ${color('surface-secondary')};
    border-radius: 16px;
    flex: 2;
    width: 100%;

    ${mediaQueriesGrid.tablet} {
        margin-right: 24px;
    }

    ${mediaQueriesGrid.largeTablet} {
        margin-right: 0;
        flex: 1;
        align-items: flex-end;
    }

    ${mediaQueriesGrid.desktop} {
        flex: 1.5;
    }
`;

export const BannerIcon = styled(Image)`
    border-radius: 16px;
`;

export const BannerTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px 16px 0;
    gap: 16px;
`;

export const Divider = styled.div`
    width: 100%;
    border: 1px solid ${color('border-subdued')};
`;
