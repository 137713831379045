// store
import * as actionTypes from './actionTypes';
import * as action from './actions';

// types
import { InferValueTypes } from 'types/commonTypes';
import { IBlockById, IBlockItem, ICategories } from 'types/insights';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

interface InsightsState {
    userInsights: ICategories;
    blocksByCategory: IBlockItem[];
    isLoading: boolean;
    isPreviewLayoutLoading: boolean;
    isBlocksNextLoading: boolean;
    isNextLoading: boolean;
    isPrevLoading: boolean;
    blockPreview: null | IBlockById;
    blockPreviewLayout: null | IBlockById;
    blockNextPreview: null | IBlockById;
    blockPrevPreview: null | IBlockById;
}

const initialState: InsightsState = {
    userInsights: [],
    blocksByCategory: [],
    isLoading: true,
    isPreviewLayoutLoading: true,
    isBlocksNextLoading: false,
    isNextLoading: false,
    isPrevLoading: false,
    blockPreview: null,
    blockPreviewLayout: null,
    blockNextPreview: null,
    blockPrevPreview: null,
};

const insightsReducer = (state = initialState, action: ActionType): InsightsState => {
    switch (action.type) {
        case actionTypes.SET_LOADING_STATE:
            return { ...state, isLoading: action.payload };
        case actionTypes.SET_PREVIEW_LAYOUT_LOADING_STATE:
            return { ...state, isPreviewLayoutLoading: action.payload };
        case actionTypes.SET_NEXT_LOADING_STATE:
            return { ...state, isNextLoading: action.payload };
        case actionTypes.SET_PREV_LOADING_STATE:
            return { ...state, isPrevLoading: action.payload };

        case actionTypes.SET_INSIGHTS:
            return { ...state, isLoading: false, userInsights: action.payload };

        case actionTypes.SET_BLOCKS_BY_CATEGORY:
            return { ...state, isLoading: false, blocksByCategory: action.payload };
        case actionTypes.SET_NEXT_BLOCKS_IS_LOADING:
            return { ...state, isBlocksNextLoading: action.payload };
        case actionTypes.SET_NEXT_BLOCKS_BY_CATEGORY:
            return { ...state, isLoading: false, blocksByCategory: [...state.blocksByCategory, ...action.payload] };

        case actionTypes.SET_BLOCK_PREVIEW:
            return { ...state, isLoading: false, blockPreview: action.payload };
        case actionTypes.SET_BLOCK_PREVIEW_LAYOUT:
            return { ...state, isLoading: false, blockPreviewLayout: action.payload };
        case actionTypes.SET_NEXT_BLOCK_PREVIEW:
            return { ...state, isLoading: false, blockNextPreview: action.payload };
        case actionTypes.SET_PREV_BLOCK_PREVIEW:
            return { ...state, isLoading: false, blockPrevPreview: action.payload };

        case actionTypes.CHANGE_CURRENT_NEXT_BLOCK_PREVIEW:
            return {
                ...state,
                blockPreview: state.blockNextPreview,
                blockNextPreview: null,
                blockPrevPreview: state.blockPreview,
                isNextLoading: true,
            };
        case actionTypes.CHANGE_CURRENT_PREV_BLOCK_PREVIEW:
            return {
                ...state,
                blockPreview: state.blockPrevPreview,
                blockNextPreview: state.blockPreview,
                blockPrevPreview: null,
                isPrevLoading: true,
            };
        case actionTypes.RESET_PREVIEW:
            return {
                ...state,
                blockPreview: null,
                blockNextPreview: null,
                blockPrevPreview: null,
            };

        default:
            return state;
    }
};
export default insightsReducer;
