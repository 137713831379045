import { Trans, useTranslation } from 'react-i18next';

// components
import { Text } from 'wikr-core-components';
import Modal from 'components/Modal';

// styled
import * as S from './styled';

// types
import { OptionContent } from '../../types';

interface ContentModalProps {
    isOpen: boolean;
    content: OptionContent;
    onClose: () => void;
}

export const ContentModal = ({ isOpen, content, onClose }: ContentModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick withCloseButton>
            <S.ContentContainer>
                <S.HoursContainer>
                    <S.TextHours>
                        <Trans
                            i18nKey={content.hoursKey}
                            values={{ hours: content.hours }}
                            components={{ span: <S.HoursTitle /> }}
                        />
                    </S.TextHours>
                </S.HoursContainer>
                <Text type="h5" text={t('welcome.yourFasting.option.modal.title')} center mb={24} />
                <S.TextDescription type="large-text" text={t(content.description)} center />
            </S.ContentContainer>
        </Modal>
    );
};
