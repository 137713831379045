import { ChangeEvent } from 'react';
import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

// styled
import * as S from './styled';

interface TextFieldBlockProps {
    onChange: (value: string) => void;
    value: string;
    hasError: boolean;
}

const TextFieldBlock = ({ onChange, value, hasError }: TextFieldBlockProps) => {
    const { t } = useTranslation();

    const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange(e.target.value);
    };

    return (
        <S.TextAreaContainer>
            <Text
                type="medium-text"
                text={t('subscription.cancellation.additionalQuestion.textArea.title')}
                mb={12}
                bold
            />
            <S.TextArea value={value} onChange={onChangeHandler} rows={6} maxLength={200} hasError={hasError} />
            <S.TextError
                type="caption"
                color="critical-default"
                text={t('subscription.cancellation.additionalQuestion.textArea.error')}
                hasError={hasError}
            />
        </S.TextAreaContainer>
    );
};

export default TextFieldBlock;
