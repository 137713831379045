import React from 'react';
import ContentLoader from 'react-content-loader';
import { withTheme } from 'styled-components';
import { Theme } from 'wikr-core-components';

// types
import { IInsightBlockSize } from 'pages/insights-main/types';

// styled
import { CategoryBlockItemSkeleton } from '../CategoryListSkeleton/styled';

interface CategoryBlockProps {
    index: number;
    blockSize: IInsightBlockSize;
    theme: Theme;
}

function CategoryBlockSkeleton({ index, blockSize, theme }: CategoryBlockProps) {
    const preparedIndex = index + 1;

    return (
        <CategoryBlockItemSkeleton
            className={`blockItem${preparedIndex}`}
            key={preparedIndex}
            itemId={preparedIndex}
            blockSize={blockSize}
        >
            <ContentLoader
                foregroundColor={theme['surface-main']}
                backgroundColor={theme['surface-default']}
                style={{ width: '100%', height: '100%' }}
                animate
            >
                <rect x="0" y="0" width="100%" height="100%" />
            </ContentLoader>
        </CategoryBlockItemSkeleton>
    );
}

export default withTheme(CategoryBlockSkeleton);
