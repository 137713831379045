import { useTranslation } from 'react-i18next';
import { useInView } from '@react-spring/web';

// components
import * as S from './styled';
import { Text, Box } from 'wikr-core-components';

// types
import { ISmallBenefitProps } from './type';

export const SmallBenefit = ({ benefit, isLastBenefit = false }: ISmallBenefitProps) => {
    const { t } = useTranslation();

    const { benefitNumber, title, subtitle, descriptions = [] } = benefit;

    const [ref, springs] = useInView(() => ({
        from: {
            opacity: 0,
            y: 100,
        },
        to: {
            opacity: 1,
            y: 0,
        },
        config: {
            duration: 500,
        },
    }));

    return (
        <S.SmallBenefit isLastBenefit={isLastBenefit} ref={ref} style={springs}>
            <Text medium type="h2" text={`#${benefitNumber}`} mb={4} />
            <Text medium type="h4" text={t(title)} mb={20} />
            <S.Subtitle withMB={Boolean(descriptions?.length)}>
                <Text medium text={t(subtitle)} />
            </S.Subtitle>
            <S.DescriptionsWrap>
                {descriptions.map((description, index) => (
                    <S.OneDescriptionWrap key={index}>
                        <S.DescriptionNumber type="caption" text={`[${description.disNumber}]`} />
                        <Box>
                            <Text type="caption" text={t(description.text)} mb={4} />
                            <Text medium type="caption" text={t(description.authors)} />
                        </Box>
                    </S.OneDescriptionWrap>
                ))}
            </S.DescriptionsWrap>
        </S.SmallBenefit>
    );
};
