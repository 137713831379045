import { useState } from 'react';
import { IOption, Option } from 'wikr-core-components';

interface MultipleReasonOptionsBlockProps {
    optionsData: IOption[];
    onChange: (values: string[]) => void;
}

const MultipleReasonOptionsBlock = ({ optionsData, onChange }: MultipleReasonOptionsBlockProps) => {
    const [values, setValues] = useState<string[]>([]);

    const onChangeHandler = (value: string, checked: boolean) => {
        let optionsValue = [...values];

        if (checked) {
            optionsValue.push(value);
        } else {
            optionsValue = values.filter((item) => item !== value);
        }

        setValues(optionsValue);
        onChange(optionsValue);
    };

    return (
        <>
            {optionsData.map((option) => (
                <Option key={option.value} {...option} onClick={onChangeHandler} />
            ))}
        </>
    );
};

export default MultipleReasonOptionsBlock;
