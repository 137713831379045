import styled from 'styled-components';

// components
import { Box } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';

export const Container = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 8px;
    gap: 12px;
    flex: 1;
    border-radius: 20px;
`;

export const StatusBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    gap: 4px;
    min-width: fit-content;
`;

export const VerticalLine = styled.span`
    height: 100%;
    min-height: 48px;
    border-left: 1px solid ${color('border-default')};
    margin: 0 4px;
`;
