import styled from 'styled-components';
import { Box, Text } from 'wikr-core-components';
import { color } from 'theme/selectors';

import { CONTENT_2, CONTENT_5 } from 'constants/customContentColors';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const IntermittentFastingBlock = styled(Box)`
    background-color: ${CONTENT_2};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 32px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 120px 64px;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
    }
`;

export const IFDefinitionsBlock = styled(Box)`
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 98px;
    }
`;

export const IFHelpsBlock = styled(Box)`
    background-color: ${CONTENT_5};
    width: 100%;
    padding: 32px 20px;
    margin-bottom: 64px;

    ${mediaQueriesGrid.desktop} {
        padding: 64px 128px;
        margin-bottom: 96px;
    }
`;

export const IFHelpsTitle = styled(Text)`
    margin-bottom: 24px;

    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const IFHelpsTitleSpan = styled.span`
    color: ${color('success-default')};
`;

export const IFHelpsSubtitle = styled(Text)`
    ${mediaQueriesGrid.desktop} {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const IFPointsBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        align-items: baseline;
        flex-direction: row;
        margin-bottom: 96px;
    }
`;

export const BtnWrap = styled.div`
    display: none;

    ${mediaQueriesGrid.desktop} {
        display: block;
        max-width: 360px;
        margin: 0 auto;
        width: 100%;
    }
`;
