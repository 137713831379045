import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text, Box, Image } from 'wikr-core-components';
import { ActionEventAction } from 'wikr-core-analytics';

// subComponents
import SidebarNavButton from './components/SidebarNavButton';
import SideBarNavLink from './components/SidebarNavLink';

// routes
import routes from 'router/routes';

// constants
import { PRIMARY_SIDEBAR_LINKS, SECONDARY_SIDEBAR_LINKS } from './constants';

// hooks
import { useToggle, useDispatch, useSelector } from 'hooks';

// store
import { logout } from 'store/auth/actions';
import { selectUserFirstName } from 'store/user/selectors';

// helpers
import { getWindowSize } from 'helpers/getWindowSize';

// analytic
import { sendAnalyticSideBarElementClick, sendAnalyticSideBarLogoutClick } from 'services/analytics';

// styled
import * as S from './styled';

// images
import avatar from './img/Avatar.webp';

const isDesktop = getWindowSize(1240);

export default function SideBar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const firstName = useSelector(selectUserFirstName);

    const { isOpen, close } = useToggle();

    const navigate = useNavigate();

    const handleLinkClick = (analyticActionEvent?: ActionEventAction) => {
        analyticActionEvent && sendAnalyticSideBarElementClick(analyticActionEvent);

        close();
    };

    const handleLogout = () => {
        sendAnalyticSideBarLogoutClick();
        dispatch(logout());
        navigate('/');
        close();
    };

    const handleAvatarRedirect = () => {
        navigate(routes.PRIVATE.ACCOUNT_DEFAULT.path);
        close();
    };

    return (
        <S.SideBarWrapper isOpen={isOpen} isDesktop={isDesktop}>
            {!isDesktop && <S.SideBarOverlay onClick={close} />}
            <S.SideBarMenu>
                <Box paddingTop={32} paddingX={12}>
                    <S.User>
                        <S.Avatar onClick={handleAvatarRedirect}>
                            <Image alt="avatar" src={avatar} maxWidth={40} dataLocator="burgerUserAvatar" />
                        </S.Avatar>
                        <Text
                            medium
                            text={firstName || ''}
                            // @ts-ignore
                            dataLocator="burgerUsername"
                        />
                    </S.User>
                    {PRIMARY_SIDEBAR_LINKS.map(({ to, title, icon, dataLocator, analyticActionEvent }) => (
                        <SidebarNavButton
                            key={dataLocator}
                            onClick={() => handleLinkClick(analyticActionEvent)}
                            to={to}
                            title={title}
                            icon={icon}
                            dataLocator={dataLocator}
                        />
                    ))}
                </Box>

                <Box paddingX={12}>
                    {SECONDARY_SIDEBAR_LINKS.map(({ dataLocator, analyticActionEvent, ...rest }) => (
                        <SideBarNavLink
                            key={dataLocator}
                            onClick={() => handleLinkClick(analyticActionEvent)}
                            dataLocator={dataLocator}
                            {...rest}
                        />
                    ))}

                    <SideBarNavLink onClick={handleLogout} dataLocator="burgerLogoutBtn" title={t('sidebar.logout')} />
                </Box>
            </S.SideBarMenu>
        </S.SideBarWrapper>
    );
}
