import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// hooks
import { useDispatch, useSelector } from 'hooks';
import { useBlocksByCategory } from 'hooks/insights';
import { useCategoryRedirect } from './useCategoryRedirect';

// helpers
import { getWindowSize } from 'helpers/getWindowSize';

// redux
import {
    fetchBlockPreviewLayout,
    setBlockPreviewLayout,
    setCategoryBlocks,
    setPreviewLayoutLoadingState,
} from 'store/insights/actions';

const SMALL_SCREEN_PREVIEW_INDEX = 10;
const BIG_SCREEN_PREVIEW_INDEX = 11;

export const useInsightsCategory = () => {
    const dispatch = useDispatch();

    const [blockId, setBlockId] = useState<number | null>(null);
    const { categoryId }: { categoryId?: string; '*'?: string } = useParams();

    const {
        blocksByCategory,
        handleNextBlocks,
        hasMoreBlocks,
        isLoading,
        isPreviewLayoutLoading,
        isBlocksNextLoading,
    } = useBlocksByCategory(categoryId);

    const { blockPreviewLayout } = useSelector(({ insights }) => insights);

    const previewPosition = getWindowSize(768) ? BIG_SCREEN_PREVIEW_INDEX : SMALL_SCREEN_PREVIEW_INDEX;

    useEffect(() => {
        if (blocksByCategory.length > 0 && blocksByCategory[previewPosition]) {
            dispatch(fetchBlockPreviewLayout(blocksByCategory[previewPosition].id));
        }

        return () => {
            if (!window.location.href.includes('insights-category')) {
                dispatch(setBlockPreviewLayout(null));
                dispatch(setPreviewLayoutLoadingState(true));
            }
        };
    }, [blocksByCategory]);

    useEffect(() => {
        return () => {
            dispatch(setCategoryBlocks([]));
        };
    }, []);

    const { handleNavigateBackToAllCategories, handleCloseModal, handleOpenModal } = useCategoryRedirect({
        categoryId,
        setBlockId,
    });

    return {
        blockId,
        blocksByCategory,
        blockPreviewLayout,
        hasMoreBlocks,
        isLoading,
        isPreviewLayoutLoading,
        isBlocksNextLoading,
        previewPosition,
        setBlockId,
        handleNextBlocks,
        handleNavigateBackToAllCategories,
        handleCloseModal,
        handleOpenModal,
    };
};
