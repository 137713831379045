import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const InsightCategoryBlockContainer = styled.div`
    position: relative;
    padding: 64px 0;
`;

export const InsightCategoryBlockMainWithBackGround = styled.div<{ background: string }>`
    display: none;

    ${mediaQueries.laptop} {
        background: ${({ background }) => `${background}4d`};
        display: flex;
        border-radius: 60px;
        padding: 56px 40px !important;
        min-height: 480px;
        width: 100%;
    }
`;

export const InsightCategoryBlockText = styled.div`
    display: none;
    max-width: 260px;
    ${mediaQueries.tabletLandscape} {
        display: block;
    }
`;

export const InsightCategoryBlockPreviewWrap = styled.div`
    position: absolute;
    top: 0;
    right: -16px;
    left: -16px;
    height: 100%;

    ${mediaQueries.laptop} {
        width: 100%;
        left: inherit;
        max-width: 552px;
        right: 12%;
    }
`;
