import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// redux
import { fetchBlockPreview, setBlockPreview, setCategoryBlocks } from 'store/insights/actions';

// routes
import routes from 'router/routes';
import { sendAnalyticInsStoryClick } from 'services/analytics/trackers/insights';

interface IUseCategoryRedirect {
    categoryId?: string;
    setBlockId: (id: number | null) => void;
}

export function useCategoryRedirect({ categoryId, setBlockId }: IUseCategoryRedirect) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNavigateBackToAllCategories = () => {
        dispatch(setCategoryBlocks([]));

        navigate(routes.PRIVATE.INSIGHTS_MAIN.path);
    };

    const handleOpenModal = (blockId: number) => {
        sendAnalyticInsStoryClick(Number(categoryId), blockId);
        navigate(`/insights-category/${categoryId}/${blockId}`);

        dispatch(fetchBlockPreview(blockId));
        setBlockId(blockId);
    };

    const handleCloseModal = () => {
        navigate(`/insights-category/${categoryId}`);

        setBlockId(null);
        dispatch(setBlockPreview(null));
    };

    return {
        handleNavigateBackToAllCategories,
        handleOpenModal,
        handleCloseModal,
    };
}
