// components
import SpecialOfferModal from './components/SpecialOfferModal';

// hooks
import { useSelector } from 'hooks';

// redux
import { selectSubscriptions } from 'store/subscriptions/selectors';

// hooks
import { useCancelSubscription } from 'hooks';

const SpecialOfferModalContainer = () => {
    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const { onCancelSubscription, onUpdateSubscription } = useCancelSubscription();

    if (!cancellationCandidate) {
        return null;
    }

    return (
        <SpecialOfferModal
            isOpen
            externalId={cancellationCandidate?.externalId}
            onCancelSubscription={onCancelSubscription}
            onSubmitDiscount={onUpdateSubscription}
        />
    );
};

export default SpecialOfferModalContainer;
