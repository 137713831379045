// components
import * as S from './styled';
import { BigBenefit, NobelBlock, SmallBenefit, TitleBlock, WorthTrying } from './subcomponents';

// config
import {
    FIRST_BIG_BENEFIT,
    FIRST_SMALL_BENEFITS,
    SECOND_BIG_BENEFIT,
    OK_NOBEL_BLOCK_DATA,
    SECOND_SMALL_BENEFITS,
    LAST_SMALL_BENEFIT,
} from './config';

// types
import { IBenefitsForHealthBlockProps } from './types';

export const BenefitsForHealthBlock = ({ btnHandler }: IBenefitsForHealthBlockProps) => {
    return (
        <S.IntermittentFastingBlock dataLocator="benefitsSection">
            <S.ContentContainer>
                <TitleBlock />
                <BigBenefit benefit={FIRST_BIG_BENEFIT} />
                <S.SmallBenefitsWrap>
                    {FIRST_SMALL_BENEFITS.map((benefit, index) => (
                        <SmallBenefit key={index} benefit={benefit} />
                    ))}
                </S.SmallBenefitsWrap>
                <BigBenefit benefit={SECOND_BIG_BENEFIT} mbDesktop={56} />
                <NobelBlock content={OK_NOBEL_BLOCK_DATA} />
                <S.SmallBenefitsWrapSecond>
                    {SECOND_SMALL_BENEFITS.map((benefit, index) => (
                        <SmallBenefit key={index} benefit={benefit} />
                    ))}
                </S.SmallBenefitsWrapSecond>
                <S.SmallBenefitsWrapSecond>
                    {LAST_SMALL_BENEFIT.map((benefit, index) => (
                        <SmallBenefit key={index} benefit={benefit} isLastBenefit />
                    ))}
                </S.SmallBenefitsWrapSecond>
                <WorthTrying btnHandler={btnHandler} />
            </S.ContentContainer>
        </S.IntermittentFastingBlock>
    );
};
