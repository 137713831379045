import { Trans, useTranslation } from 'react-i18next';

// styled
import * as S from './styled';

// components
import { Text } from 'wikr-core-components';

// types
import { OptionContent } from '../../types';

interface ContentOptionProps {
    content: OptionContent;
    onClick: () => void;
}

export const ContentOption = ({ content, onClick }: ContentOptionProps) => {
    const { t } = useTranslation();

    return (
        <S.ContentOptionContainer onClick={onClick}>
            <S.TextContainer>
                <Text type="large-text" medium>
                    <Trans
                        i18nKey={content.hoursKey}
                        components={{ span: <S.HoursAmount /> }}
                        values={{ hours: content.hours }}
                    />
                </Text>
                <Text text={t(content.title)} />
            </S.TextContainer>
            <S.Image once isForceVisible alt="hours" src={content.image} />
        </S.ContentOptionContainer>
    );
};
