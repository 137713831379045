import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { StartFastingButton } from 'pages/welcome/components/StartFastingButton';

export const WorthTrying = ({ btnHandler }: { btnHandler: () => void }) => {
    const { t } = useTranslation();

    return (
        <S.WorthTrying borderRadius={40} backgroundColor="surface-main">
            <S.WorthText type="h2" center medium text={t('welcome.benefitsForHealth.lastQuestion')} mb={32} />
            <StartFastingButton btnHandler={btnHandler} />
        </S.WorthTrying>
    );
};
