import { useEffect } from 'react';

// hooks
import { useDispatch, useSelector } from 'hooks';

// actions
import { fetchInsights } from 'store/insights/actions';

export const useCategories = () => {
    const { userInsights, isLoading } = useSelector(({ insights }) => insights);
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.removeItem('currentCategory');

        dispatch(fetchInsights());
    }, []);

    const getCategoryById = (categoryId: number | null) => userInsights.find((insight) => insight.id === categoryId);

    return {
        isLoading,
        userInsights,
        getCategoryById,
    };
};
