// components
import CategoryModal from 'components/CategoryModal';

// types
import { InsightsModalContainerProps } from './types';
import { useInsightsModalContainer } from './useInsightsModalContainer';

function InsightsMainModalContainer({ blocks, blockId, setBlockId }: InsightsModalContainerProps) {
    const { blockPreview, handleCloseModal } = useInsightsModalContainer(setBlockId);

    return (
        <CategoryModal
            isOpenModal={Boolean(blockId)}
            onClose={handleCloseModal}
            blocksByCategory={blocks}
            articles={blockPreview?.articles || []}
            blockPreviewColor={blockPreview?.color || ''}
            skeletonColor={blocks?.find((el) => el.id === blockId)?.color || ''}
            blockId={blockId}
        />
    );
}

export default InsightsMainModalContainer;
