import React from 'react';
import { useTranslation } from 'react-i18next';

// helpers
import { getMockChartsConfig } from './helpers/getMockChartsConfig';

// components
import { Text, Box } from 'wikr-core-components';
import WeightLostGraphBlock from './components/WeightLostGraphBlock';
import ChartBlock from './components/ChartBlock';

// constants
import { OnboardingClassNames } from 'constants/onboardingFlow';

interface StatisticsBlockProps {
    isDemo: boolean;
}

const StatisticsBlock = ({ isDemo }: StatisticsBlockProps) => {
    const { t } = useTranslation();

    const mockedCharts = getMockChartsConfig();

    return (
        <Box dataLocator="statisticsContainer" className={OnboardingClassNames.seventh}>
            <Text text={t('dashboard.statistics.title')} medium type="medium-text" mb={20} />
            <WeightLostGraphBlock isDemo={isDemo} />
            {isDemo &&
                mockedCharts.map(({ data, infoData }, index) => (
                    <ChartBlock key={index} chartImgData={data} infoBlockData={infoData} />
                ))}
        </Box>
    );
};

export default StatisticsBlock;
