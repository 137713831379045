import { useCallback } from 'react';

// hooks
import { useDispatch, useSelector, useModals } from 'hooks';

// redux
import { selectSubscriptions } from 'store/subscriptions/selectors';
import {
    cancelSubscription,
    fetchSubscriptions,
    setCancellationCandidate,
    setDiscountSubscription,
    updateSubscription,
} from 'store/subscriptions/actions';

// analytics
import {
    sendAnalyticSpecialOfferError,
    sendAnalyticSpecialOfferGetDiscountClick,
    sendAnalyticSpecialOfferSuccess,
    sendAnalyticSubscriptionCancelSuccess,
    sendAnalyticSubscriptionCancelError,
} from 'services/analytics';

// types
import { ModalName } from 'store/modals/types';

// constants
import { DEFAULT_CANCEL_SUBSCRIPTION_ERROR } from 'constants/subscriptions';

export const useCancelSubscription = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();
    const { discountSubscription, cancellationCandidate } = useSelector(selectSubscriptions);

    const discardCancellation = () => {
        closeModal();

        dispatch(setCancellationCandidate(null));

        if (discountSubscription) {
            dispatch(setDiscountSubscription(null));
        }
    };

    const onCancelSubscription = useCallback(() => {
        openModal(ModalName.waitingModal);

        if (cancellationCandidate) {
            dispatch(
                cancelSubscription({
                    externalId: cancellationCandidate.externalId,
                    onError: (err?: string) => {
                        sendAnalyticSubscriptionCancelError(err || DEFAULT_CANCEL_SUBSCRIPTION_ERROR);

                        discardCancellation();
                    },

                    onSuccess: () => {
                        sendAnalyticSubscriptionCancelSuccess();

                        discardCancellation();
                    },
                    onCancel: discardCancellation,
                })
            );
        }
    }, [cancellationCandidate, discountSubscription]);

    const onUpdateSubscription = useCallback(() => {
        if (cancellationCandidate && discountSubscription) {
            sendAnalyticSpecialOfferGetDiscountClick();
            dispatch(
                updateSubscription({
                    updatedSubscription: {
                        external_id: cancellationCandidate.externalId,
                        product: discountSubscription,
                    },
                    onSuccess: () => {
                        discardCancellation();
                        sendAnalyticSpecialOfferSuccess(discountSubscription.id);
                        dispatch(fetchSubscriptions());
                    },
                    onError: () => {
                        discardCancellation();
                        sendAnalyticSpecialOfferError();
                    },
                })
            );
        }
    }, [cancellationCandidate, discountSubscription]);

    return { discardCancellation, onCancelSubscription, onUpdateSubscription, cancellationCandidate };
};
