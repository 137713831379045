import { useEffect } from 'react';

// trackers
import { trackScreenLoad } from './trackers/mainTrackers';

export function useScreenLoad() {
    useEffect(() => {
        trackScreenLoad();
    }, []);
}
