import styled from 'styled-components';
import { Box, Text } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const HowItWorksBlock = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 38px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 120px 64px;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
    }
`;

export const ImgWrap = styled(Box)`
    width: 100%;
    max-width: 300px;

    ${mediaQueriesGrid.desktop} {
        max-width: 344px;
    }

    ${mediaQueriesGrid.desktop} {
        max-width: 300px;
    }
`;

export const Title = styled(Text)`
    margin-bottom: 24px;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 32px;
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;
