import React from 'react';
import { useTranslation } from 'react-i18next';

// styled
import * as S from './styled';

// components
import { Text } from 'wikr-core-components';
import Header from 'pages/body-status/subcomponents/Header';
import DetailsButton from '../DetailsButton';

// images
import statusIcon1 from 'pages/body-status/img/bodyStatus1.webp';
import statusIndIcon from 'pages/body-status/img/curStatIcon.webp';

const DemoBodyStatus = () => {
    const { t } = useTranslation();

    return (
        <S.DemoStatusContainer>
            <Header
                title={t('bodyStatus.stateTitle1')}
                icon={statusIcon1}
                statusIndLabel={t('bodyStatus.curStateLabel')}
                statusIndIcon={statusIndIcon}
                isDashboardBlock
            />
            <S.Footer>
                <S.Description>
                    <Text
                        type="small-text"
                        bold
                        text={t('bodyStatus.stateHours1')}
                        // @ts-ignore
                        dataLocator="fastingStageSubTitle"
                    />
                    <S.TruncatedMeaning
                        type="small-text"
                        text={t('bodyStatus.stateMeaning1')}
                        // @ts-ignore
                        dataLocator="fastingStageDescription"
                    />
                </S.Description>
                <DetailsButton />
            </S.Footer>
        </S.DemoStatusContainer>
    );
};

export default DemoBodyStatus;
