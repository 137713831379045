import { useEffect } from 'react';

// helpers
import { getLanguage } from 'api/utils/languageManagment';

// services
import { initFrontChat, shutdownFrontChat } from 'services/FrontChat';

// store
import { useSelector } from 'hooks';
import { selectCurrentUser } from 'store/user/selectors';
import { selectHideFrontChat } from 'store/uiEffects/selectors';

function useFrontChat() {
    const language = getLanguage();
    const { email, name } = useSelector(selectCurrentUser);

    const isHideFrontChat = useSelector(selectHideFrontChat);

    useEffect(() => {
        if (!isHideFrontChat) {
            initFrontChat({ name, email, language });
        }

        return () => shutdownFrontChat();
    }, []);
}

export default useFrontChat;
