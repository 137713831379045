export const MOBILE_DOWNLOAD_STEPS = [
    'main.downloadBlock.stepDownload',
    'main.downloadBlock.stepChoose',
    'main.downloadBlock.stepLogin',
];

export const DESKTOP_DOWNLOAD_STEPS = [
    'main.downloadBlock.stepScan',
    'main.downloadBlock.stepDownload',
    'main.downloadBlock.stepChoose',
    'main.downloadBlock.stepLogin',
];
