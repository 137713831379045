import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

// components
import * as S from './styled';
import { Image, Text } from 'wikr-core-components';

// img
import timer from './img/timer.webp';

// constants
import { OnboardingClassNames } from 'constants/onboardingFlow';

function TimerComponent() {
    const { t } = useTranslation();
    const [hours, setHours] = useState(2);
    const [minutes, setMinutes] = useState(35);
    const [seconds, setSeconds] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            // for timer reset
            if (hours === 3 && minutes === 35) {
                setHours(2);
                setMinutes(35);
                setSeconds(1);
            } else {
                // for seconds +1
                if (seconds < 59) {
                    setSeconds(seconds + 1);
                }

                // for seconds to 0
                if (seconds === 59) {
                    setSeconds(0);

                    // for minutes +1 or minutes to 0 and hours+1
                    if (minutes !== 59) {
                        setMinutes(minutes + 1);
                    } else {
                        setMinutes(0);
                        setHours(hours + 1);
                    }
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [hours, minutes, seconds]);

    const time = `0${hours}:${minutes <= 9 ? `0${minutes}` : minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`;

    return (
        <S.ComponentWrap
            backgroundColor="on-primary"
            dataLocator="plateContainer"
            className={OnboardingClassNames.second}
        >
            <S.ImgWrap>
                <Image maxWidth={295} isForceVisible alt="timer" src={timer} />
                <S.TimerTextWrap>
                    <Text center color="primary-active" mb={4} />
                    <Text text={t('dashboard.timer.title')} medium type="h6" center color="primary-active" mb={4} />
                    <Text text={time} type="h3" center />
                </S.TimerTextWrap>
            </S.ImgWrap>
        </S.ComponentWrap>
    );
}

export default TimerComponent;
