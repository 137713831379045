export const SUBSCRIPTION_TITLE = {
    APP_FULL_ACCESS: 'subscription.heading.personalizedProgram',
    APP_VIP_SUPPORT: 'subscription.heading.vipSupport',
    OTHER: 'subscription.type.subscription',
};

export const AVAILABLE_CURRENCY: Record<string, string> = {
    USD: '$',
    EUR: '€',
    GBP: '£',
};

export const DEFAULT_UNSUBSCRIBE_PLATFORM = 'WEB';

export const DEFAULT_CANCEL_SUBSCRIPTION_ERROR = 'Subscription is not cancelled';
