import { ActionEventAction, ElementEventAction, EventTriggerFixed } from 'wikr-core-analytics';

// mainTrackers
import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticDeeplinkLoadClick = () => {
    trackEventFixed(EventTriggerFixed.deeplinkLoad);
};

export const sendAnalyticDownloadAppClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.downloadApp,
    });
};
