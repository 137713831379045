import React from 'react';
import { useRouteError, isRouteErrorResponse } from 'react-router';
import { Navigate } from 'react-router-dom';

const RootBoundary = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        console.error('ROUTER ERROR');

        if (error.status === 404) {
            console.error('ROUTER | PAGE NOT FOUND');
        }
    }

    return <Navigate to="/" />;
};

export default RootBoundary;
