import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';

// constants
import { OnboardingClassNames } from 'constants/onboardingFlow';

interface IDashboardTogglerProps {
    demoOn: boolean;
    onClick: () => void;
}

const DashboardToggler = ({ demoOn, onClick }: IDashboardTogglerProps) => {
    const { t } = useTranslation();

    return (
        <S.FullComponent demoOn={demoOn} className={OnboardingClassNames.first}>
            <S.Name type="small-text" medium text={t('dashboard.insights.toggler')} />
            <S.ToggleLabel>
                <S.ToggleInput data-locator="showDemoButton" checked={demoOn} onClick={onClick} type="checkbox" />
                <S.ToggleSlider />
            </S.ToggleLabel>
        </S.FullComponent>
    );
};

export default DashboardToggler;
