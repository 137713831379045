// helpers
import { getWindowSize } from 'helpers/getWindowSize';

// types
import { IInsightBlockSize } from './types';
import { TextType } from 'wikr-core-components';

export const getBlocksSize = (categoryIndex: number): IInsightBlockSize => {
    const moduleOperation = categoryIndex % 3;

    switch (moduleOperation) {
        case 0:
        default:
            return 'large';
        case 2:
            return 'medium';
        case 1:
            return 'small';
    }
};

const BIG_SCREENS_SIZES: Record<IInsightBlockSize, TextType> = {
    large: 'large-text',
    medium: 'large-text',
    small: 'h3',
};

const MOBILE_SCREENS_SIZES: Record<IInsightBlockSize, TextType> = {
    large: 'small-text',
    medium: 'small-text',
    small: 'h6',
};

export const getTextSize = (blockSize: IInsightBlockSize, isFirstTitle: boolean): TextType => {
    const isMobile = getWindowSize(375);
    if (isFirstTitle && blockSize === 'large') {
        return isMobile ? 'h3' : 'h1';
    }

    const screenSizes = isMobile ? MOBILE_SCREENS_SIZES : BIG_SCREENS_SIZES;

    return screenSizes[blockSize];
};
