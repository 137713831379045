import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Image } from 'wikr-core-components';

// types
import { SpecialOfferModalProps } from './types';

// hooks
import { useSelector } from 'hooks';
import { useSpecialOfferModal } from 'hooks/subscriptions';

// components
import Modal from 'components/Modal';
import OverlineButton from 'components/OverlineButton';

// styled
import * as S from './styled';

// img
import women from 'assets/img/specialOfferWomen.webp';
import men from 'assets/img/specialOfferMen.webp';
import stars from 'assets/img/specialOfferStars.svg';
import loader from './img/loader.svg';

// helpers
import { isMaleGender } from 'helpers/utils';
import { getWindowSize } from 'helpers/getWindowSize';

// constants
import { AVAILABLE_CURRENCY } from 'constants/subscriptions';
import { MODAL_OVERLAY_STYLES, MODAL_CONTENT_STYLES } from './constants';

// services
import { sendAnalyticSpecialOfferScreenLoad } from 'services/analytics';

const isMobile = getWindowSize(376);

const SpecialOfferModal = ({ isOpen, externalId, onSubmitDiscount, onCancelSubscription }: SpecialOfferModalProps) => {
    const { t } = useTranslation();
    const { candidatePriceData, discountPriceData, isDiscountLoading } = useSpecialOfferModal(externalId);
    const gender = useSelector(({ user }) => user.gender);
    const isMale = isMaleGender(gender);

    useEffect(() => {
        sendAnalyticSpecialOfferScreenLoad();
    }, []);

    if (!candidatePriceData || !discountPriceData || !isOpen) {
        return null;
    }

    const OLD_PRICE = `${AVAILABLE_CURRENCY[candidatePriceData.currency]}${candidatePriceData.integer}.${
        candidatePriceData.cents
    }`;

    return (
        <Modal
            isOpen={isOpen}
            fullscreen={!isMobile && !isDiscountLoading}
            style={{
                content: {
                    ...MODAL_CONTENT_STYLES,
                    ...(isMobile && {
                        borderRadius: '20px',
                        overflow: 'hidden',
                        maxWidth: isDiscountLoading ? '344px' : '400px',
                    }),
                },
                overlay: MODAL_OVERLAY_STYLES,
            }}
        >
            {isDiscountLoading ? (
                <S.LoaderContainer>
                    <Text type="h5" text={t('subscription.cancellation.specialOffer.updating')} mb={24} center />
                    <Image maxWidth={24} center alt="loader" src={loader} isForceVisible />
                </S.LoaderContainer>
            ) : (
                <S.ModalContainer>
                    <S.TextWrapper>
                        <Text text={t('subscription.cancellation.specialOffer.title')} type="h4" medium center />
                    </S.TextWrapper>
                    <Image alt="offer" src={isMale ? men : women} maxWidth={375} />

                    {isMobile && <S.DividerWrapper />}

                    <S.ContentContainer>
                        <Image alt="rate" src={stars} maxWidth={92} mb={18} />
                        <S.PriceWrapper>
                            <S.OldPriceWrapper>
                                <S.OldPrice text={OLD_PRICE} color="text-secondary-subdued" type="h5" center />
                                <S.OldPriceTextWrapper>
                                    <Text
                                        text={t('subscription.cancellation.specialOffer.oldPrice')}
                                        color="text-secondary-subdued"
                                        type="overline"
                                        center
                                    />
                                </S.OldPriceTextWrapper>
                            </S.OldPriceWrapper>
                            <S.NewPriceWrapper>
                                <S.DiscountWrapper>
                                    <S.DiscountTextWrapper>
                                        <Text
                                            text={t('subscription.cancellation.specialOffer.discountAmount')}
                                            type="overline"
                                            bold
                                            center
                                        />
                                    </S.DiscountTextWrapper>
                                </S.DiscountWrapper>
                                <S.NewPriceAmount>
                                    <Text
                                        text={AVAILABLE_CURRENCY[discountPriceData.currency]}
                                        type="small-text"
                                        bold
                                    />
                                    <Text text={discountPriceData.integer} type="h3" bold />
                                    <Text text={discountPriceData.cents} type="small-text" bold />
                                </S.NewPriceAmount>
                                <S.NewPriceTextWrapper>
                                    <Text
                                        text={t('subscription.cancellation.specialOffer.newPrice')}
                                        type="overline"
                                        center
                                    />
                                </S.NewPriceTextWrapper>
                            </S.NewPriceWrapper>
                        </S.PriceWrapper>
                        <Text text={t('subscription.cancellation.specialOffer.disclaimer')} type="caption" mb={12} />
                        <S.ButtonWrapper
                            text={t('subscription.cancellation.specialOffer.getMyDiscount')}
                            backgroundColor="secondary-default"
                            hoverColor="secondary-hovered"
                            mb={20}
                            onClick={onSubmitDiscount}
                        />
                        <OverlineButton
                            text={t('subscription.text.cancel')}
                            dataLocator="keepSubBtn"
                            onClick={onCancelSubscription}
                        />
                    </S.ContentContainer>
                </S.ModalContainer>
            )}
        </Modal>
    );
};

export default memo(SpecialOfferModal);
