import recipeMon from './img/recipeMon.webp';
import recipeTue from './img/recipeTue.webp';
import recipeWed from './img/recipeWed.webp';
import recipeThu from './img/recipeThu.webp';
import recipeFri from './img/recipeFri.webp';
import recipeSat from './img/recipeSat.webp';
import recipeSun from './img/recipeSun.webp';

export const WEEK_RECIPES = [
    {
        dayName: 'week.mon',
        img: recipeMon,
    },
    {
        dayName: 'week.tue',
        img: recipeTue,
    },
    {
        dayName: 'week.wed',
        img: recipeWed,
    },
    {
        dayName: 'week.thu',
        img: recipeThu,
    },
    {
        dayName: 'week.fri',
        img: recipeFri,
    },
    {
        dayName: 'week.sat',
        img: recipeSat,
    },
    {
        dayName: 'week.sun',
        img: recipeSun,
    },
];
