// components
import * as S from './styled';

// types
import { IButton } from 'wikr-core-components';

const SecondaryDefaultButton = ({ text, onClick, dataLocator, withPulsating, mb }: IButton) => {
    return (
        <S.SecondaryDefaultButton
            text={text}
            onClick={onClick}
            withPulsating={withPulsating}
            mb={mb}
            dataLocator={dataLocator}
        />
    );
};

export default SecondaryDefaultButton;
