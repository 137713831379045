import { Outlet, ScrollRestoration } from 'react-router-dom';

// hooks
import { useAuthentication, useSelector } from 'hooks';

// store
import { isAuthenticationCompleteSelector } from 'store/auth/selectors';

// services
import useI18next from 'services/i18n';
import { useAnalytic } from 'services/analytics/useAnalytic';

// components
import { PageLoader } from 'components/PageLoader';
import Modals from 'modals';

const RootLayout = () => {
    const isComplete = useSelector(isAuthenticationCompleteSelector);
    const { isTranslationsLoaded } = useI18next();

    useAuthentication();
    useAnalytic();

    if (isTranslationsLoaded && isComplete)
        return (
            <>
                <ScrollRestoration />
                <Outlet />
                <Modals />
            </>
        );

    return <PageLoader />;
};

export default RootLayout;
