import styled from 'styled-components';

// theme
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const PageWrapper = styled.div`
    ${mediaQueriesGrid.desktop} {
        margin-left: 240px;
    }
`;
