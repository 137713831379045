import styled from 'styled-components';
import { Box } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const NobelInfoBlock = styled(Box)`
    display: flex;
    width: 100%;
    margin-bottom: 48px;

    ${mediaQueriesGrid.desktop} {
        padding-left: 56px;
        margin-bottom: 96px;
    }
`;

export const OkImgWrap = styled(Box)`
    min-width: 96px;
    width: 100%;
    margin-right: 20px;

    ${mediaQueriesGrid.desktop} {
        max-width: 136px;
    }
`;

export const TextsWrap = styled(Box)`
    ${mediaQueriesGrid.desktop} {
        max-width: 684px;
    }
`;
