import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Image } from 'wikr-core-components';

// img
import food from './img/food.webp';

export const LifestyleBlock = () => {
    const { t } = useTranslation();

    return (
        <S.LifestyleBlock backgroundColor="surface-secondary" dataLocator="lifestyleSection">
            <S.ContentContainer>
                <S.Subtitle type="large-text" text={t('welcome.lifestyle.subtitle')} medium />
                <S.Title type="h2" text={t('welcome.lifestyle.title')} medium />
                <S.ContentWrap>
                    <S.ImgWrap>
                        <Image once isForceVisible alt="food" src={food} />
                    </S.ImgWrap>
                    <S.MainText type="h5" medium text={t('welcome.lifestyle.text')} />
                </S.ContentWrap>
            </S.ContentContainer>
        </S.LifestyleBlock>
    );
};
