import emailIcon from 'assets/img/icons/emailIcon.webp';
import passwordIcon from 'assets/img/icons/passwordIcon.webp';

// analytic
import { sendAnalyticLoginEmailClick, sendAnalyticLoginPasswordClick } from 'services/analytics';

export const CONTENT = [
    {
        name: 'email',
        label: 'input.email.label',
        type: 'email',
        icon: emailIcon,
        dataLocator: 'loginEmailInput',
        placeholder: 'input.email.placeholder',
        analyticHandler: sendAnalyticLoginEmailClick,
    },
    {
        name: 'password',
        label: 'input.password.label',
        type: 'password',
        icon: passwordIcon,
        dataLocator: 'loginPasswordInput',
        placeholder: 'input.password.placeholder',
        analyticHandler: sendAnalyticLoginPasswordClick,
    },
];
