import styled from 'styled-components';

// components
import { Box } from 'wikr-core-components';

// theme
import mediaQueriesGrid from 'theme/mediaQueriesGrid';
import { color } from 'theme/selectors';

// constants
import { CONTENT_3 } from 'constants/customContentColors';

export const BlockWrap = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:after {
        content: '';
        position: absolute;
        height: 267px;
        max-width: 343px;
        left: 0;
        right: 0;
        top: 80px;
        background: ${CONTENT_3};
        border-radius: 300px 300px 40px 40px;

        ${mediaQueriesGrid.desktop} {
            left: 132.5px;
        }
    }
`;

export const ImgWrap = styled.div`
    z-index: 2;
`;

export const PDFLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 12px 16px;
    background: ${color('secondary-default')};
    border-radius: 100px;
    z-index: 2;
`;

export const LinkText = styled.span`
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${color('on-primary')};
`;
