import styled, { css } from 'styled-components';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const OneInsight = styled.div<{
    backgroundColor: string;
    isSafari: boolean;
}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    flex: 0 0 calc(50% - 6px);

    position: relative;
    padding: 16px 16px 0;
    margin-right: 12px;

    &:nth-child(2n) {
        margin-right: 0;
    }

    &:nth-child(1),
    &:nth-child(2) {
        margin-bottom: 16px;
    }

    background: ${({ backgroundColor }) => `${backgroundColor}`};
    border-radius: 20px;
    overflow: hidden;
    text-align: left;

    &:hover {
        ${({ isSafari }) =>
            !isSafari &&
            css`
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.26);
            `};
        cursor: pointer;
    }

    ${mediaQueriesGrid.tablet} {
        flex: 0 0 calc(33.33% - 8px);

        &:nth-child(2n) {
            margin-right: 12px;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:nth-child(1),
        &:nth-child(2) {
            margin-bottom: 0;
        }
    }

    ${mediaQueriesGrid.largeTablet} {
        flex: 0 0 calc(25% - 15px);

        margin-right: 20px;

        &:nth-child(2n) {
            margin-right: 20px;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export const ImageWrapper = styled.div`
    max-height: 75%;
    margin: 0 auto;
`;

export const ImageStyled = styled.img`
    max-height: 123px;
    display: block;
    margin: 0 auto;

    ${mediaQueriesGrid.tablet} {
        max-height: 143px;
    }

    ${mediaQueriesGrid.largeTablet} {
        max-height: 188px;
    }
`;
