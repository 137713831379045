import * as actionTypes from './actionTypes';

// types
import { ICancelSubscription, IDiscountSubscriptionSaga } from './types';
import { IDiscountSubscription, ISubscription } from 'types/subscription';
import { CancelCandidate } from 'hooks/subscriptions/types';

export const fetchSubscriptions = () => {
    return {
        type: actionTypes.FETCH_USER_SUBSCRIPTIONS,
    } as const;
};

export const setSubscriptions = (payload: Array<ISubscription>) => {
    return {
        type: actionTypes.SET_USER_SUBSCRIPTIONS,
        payload,
    } as const;
};

export const setCancellationCandidate = (payload: CancelCandidate | null) => {
    return {
        type: actionTypes.SET_CANCELLATION_CANDIDATE,
        payload,
    } as const;
};

export const cancelSubscription = (payload: ICancelSubscription) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION,
        payload,
    } as const;
};

export const discardSubscriptionCancellation = () => {
    return {
        type: actionTypes.DISCARD_SUBSCRIPTION_CANCELLATION,
    } as const;
};

export const setSubscriptionsFetchingStatus = (payload: boolean) => {
    return {
        type: actionTypes.SET_FETCHING_STATUS,
        payload,
    } as const;
};

export const fetchDiscountSubscriptions = (payload: string) => {
    return {
        type: actionTypes.FETCH_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const setIsDiscountLoading = (payload: boolean) => {
    return {
        type: actionTypes.SET_IS_DISCOUNT_LOADING,
        payload,
    } as const;
};

export const setDiscountSubscription = (payload: IDiscountSubscription | null) => {
    return {
        type: actionTypes.SET_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const updateSubscription = (payload: IDiscountSubscriptionSaga) => {
    return {
        type: actionTypes.UPDATE_SUBSCRIPTION,
        payload,
    } as const;
};

export const resetSubscriptions = () => {
    return {
        type: actionTypes.RESET_SUBSCRIPTIONS,
    } as const;
};
