import styled from 'styled-components';

// components
import { Text } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const BigBenefit = styled.div<{ mbDesktop: number }>`
    position: relative;
    background-color: ${color('surface-main')};
    padding: 24px 24px 32px;
    border-radius: 40px;
    width: 100%;
    margin-bottom: 48px;

    ${mediaQueriesGrid.desktop} {
        padding: 48px 80px 64px;
        margin-bottom: ${({ mbDesktop }) => `${mbDesktop}px`};
    }
`;

export const ImageWrap = styled.div`
    position: absolute;
    top: -100px;
    right: 0;
    width: 100%;
    max-width: 200px;

    ${mediaQueriesGrid.desktop} {
        top: inherit;
        bottom: 2px;
        max-width: 480px;
    }
`;

export const ContentWrap = styled.div`
    width: 100%;

    ${mediaQueriesGrid.desktop} {
        max-width: 620px;
    }
`;

export const TitleWrap = styled.div`
    display: flex;
    width: 100%;
`;

export const SVGWrap = styled.div`
    margin-right: 4px;
`;

export const Subtitle = styled(Text)<{ withDescriptions: boolean }>`
    margin-bottom: ${({ withDescriptions }) => (withDescriptions ? '40px' : '0')};

    ${mediaQueriesGrid.desktop} {
        font-size: 21px;
        line-height: 32px;
        margin-bottom: ${({ withDescriptions }) => (withDescriptions ? '80px' : '0')};
    }
`;

export const DescriptionsWrap = styled.div`
    width: 100%;

    ${mediaQueriesGrid.desktop} {
        display: flex;
        justify-content: space-between;
    }
`;

export const DescriptionNumber = styled(Text)`
    margin-right: 6px;
`;

export const OneDescriptionWrap = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 24px;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${mediaQueriesGrid.desktop} {
        max-width: 290px;
        margin-bottom: 0;
    }
`;
