import React from 'react';
import { Box } from 'wikr-core-components';

// hooks
import { useDispatch, useModals, useSubscriptions } from 'hooks';

// components
import SubscriptionCard from './components/SubscriptionCard';
import SubscriptionsSkeleton from './components/SubscriptionsSkeleton';
import ContactUsLink from 'components/ContactUsLink';

// styled
import { ContactUsWrapper } from './styled';

// types
import { ISubscription } from 'types/subscription';

// analytics
import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { CancelCandidate } from 'hooks/subscriptions/types';

// redux
import { fetchDiscountSubscriptions, setCancellationCandidate } from 'store/subscriptions/actions';

// type
import { ModalName } from 'store/modals/types';

const SubscriptionsTab = () => {
    useScreenLoad();

    const dispatch = useDispatch();
    const { openModal } = useModals();

    const { isLoading, subscriptions } = useSubscriptions();

    const onCancelSubscription = (candidate: CancelCandidate) => {
        dispatch(setCancellationCandidate(candidate));

        dispatch(fetchDiscountSubscriptions(candidate.externalId));

        openModal(ModalName.alertModal);
    };

    const displaySkeleton = isLoading && !subscriptions?.length;

    return (
        <Box paddingTop={40} paddingBottom={40}>
            {displaySkeleton && <SubscriptionsSkeleton />}

            {!displaySkeleton &&
                subscriptions.map((subscription: ISubscription) => (
                    <SubscriptionCard
                        key={subscription.id}
                        subscription={subscription}
                        onCancelSubscription={onCancelSubscription}
                    />
                ))}

            <ContactUsWrapper>
                <ContactUsLink data-locator="subscriptionFaqBtn" subject="subscription" />
            </ContactUsWrapper>
        </Box>
    );
};

export default SubscriptionsTab;
