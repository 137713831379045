import * as S from './styled';

import fire from './img/fireEmoji.svg';
import shokedEmoji from './img/shokedEmoji.svg';
import weights from './img/weights.webp';
import bacterium from './img/bacterium.webp';
import okNobel from './img/okNobel.webp';

// types
import { IBenefit, INobelBlockData, ISmallBenefit } from './types';

export const FIRST_BIG_BENEFIT: IBenefit = {
    benefitNumber: 1,
    emoji: () => <S.BigBenefitSvg maxWidth={40} isForceVisible alt="fire" src={fire} />,
    title: 'welcome.benefitsForHealth.firstBenefit.title',
    subtitle: 'welcome.benefitsForHealth.firstBenefit.subtitle',
    img: weights,
    descriptions: [
        {
            disNumber: 1,
            text: 'welcome.benefitsForHealth.firstBenefit.description1.text',
            authors: 'welcome.benefitsForHealth.firstBenefit.description1.authors',
        },
        {
            disNumber: 2,
            text: 'welcome.benefitsForHealth.firstBenefit.description2.text',
            authors: 'welcome.benefitsForHealth.firstBenefit.description2.authors',
        },
    ],
};

export const SECOND_BIG_BENEFIT: IBenefit = {
    benefitNumber: 5,
    emoji: () => <S.BigBenefitSvg maxWidth={40} isForceVisible alt="shokedEmoji" src={shokedEmoji} />,
    title: 'welcome.benefitsForHealth.fifthBenefit.title',
    subtitle: 'welcome.benefitsForHealth.fifthBenefit.subtitle',
    img: bacterium,
};

export const FIRST_SMALL_BENEFITS: ISmallBenefit[] = [
    {
        benefitNumber: 2,
        title: 'welcome.benefitsForHealth.secondBenefit.title',
        subtitle: 'welcome.benefitsForHealth.secondBenefit.subtitle',
        descriptions: [
            {
                disNumber: 3,
                text: 'welcome.benefitsForHealth.secondBenefit.description1.text',
                authors: 'welcome.benefitsForHealth.secondBenefit.description1.authors',
            },
        ],
    },
    {
        benefitNumber: 3,
        title: 'welcome.benefitsForHealth.thirdBenefit.title',
        subtitle: 'welcome.benefitsForHealth.thirdBenefit.subtitle',
        descriptions: [
            {
                disNumber: 4,
                text: 'welcome.benefitsForHealth.thirdBenefit.description1.text',
                authors: 'welcome.benefitsForHealth.thirdBenefit.description1.authors',
            },
            {
                disNumber: 5,
                text: 'welcome.benefitsForHealth.thirdBenefit.description2.text',
                authors: 'welcome.benefitsForHealth.thirdBenefit.description2.authors',
            },
        ],
    },
    {
        benefitNumber: 4,
        title: 'welcome.benefitsForHealth.fourthBenefit.title',
        subtitle: 'welcome.benefitsForHealth.fourthBenefit.subtitle',
    },
];

export const SECOND_SMALL_BENEFITS: ISmallBenefit[] = [
    {
        benefitNumber: 6,
        title: 'welcome.benefitsForHealth.sixthBenefit.title',
        subtitle: 'welcome.benefitsForHealth.sixthBenefit.subtitle',
    },
    {
        benefitNumber: 7,
        title: 'welcome.benefitsForHealth.seventhBenefit.title',
        subtitle: 'welcome.benefitsForHealth.seventhBenefit.subtitle',
        descriptions: [
            {
                disNumber: 2,
                text: 'welcome.benefitsForHealth.seventhBenefit.description1.text',
                authors: 'welcome.benefitsForHealth.seventhBenefit.description1.authors',
            },
        ],
    },
    {
        benefitNumber: 8,
        title: 'welcome.benefitsForHealth.eighthBenefit.title',
        subtitle: 'welcome.benefitsForHealth.eighthBenefit.subtitle',
        descriptions: [
            {
                disNumber: 3,
                text: 'welcome.benefitsForHealth.eighthBenefit.description1.text',
                authors: 'welcome.benefitsForHealth.eighthBenefit.description1.authors',
            },
        ],
    },
];

export const LAST_SMALL_BENEFIT: ISmallBenefit[] = [
    {
        benefitNumber: 9,
        title: 'welcome.benefitsForHealth.ninthBenefit.title',
        subtitle: 'welcome.benefitsForHealth.ninthBenefit.subtitle',
    },
];

export const OK_NOBEL_BLOCK_DATA: INobelBlockData = {
    img: okNobel,
    title: 'welcome.benefitsForHealth.nobel.title',
    text: 'welcome.benefitsForHealth.nobel.text',
};
