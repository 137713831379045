import React from 'react';

// components
import ExpiredSubscription from './components/ExpiredSubscription';
import ExpiresSubscription from './components/ExpiresSubscription';
import SubscriptionRedemption from './components/SubscriptionRedemption';
import DefaultSubscription from './components/DefaultSubscription';

// types
import { ISubscriptionCard } from './types';

// hooks
import useSubscriptionCard from './useSubscriptionCard';

const SubscriptionCard = ({ onCancelSubscription, subscription }: ISubscriptionCard) => {
    const {
        isExpiredSubscription,
        isExpiresSubscription,
        isSubscriptionRedemption,
        trackCardExpand,
        handleCancelSubscription,
        state,
        upcomingProduct,
        tabIcon,
        heading,
        subscriptionPeriod,
        subscriptionType,
        price,
        expirationDateUI,
    } = useSubscriptionCard({
        subscription,
        onCancelSubscription,
    });

    const commonProps = {
        tabIcon,
        heading,
        subscriptionPeriod,
        subscriptionType,
        price,
    };

    if (isExpiredSubscription) {
        return <ExpiredSubscription {...commonProps} expirationDate={expirationDateUI} />;
    }

    if (isExpiresSubscription) {
        return <ExpiresSubscription {...commonProps} expirationDate={expirationDateUI} />;
    }

    if (isSubscriptionRedemption) {
        return (
            <SubscriptionRedemption
                {...commonProps}
                trackCardExpand={trackCardExpand}
                onCancelSubscription={handleCancelSubscription}
            />
        );
    }

    return (
        <DefaultSubscription
            {...commonProps}
            trackCardExpand={trackCardExpand}
            expirationDate={expirationDateUI}
            state={state}
            upcomingProduct={upcomingProduct}
            onCancelSubscription={handleCancelSubscription}
        />
    );
};

export default SubscriptionCard;
