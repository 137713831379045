import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import { Text } from 'wikr-core-components';
import Modal from 'components/Modal/Modal';
import OutlinedButton from 'components/OutlinedButton';
import OverlineButton from 'components/OverlineButton';

interface IPlateModalProps {
    isOpen: boolean;
    onClose: () => void;
    onStartBtn: () => void;
    onDemoShow: () => void;
}

const MODAL_STYLES = { maxWidth: '343px' };

const PlateModal = ({ isOpen, onClose, onStartBtn, onDemoShow }: IPlateModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            style={{ content: MODAL_STYLES }}
            isOpen={isOpen}
            padding="24px 16px 28px"
            onClose={onClose}
            closeOnOverlayClick
        >
            <Text center type="h5" text={t('dashboard.plate.modal.title')} mb={12} />
            <Text center text={t('dashboard.plate.modal.subtitle')} mb={32} />
            <OutlinedButton onClick={onStartBtn} text={t('dashboard.plate.modal.startInApp')} mb={20} />
            <OutlinedButton onClick={onDemoShow} text={t('dashboard.plate.modal.showDemoHere')} mb={24} />
            <OverlineButton onClick={onClose} text={t('account.settings.deletionModal.cancelBtn')} />
        </Modal>
    );
};

export default PlateModal;
