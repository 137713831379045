import AlertModalContainer from 'components/ModalContainers/AlertModalContainer';
import CancellationReasonModalContainer from 'components/ModalContainers/CancellationReasonModalContainer';
import SpecialOfferModalContainer from 'components/ModalContainers/SpecialOfferModalContainer';
import WaitingModalContainer from 'components/ModalContainers/WaitingModalContainer';
import ProductProposeContainer from 'components/ModalContainers/ProductProposeContainer';
import CheckOurAppsContainer from 'components/ModalContainers/CheckOurAppsContainer';
import SurveyModalContainer from 'components/ModalContainers/SurveyModalContainer';
import SurveyThankYouModalContainer from 'components/ModalContainers/SurveyThankYouModalContainer';
import LifeTimeAccessQuestionContainer from 'components/ModalContainers/LifeTimeAccessQuestionContainer';
import LifeTimeAccessFinalModalContainer from 'components/ModalContainers/LifeTimeAccessFinalModalContainer';
import AdditionalQuestionContainer from 'components/ModalContainers/AdditionalQuestionContainer';

// types
import { ModalName, ModalsMapType } from 'store/modals/types';

export const MODAL_LIST: ModalsMapType = {
    [ModalName.alertModal]: AlertModalContainer,
    [ModalName.reasonModal]: CancellationReasonModalContainer,
    [ModalName.specialOfferModal]: SpecialOfferModalContainer,
    [ModalName.waitingModal]: WaitingModalContainer,
    [ModalName.productPropose]: ProductProposeContainer,
    [ModalName.checkOurApps]: CheckOurAppsContainer,
    [ModalName.survey]: SurveyModalContainer,
    [ModalName.surveyThankYou]: SurveyThankYouModalContainer,
    [ModalName.lifeTimeAccessQuestion]: LifeTimeAccessQuestionContainer,
    [ModalName.lifeTimeAccessFinal]: LifeTimeAccessFinalModalContainer,
    [ModalName.additionalQuestion]: AdditionalQuestionContainer,
};
