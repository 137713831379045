import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// styled
import * as S from './styled';

// img
import { ReactComponent as Arrow } from './img/Arrow.svg';

interface IButtonWithArrowProps {
    text?: string;
    onClick?: () => void;
    backArrow?: boolean;
    dataLocator?: string;
    btnId?: string;
    isAbsoluteOnStack?: boolean;
}

const ButtonWithArrow: FC<IButtonWithArrowProps> = ({
    text,
    onClick = () => {},
    backArrow,
    dataLocator,
    btnId,
    isAbsoluteOnStack,
}) => {
    const { t } = useTranslation();

    return (
        <S.Container backArrow={backArrow} data-locator={dataLocator}>
            <S.Button backArrow={backArrow} onClick={onClick} id={btnId} isAbsoluteOnStack={isAbsoluteOnStack}>
                <Arrow />
            </S.Button>
            {text && btnId && <S.Label htmlFor={btnId}>{t(text)}</S.Label>}
        </S.Container>
    );
};

export default ButtonWithArrow;
