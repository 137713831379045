import styled, { css } from 'styled-components';
import { ImageWrapper } from '../CategoryBlock/styled';

export const CategoryBlockItemSkeleton = styled.div<{
    itemId: number;
    blockSize: string;
}>`
    grid-area: ${({ itemId }) => `blockItem${itemId}`};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    overflow: hidden;
    text-align: left;

    transition: transform 0.3s, box-shadow 0.3s;

    ${({ blockSize }) =>
        blockSize === 'large' &&
        css`
            &:nth-child(1n + 4) {
                flex-direction: column;
            }

            @media (max-width: 768px) {
                &:nth-child(1n + 2) {
                    flex-direction: column;
                }
            }
            @media (max-width: 375px) {
                flex-direction: column;
            }
        `};
    ${({ blockSize }) =>
        blockSize === 'medium' &&
        css`
            @media (max-width: 768px) {
                &:nth-child(1n + 1) {
                    flex-direction: column;
                }
            }
        `};

    :first-child {
        ${({ blockSize }) =>
            blockSize === 'large' &&
            css`
                ${ImageWrapper} {
                    height: 100%;

                    @media (max-width: 375px) {
                        height: 75%;
                    }
                }
            `};
    }
`;
