import React from 'react';

// helpers
import { getWindowSize } from 'helpers/getWindowSize';

// components
import { Text } from 'wikr-core-components';

// styled
import * as S from './styled';

interface IChartImgProps {
    data: {
        yLabels: string[];
        xData: Record<string, string>[];
    };
}

const isBigScreen = getWindowSize(600);

const ChartImg = ({ data }: IChartImgProps) => {
    const xData = isBigScreen ? data.xData : data.xData.slice(0, 3);

    return (
        <S.ChartImgContainer>
            <S.AxisY>
                {data.yLabels.map((yVal) => (
                    <Text key={yVal} type="small-text" color="text-secondary" text={yVal} />
                ))}
            </S.AxisY>
            <S.Chart>
                <S.Line />
                {xData.map((xVal) => {
                    return (
                        <S.AxisXBlock key={xVal.date}>
                            <S.AxisXImage alt="fasting" src={xVal.img} maxWidth={44} mb={12} />
                            <Text type="small-text" center color="text-secondary" text={xVal.date} />
                        </S.AxisXBlock>
                    );
                })}
            </S.Chart>
        </S.ChartImgContainer>
    );
};

export default ChartImg;
