import React from 'react';
import { useTranslation } from 'react-i18next';

// styled
import * as S from './styled';

// components
import { Text } from 'wikr-core-components';

const DemoInfo = () => {
    const { t } = useTranslation();

    return (
        <S.Container>
            <S.StatusBlock>
                <Text type="caption" color="text-secondary" text={t('dashboard.fastingProtocol.fastStarted.title')} />
                <Text
                    type="caption"
                    medium
                    text={t('dashboard.fastingProtocol.fastStarted.value')}
                    // @ts-ignore
                    dataLocator="startDate"
                />
            </S.StatusBlock>
            <S.VerticalLine />
            <S.StatusBlock>
                <Text type="caption" color="text-secondary" text={t('dashboard.fastingProtocol.fastFinishes.title')} />
                <Text
                    type="caption"
                    medium
                    text={t('dashboard.fastingProtocol.fastFinishes.value')}
                    // @ts-ignore
                    dataLocator="finishDate"
                />
            </S.StatusBlock>
        </S.Container>
    );
};

export default DemoInfo;
