import { useEffect } from 'react';

// components
import CheckOurAppsModal from './components/CheckOurAppsModal';

// hooks
import { useModals, useCancelSubscription } from 'hooks';

// redux
import { ModalName } from 'store/modals/types';

// analytics
import {
    sendAnalyticCheckOurAppsModalCancelCancellationClick,
    sendAnalyticCheckOurAppsModalExpandClick,
    sendAnalyticCheckOurAppsModalGetAppClick,
    sendAnalyticCheckOurAppsModalScreenLoad,
} from 'services/analytics';

const CheckOurAppsContainer = () => {
    const { openModal } = useModals();
    const { onCancelSubscription } = useCancelSubscription();

    useEffect(() => {
        sendAnalyticCheckOurAppsModalScreenLoad();
    }, []);

    const onContinueHandler = (projectName: string) => {
        sendAnalyticCheckOurAppsModalGetAppClick(projectName);
        openModal(ModalName.survey, { projectName });
    };

    const onExpandHandler = (productName: string) => {
        sendAnalyticCheckOurAppsModalExpandClick(productName);
    };

    const onCancelSubscriptionHandler = () => {
        sendAnalyticCheckOurAppsModalCancelCancellationClick();

        onCancelSubscription();
    };

    return (
        <CheckOurAppsModal
            onContinue={onContinueHandler}
            onCancelSubscription={onCancelSubscriptionHandler}
            onExpand={onExpandHandler}
        />
    );
};

export default CheckOurAppsContainer;
