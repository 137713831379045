import React from 'react';
import { Trans } from 'react-i18next';

// types
import { ProtocolCardProps } from 'pages/fasting-protocol/types';

// components
import { Text } from 'wikr-core-components';

// styled
import * as S from './styled';

const ProtocolCard = ({ protocol, full, onClick }: ProtocolCardProps) => {
    return (
        <S.Container
            data-locator={full ? 'fastingProtocolTitle' : 'fastingProtocolCard'}
            className={full ? 'full' : ''}
            onClick={() => onClick && onClick(protocol.id)}
        >
            <S.Description>
                <Text
                    type="large-text"
                    medium
                    // @ts-ignore
                    dataLocator="fastingProtocolCardTitle"
                >
                    <Trans
                        i18nKey={protocol.hoursKey}
                        components={{ span: <S.HoursAmount /> }}
                        values={{ hours: full ? protocol.fullHours : protocol.hours }}
                    />
                </Text>
                <Text
                    type="medium-text"
                    medium
                    text={full ? protocol.description : protocol.title}
                    // @ts-ignore
                    dataLocator={full ? 'fastingProtocolDescription' : 'fastingProtocolCardDescription'}
                />
            </S.Description>
            <S.Timer alt="fasting-protocol-icon" src={protocol.image} />
        </S.Container>
    );
};

export default ProtocolCard;
