import styled, { css } from 'styled-components';

// theme
import mediaQueries from 'theme/mediaQueries';

// mobile blocks styles
const MOBILE_LARGE_BLOCK_STYLES = css`
    height: 644px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 0.9fr 0.6fr;
    gap: 32px 24px;
    grid-template-areas:
        'blockItem1 blockItem1'
        'blockItem2 blockItem3';
    > div:nth-child(1n + 4) {
        display: none;
    }
`;

const MOBILE_SMALL_BLOCK_STYLES = css`
    height: 452px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 24px;
    grid-template-areas:
        'blockItem1'
        'blockItem2';
    > div:nth-child(1n + 3) {
        display: none;
    }
`;

const MOBILE_MEDIUM_BLOCK_STYLES = css`
    height: 200px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 24px;
    grid-template-areas: 'blockItem1 blockItem2';
    > div:nth-child(1n + 3) {
        display: none;
    }
`;

// tablet blocks styles
const TABLET_LARGE_BLOCK_STYLES = css`
    height: 692px;
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 0.9fr 0.6fr;
    gap: 32px 24px;
    grid-template-areas:
        'blockItem1 blockItem1 blockItem1'
        'blockItem2 blockItem3 blockItem4';
    > div:nth-child(1n + 5) {
        display: none;
    }
    > div:nth-child(4) {
        display: flex;
    }
`;

const TABLET_SMALL_BLOCK_STYLES = css`
    height: 744px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 24px;
    grid-template-areas:
        'blockItem1'
        'blockItem2';
    > div:nth-child(1n + 3) {
        display: none;
    }

    > :nth-child(-n + 3) > div > p {
        font-size: 32px;
        line-height: 40px;
    }
`;

const TABLET_MEDIUM_BLOCK_STYLES = css`
    height: 260px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: 24px;
    grid-template-areas: 'blockItem1 blockItem2 blockItem3';
    > div:nth-child(-1n + 3) {
        display: flex;
    }
    > div:nth-child(1n + 4) {
        display: none;
    }
`;

// small desktop blocks styles
const SMALL_DESKTOP_LARGE_BLOCK_STYLES = css`
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 0.9fr 0.6fr;
    grid-template-areas:
        'blockItem1 blockItem1 blockItem1 blockItem1'
        'blockItem2 blockItem3 blockItem4 blockItem5';
    > div:nth-child(-n + 5) {
        display: flex;
    }
`;

const SMALL_DESKTOP_SMALL_BLOCK_STYLES = TABLET_SMALL_BLOCK_STYLES;

const SMALL_DESKTOP_MEDIUM_BLOCK_STYLES = css`
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 24px;
    grid-template-areas: 'blockItem1 blockItem2 blockItem3 blockItem4';
    > div:nth-child(-1n + 4) {
        display: flex;
    }
    > div:nth-child(1n + 5) {
        display: none;
    }
`;

// desktop blocks styles
const DESKTOP_LARGE_BLOCK_STYLES = css`
    height: 692px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 0.7fr) 1fr;
    gap: 32px 24px;
    grid-template-areas:
        'blockItem1 blockItem1 blockItem1 blockItem1 blockItem2 blockItem2'
        'blockItem1 blockItem1 blockItem1 blockItem1 blockItem3 blockItem3'
        'blockItem4 blockItem5 blockItem6 blockItem7 blockItem8 blockItem9';
    > div:nth-child(-n + 9) {
        display: flex;
        > div > p {
            font-size: 18px;
            line-height: 28px;
        }
    }
    > div:first-child {
        > div > p {
            font-size: 40px;
            line-height: 52px;
        }
    }
    > div:nth-child(-1n + 3) {
        flex-direction: row;
        > :first-child {
            align-self: flex-start;
        }
        > :nth-child(2) {
            max-height: 100%;
            max-width: 60%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            margin: 0;
        }
    }
`;

const DESKTOP_SMALL_BLOCK_STYLES = css`
    height: 360px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 24px;
    grid-template-areas: 'blockItem1 blockItem2';
    > div:nth-child(1n + 3) {
        display: none;
    }
`;

const DESKTOP_MEDIUM_BLOCK_STYLES = css`
    height: 344px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 24px;
    grid-template-areas:
        'blockItem1 blockItem2 blockItem3'
        'blockItem4 blockItem5 blockItem6';
    > div:nth-child(-n + 6) {
        display: flex;
        flex-direction: row;
    }
`;

export const CategoryContainer = styled.div<{ blockSize: string }>`
    max-width: 1360px;
    display: grid;
    margin-bottom: 80px;

    ${mediaQueries.mobile} {
        margin-bottom: 0;

        ${({ blockSize }) => {
            switch (blockSize) {
                case 'large':
                default:
                    return MOBILE_LARGE_BLOCK_STYLES;
                case 'small':
                    return MOBILE_SMALL_BLOCK_STYLES;
                case 'medium':
                    return MOBILE_MEDIUM_BLOCK_STYLES;
            }
        }};
    }
    ${mediaQueries.laptop} {
        ${({ blockSize }) => {
            switch (blockSize) {
                case 'large':
                default:
                    return TABLET_LARGE_BLOCK_STYLES;
                case 'small':
                    return TABLET_SMALL_BLOCK_STYLES;
                case 'medium':
                    return TABLET_MEDIUM_BLOCK_STYLES;
            }
        }};
    }
    ${mediaQueries.tabletLandscape} {
        ${({ blockSize }) => {
            switch (blockSize) {
                case 'large':
                default:
                    return SMALL_DESKTOP_LARGE_BLOCK_STYLES;
                case 'small':
                    return SMALL_DESKTOP_SMALL_BLOCK_STYLES;
                case 'medium':
                    return SMALL_DESKTOP_MEDIUM_BLOCK_STYLES;
            }
        }};
    }
    ${mediaQueries.smallDesktop} {
        ${({ blockSize }) => {
            switch (blockSize) {
                case 'large':
                default:
                    return DESKTOP_LARGE_BLOCK_STYLES;
                case 'small':
                    return DESKTOP_SMALL_BLOCK_STYLES;
                case 'medium':
                    return DESKTOP_MEDIUM_BLOCK_STYLES;
            }
        }};
    }
`;
