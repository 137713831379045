import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// router
import routes from 'router/routes';

// components
import ButtonWithArrow from 'components/ButtonWithArrow';
import FastingProtocolItem from './subcomponents/FastingProtocolItem';
import ProtocolCard from './subcomponents/ProtocolCard';

// analytics
import { trackScreenLoad } from 'services/analytics';

// styled
import * as S from './styled';

// content
import { getContent } from './config';

export const FastingProtocol = () => {
    const navigate = useNavigate();
    const params: { protocolId?: string } = useParams();

    useEffect(() => {
        trackScreenLoad();
    }, [params.protocolId]);

    const handleBackClick = () => {
        navigate(routes.PRIVATE.DASHBOARD.path);
    };

    const handleCardClick = (protocolId: string) => {
        navigate(`/dashboard/fasting-protocol/${protocolId}`);
    };

    const { titleMain, titleItem, expertName, expertSubtitle, foodAdviceTitle, protocols } = getContent();

    const curProtocol = protocols.find((protocol) => protocol.id === params.protocolId);

    return (
        <S.MainContainer>
            <S.InnerContainer>
                <S.TitleBox paddingTop={26} paddingBottom={34}>
                    <ButtonWithArrow onClick={handleBackClick} backArrow dataLocator="backButton" />
                    <S.Title
                        text={curProtocol ? titleItem : titleMain}
                        medium
                        type="h4"
                        // @ts-ignore
                        dataLocator="pageTitle"
                    />
                </S.TitleBox>
                {curProtocol ? (
                    <FastingProtocolItem
                        protocol={curProtocol}
                        expertName={expertName}
                        expertSubtitle={expertSubtitle}
                        foodAdviceTitle={foodAdviceTitle}
                        withAdvice={params.protocolId !== '6'}
                    />
                ) : (
                    <S.ContentBox>
                        {protocols.map((protocol) => (
                            <ProtocolCard
                                key={protocol.id}
                                protocol={protocol}
                                onClick={() => handleCardClick(protocol.id)}
                            />
                        ))}
                    </S.ContentBox>
                )}
            </S.InnerContainer>
        </S.MainContainer>
    );
};
