import React from 'react';

// components
import { Image, Text } from 'wikr-core-components';

// styled
import * as S from './styled';

// images
import benefitsIcon from './img/benefits.webp';
import howToMaxIcon from './img/howToMax.webp';
import exercisesIcon from './img/exercises.webp';

interface PointsProps {
    benefit: {
        label: string;
        value: string;
    };
    howToMax: {
        label: string;
        value: string | string[];
    };
    exercises: {
        label: string;
        value: string;
    };
}

const Points = ({ benefit, howToMax, exercises }: PointsProps) => {
    return (
        <S.PointsContainer>
            <S.Point>
                <Image alt="benefits" src={benefitsIcon} />
                <S.PointContent>
                    <Text type="medium-text" bold text={benefit.label} />
                    <Text type="medium-text" text={benefit.value} />
                </S.PointContent>
            </S.Point>
            <S.Point>
                <Image alt="how to maximize" src={howToMaxIcon} />
                <S.PointContent>
                    <Text type="medium-text" bold text={howToMax.label} />
                    {Array.isArray(howToMax.value) ? (
                        <ol>
                            {howToMax.value.map((liItem) => (
                                <li key={liItem}>
                                    <Text type="medium-text" text={liItem} />
                                </li>
                            ))}
                        </ol>
                    ) : (
                        <Text type="medium-text" text={howToMax.value} />
                    )}
                </S.PointContent>
            </S.Point>
            <S.Point>
                <Image alt="exercises" src={exercisesIcon} />
                <S.PointContent>
                    <Text type="medium-text" bold text={exercises.label} />
                    <Text type="medium-text" text={exercises.value} />
                </S.PointContent>
            </S.Point>
        </S.PointsContainer>
    );
};

export default Points;
