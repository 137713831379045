import { TFunction } from 'react-i18next';

// types
import { Captions } from './types';

export const getCaptions = (t: TFunction<'translation', undefined>, date?: string): Captions => {
    return {
        title: t('subscription.cancelDialog.title'),
        description: t('subscription.cancelDialog.description', {
            date,
        }),
        submitButton: t('subscription.text.cancel'),
        discardButton: t('subscription.cancelDialog.discard'),
    };
};
