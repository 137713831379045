import h14 from './img/14h.webp';
import h16 from './img/16h.webp';
import h18 from './img/18h.webp';
import h20 from './img/20h.webp';
import h23 from './img/23h.webp';
import h12_23 from './img/12-23h.webp';

export const BLOCK_CONTENT = [
    {
        hours: '14',
        hoursKey: 'welcome.yourFasting.option.hours',
        title: 'welcome.yourFasting.option.title1',
        description: 'welcome.yourFasting.option.modal.description1',
        image: h14,
    },
    {
        hours: '16',
        hoursKey: 'welcome.yourFasting.option.hours',
        title: 'welcome.yourFasting.option.title2',
        description: 'welcome.yourFasting.option.modal.description2',
        image: h16,
    },
    {
        hours: '18',
        hoursKey: 'welcome.yourFasting.option.hours',
        title: 'welcome.yourFasting.option.title3',
        description: 'welcome.yourFasting.option.modal.description3',
        image: h18,
    },
    {
        hours: '20',
        hoursKey: 'welcome.yourFasting.option.hours',
        title: 'welcome.yourFasting.option.title4',
        description: 'welcome.yourFasting.option.modal.description4',
        image: h20,
    },
    {
        hours: '23',
        hoursKey: 'welcome.yourFasting.option.hours',
        title: 'welcome.yourFasting.option.title5',
        description: 'welcome.yourFasting.option.modal.description5',
        image: h23,
    },
    {
        hours: '12-23',
        hoursKey: 'welcome.yourFasting.option.custom',
        title: 'welcome.yourFasting.option.title6',
        description: 'welcome.yourFasting.option.modal.description6',
        image: h12_23,
    },
];
