import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const FeaturesBlock = styled(Box)`
    position: relative;
    border-radius: 24px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mediaQueries.laptop} {
        padding: 48px 0;
    }

    ${mediaQueries.theSmallestDesktop} {
        padding: 56px 0;
        align-items: start;
    }
`;

export const TitleFeaturesWrap = styled(Box)`
    padding: 0 20px;
    border-radius: 24px;
    margin-bottom: 28px;

    ${mediaQueries.laptop} {
        padding: 0 48px;
    }

    ${mediaQueries.theSmallestDesktop} {
        max-width: 517px;
        padding: 0 0 0 48px;
    }
`;

export const MobileStoreWrap = styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;

    ${mediaQueries.laptop} {
        justify-content: flex-start;
        padding-left: 48px;
    }
`;

export const FeaturesTitle = styled(Text)`
    font-size: 28px;
    line-height: 36px;

    ${mediaQueries.laptop} {
        font-size: 32px;
        line-height: 40px;
    }
`;

export const FeaturesWrap = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    max-width: 482px;
`;

export const OneFeature = styled.div`
    display: flex;
    border: 1px solid #d6d7db;
    border-radius: 100px;
    padding: 12px 20px;
    margin-bottom: 8px;

    margin-right: 4px;

    ${mediaQueries.tablet} {
        margin-right: 8px;
    }

    ${mediaQueries.laptop} {
        margin-bottom: 12px;
        margin-right: 12px;
    }

    ${mediaQueries.theSmallestDesktop} {
        &:nth-of-type(3) {
            margin-right: 0;
        }
    }
`;

export const FeatureName = styled(Text)`
    margin-left: 8px;
    font-size: 14px;
    line-height: 20px;

    ${mediaQueries.laptop} {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const ImgWrap = styled.div`
    max-width: 400px;
    margin-bottom: 40px;

    ${mediaQueries.theSmallestDesktop} {
        margin-bottom: 0;
        position: absolute;
        top: 48px;
        right: 48px;
    }
`;

export const GreenTipBlockWrap = styled(Box)`
    width: 100%;
    max-width: calc(100% - 40px);
    display: flex;
    justify-content: center;

    @media (min-width: 490px) {
        max-width: 100%;
    }

    ${mediaQueries.theSmallestDesktop} {
        justify-content: start;
        padding-left: 48px;
    }
`;
