import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// styled
import * as S from './styled';

// components
import { Text, Image } from 'wikr-core-components';
import { OneSubPage, WantToTryBlock } from './subcomponents';

// hooks
import { useInViewRef } from './useInViewRef';

// img
import plate from './img/plate.webp';
import timer2 from './img/timer2.webp';
import timer3 from './img/timer3.webp';
import timer4 from './img/timer4.webp';
import timer6 from './img/timer6.webp';
import timer8 from './img/timer8.webp';
import timer12 from './img/timer12.webp';
import timer14 from './img/timer14.webp';

// config
import { FOOD_LIST, GLUCOSE, INSULIN, FREE_FATTY, KETOSIS, HUMAN_GROWTH, AUTOPHAGY } from './config';

// helpers
import { getWindowSize } from 'helpers/getWindowSize';

interface TimerBlockProps {
    btnHandler: () => void;
}

export const TimerBlock = ({ btnHandler }: TimerBlockProps) => {
    const { t } = useTranslation();

    const [img, setImg] = useState(timer2);
    const [isShowPlate, setShowPlate] = useState(true);
    const [isWantToTry, setWantToTry] = useState(false);

    const [isFirstContentInView, ref1] = useInViewRef();
    const [isSecondContentInView, ref2] = useInViewRef();
    const [isThirdContentInView, ref3] = useInViewRef();
    const [isFourthContentInView, ref4] = useInViewRef();
    const [isFifthContentInView, ref5] = useInViewRef();
    const [isSixthContentInView, ref6] = useInViewRef();
    const [isSeventhContentInView, ref7] = useInViewRef();
    const [isEighthContentInView, ref8] = useInViewRef();
    const [isNinthContentInView, ref9] = useInViewRef();
    const [isTenthContentInView, ref10] = useInViewRef();

    useEffect(() => {
        if (isSecondContentInView) {
            setShowPlate(true);
        }

        if (isThirdContentInView) {
            setShowPlate(false);
            setImg(timer3);
        }

        if (isFourthContentInView) {
            setImg(timer4);
        }

        if (isFifthContentInView) {
            setImg(timer6);
        }

        if (isSixthContentInView) {
            setImg(timer8);
        }

        if (isSeventhContentInView) {
            setImg(timer12);
        }
        if (isEighthContentInView) {
            setImg(timer14);
            setWantToTry(false);
        }
        if (isTenthContentInView) {
            setWantToTry(true);
        }
    }, [
        isFirstContentInView,
        isSecondContentInView,
        isThirdContentInView,
        isFourthContentInView,
        isFifthContentInView,
        isSixthContentInView,
        isSeventhContentInView,
        isEighthContentInView,
        isTenthContentInView,
    ]);

    const isTablet = getWindowSize(600);

    return (
        <S.TimerBlock backgroundColor="surface-secondary">
            <S.ContentContainer>
                {!isTablet && (
                    <>
                        <S.Title center text={t('welcome.timerBlock.title1')} medium />
                        <S.Subtitle backgroundColor="surface-main" borderRadius={12}>
                            <Text medium type="large-text" text={t('welcome.timerBlock.subtitle')} />
                        </S.Subtitle>
                    </>
                )}
                <S.StickyContent>
                    <S.StickyContentWrapper>
                        {isTablet && (
                            <S.TextTitleContainer isShow={!isWantToTry}>
                                <S.TitlesContainer>
                                    <S.TitleWrapper
                                        isShow={isShowPlate && !isSecondContentInView}
                                        center
                                        text={t('welcome.timerBlock.title1')}
                                        medium
                                    />
                                    <S.TitleWrapper
                                        isShow={isShowPlate && isSecondContentInView && !isThirdContentInView}
                                        center
                                        text={t('welcome.timerBlock.title2')}
                                        medium
                                    />
                                    <S.TitleWrapper
                                        isShow={!isShowPlate}
                                        center
                                        text={t('welcome.timerBlock.title3')}
                                        medium
                                    />
                                </S.TitlesContainer>
                                <S.Subtitle backgroundColor="surface-main" borderRadius={12}>
                                    <Text medium type="large-text" text={t('welcome.timerBlock.subtitle')} />
                                </S.Subtitle>
                            </S.TextTitleContainer>
                        )}
                        <S.ImgWrapGrid isShow={!isWantToTry}>
                            <S.ImgWrap isShow={isShowPlate}>
                                <Image once isForceVisible alt="plate" src={plate} />
                            </S.ImgWrap>
                            <S.ImgWrap isShow={!isShowPlate}>
                                <Image once isForceVisible alt="timer" src={img} />
                            </S.ImgWrap>
                        </S.ImgWrapGrid>
                    </S.StickyContentWrapper>
                    <WantToTryBlock isShow={isWantToTry} btnHandler={btnHandler} />
                </S.StickyContent>
                <S.StickyGradientDesktop />
                <OneSubPage rf={ref1} isInView={isFirstContentInView} emptyBlock />
                <OneSubPage
                    rf={ref2}
                    isInView={isSecondContentInView}
                    emptyBlock={false}
                    foodList={FOOD_LIST}
                    isFoodList
                />
                <OneSubPage
                    rf={ref3}
                    isInView={isThirdContentInView}
                    flexStart={false}
                    emptyBlock={false}
                    description={GLUCOSE}
                />
                <OneSubPage
                    rf={ref4}
                    isInView={isFourthContentInView}
                    flexStart={false}
                    emptyBlock={false}
                    description={INSULIN}
                />
                <OneSubPage
                    rf={ref5}
                    isInView={isFifthContentInView}
                    flexStart={false}
                    emptyBlock={false}
                    description={FREE_FATTY}
                />
                <OneSubPage rf={ref6} isInView={isSixthContentInView} emptyBlock={false} description={KETOSIS} />
                <OneSubPage rf={ref7} isInView={isSeventhContentInView} emptyBlock={false} description={HUMAN_GROWTH} />
                <OneSubPage rf={ref8} isInView={isEighthContentInView} emptyBlock={false} description={AUTOPHAGY} />
                <OneSubPage rf={ref9} isInView={isNinthContentInView} emptyBlock />
                <OneSubPage rf={ref10} isInView={isTenthContentInView} emptyBlock />
            </S.ContentContainer>
        </S.TimerBlock>
    );
};
