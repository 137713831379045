// components
import * as S from './styled';
import { PageContent } from 'pages/welcome/components/TimerBlock/subcomponents';

// types
import { IOneSubPageProps } from './types';

export const OneSubPage = ({
    rf,
    emptyBlock,
    isFoodList = false,
    foodList = [],
    description,
    isInView,
    flexStart = true,
}: IOneSubPageProps) => {
    return (
        <S.OneSubPageWrap emptyBlock={emptyBlock}>
            <S.Content flexStart={flexStart} isFoodList={isFoodList} ref={rf}>
                {!emptyBlock && (
                    <S.AnimationWrap isInView={isInView}>
                        <PageContent isFoodList={isFoodList} description={description} foodList={foodList} />
                    </S.AnimationWrap>
                )}
            </S.Content>
        </S.OneSubPageWrap>
    );
};
