import styled from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const NavLink = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 20px;
    margin-bottom: 16px;

    &:hover {
        cursor: pointer;

        p {
            color: ${color('text-main')};
        }

        svg path {
            fill: ${color('text-main')};
        }
    }
`;

export const SVGWrap = styled.span`
    display: flex;
    align-items: center;
    margin-left: 8px;
`;
