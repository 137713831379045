import styled from 'styled-components';

// theme
import mediaQueriesGrid from 'theme/mediaQueriesGrid';
import { Box } from 'wikr-core-components';

export const TitleContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    ${mediaQueriesGrid.largeTablet} {
        margin-bottom: 20px;
    }
`;

export const InsightsWrap = styled.div<{ isLoading: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ isLoading }) => (isLoading ? '20px' : '0')};
`;
