import React from 'react';
import ContentLoader from 'react-content-loader';

// styled
import {
    ButtonsWrapper,
    ButtonWrapper,
    StackStoriesBackgroundSkeleton,
    StackStoriesSkeleton,
    SkeletonTexts,
} from './styled';

// constants
import { SKELETON_INSIGHT_FIRST_COLOR, SKELETON_INSIGHT_SECOND_COLOR } from 'constants/theme';

interface ISkeletonBlockPreviewLayoutProps {
    skeletonColor: string;
}

const SKELETONS_SIZES = [
    { width: '200px', height: '32px', borderRadius: '6px', marginBottom: '20px' },
    { width: '272px', height: '19px', borderRadius: '6px', marginBottom: '12px' },
    { width: '193px', height: '19px', borderRadius: '6px', marginBottom: '12px' },
    { width: '249px', height: '19px', borderRadius: '6px', marginBottom: '12px' },
    { width: '143px', height: '19px', borderRadius: '6px', marginBottom: '20px' },
    { width: '272px', height: '19px', borderRadius: '6px', marginBottom: '12px' },
    { width: '143px', height: '19px', borderRadius: '6px', marginBottom: '0' },
];

function SkeletonBlockPreviewLayout({ skeletonColor }: ISkeletonBlockPreviewLayoutProps) {
    return (
        <>
            <StackStoriesSkeleton background={skeletonColor} />
            <StackStoriesBackgroundSkeleton background={skeletonColor} />
            <SkeletonTexts>
                {SKELETONS_SIZES.map((skeleton, index) => (
                    <ContentLoader
                        key={index}
                        foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
                        backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
                        style={{
                            width: skeleton.width,
                            height: skeleton.height,
                            borderRadius: skeleton.borderRadius,
                            marginBottom: skeleton.marginBottom,
                        }}
                        animate
                    >
                        <rect x="0" y="0" width="100%" height="100%" />
                    </ContentLoader>
                ))}
            </SkeletonTexts>
            <ButtonsWrapper>
                <ButtonWrapper>
                    <ContentLoader
                        foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
                        backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
                        style={{ width: '44px', height: '44px', borderRadius: '50%' }}
                        animate
                    >
                        <rect x="0" y="0" width="100%" height="100%" />
                    </ContentLoader>
                </ButtonWrapper>
                <ButtonWrapper>
                    <ContentLoader
                        foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
                        backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
                        style={{ width: '140px', height: '44px', borderRadius: '100px' }}
                        animate
                    >
                        <rect x="0" y="0" width="100%" height="100%" />
                    </ContentLoader>
                </ButtonWrapper>
            </ButtonsWrapper>
        </>
    );
}

export default SkeletonBlockPreviewLayout;
