import { useTranslation } from 'react-i18next';

// hooks
import { useChartsData } from '../hooks/useChartsData';

const DEFAULT_FASTING_GOAL = '16';
const DEFAULT_HIDRATION = '1.8';
const DEFAULT_STEPS = '11000';
const CUR_FASTING_RESULT = '54 h 54 m';
const CUR_FASTING_TODAY_RESULT = '00 h 02 m';
const CUR_HIDRATION_RESULT = '1.2 L';
const CUR_STEPS_RESULT = '10,500';
const CHART_COLORS = {
    fastingGoalReached: '#F09F8F',
    fastingGoalNotReached: '#E2C9C9',
    hidrationGoalReached: '#8EBCE1',
    hidrationGoalNotReached: '#D1E6F8',
    stepsGoalReached: '#A6CAC6',
    stepsGoalNotReached: '#CBE0DE',
};

export const getMockChartsConfig = () => {
    const { t } = useTranslation();
    const { fastingData, hidrationData, stepsData } = useChartsData();

    const fastingChartMockData = {
        data: {
            headerText: t('dashboard.statistics.fasting'),
            goalText: t('dashboard.statistics.fastingGoal', { hours: DEFAULT_FASTING_GOAL }),
            goalReachedBg: CHART_COLORS.fastingGoalReached,
            goalNotReachedBg: CHART_COLORS.fastingGoalNotReached,
            chartData: fastingData,
        },
        infoData: {
            curResultTitle: t('dashboard.statistics.goalBlock.weeklyFasting'),
            curResult: CUR_FASTING_RESULT,
            logTodayTitle: t('dashboard.statistics.goalBlock.fastingToday'),
            todayResult: CUR_FASTING_TODAY_RESULT,
        },
    };

    const hidrationChartMockData = {
        data: {
            headerText: t('dashboard.statistics.hidration'),
            goalText: t('dashboard.statistics.hidrationGoal', { litres: DEFAULT_HIDRATION }),
            goalReachedBg: CHART_COLORS.hidrationGoalReached,
            goalNotReachedBg: CHART_COLORS.hidrationGoalNotReached,
            chartData: hidrationData,
        },
        infoData: {
            curResultTitle: t('dashboard.statistics.hidrationAverage'),
            curResult: CUR_HIDRATION_RESULT,
            logTodayTitle: t('dashboard.statistics.hidrationToday'),
            logTodayBtnText: t('dashboard.statistics.logHidration'),
            isArrowUp: true,
        },
    };

    const stepsChartMockData = {
        data: {
            headerText: t('dashboard.statistics.steps'),
            goalText: t('dashboard.statistics.stepsGoal', { steps: DEFAULT_STEPS }),
            goalReachedBg: CHART_COLORS.stepsGoalReached,
            goalNotReachedBg: CHART_COLORS.stepsGoalNotReached,
            chartData: stepsData,
        },
        infoData: {
            curResultTitle: t('dashboard.statistics.stepsAverage'),
            curResult: CUR_STEPS_RESULT,
            logTodayTitle: t('dashboard.statistics.stepsToday'),
            logTodayBtnText: t('dashboard.statistics.logSteps'),
            isArrowUp: true,
        },
    };

    return [fastingChartMockData, hidrationChartMockData, stepsChartMockData];
};
