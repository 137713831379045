import { useEffect, useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import { Text, Button, Box, Image } from 'wikr-core-components';

// helpers
import { isEmpty } from 'helpers/dataStructuresUtils';

// router
import routes from 'router/routes';

// hooks
import { useDispatch, useSelector } from 'hooks/store';

// store
import { signInRequest } from 'store/auth/actions';
import { createProcessingSelector } from 'store/loading/selectors';
import { createErrorSelector } from 'store/errors/selectors';
import { SIGN_IN } from 'store/auth/actionTypes';

// images
import ResetPassword from 'assets/images/reset-password.webp';

// validation
import { LoginSchema } from './validationSchema';

// styled
import * as S from 'containers/AuthFlow/styled';

// components
import FormikInput from 'components/FormikInput';

// config
import { CONTENT } from './config';
import appConfig from 'appConfig';

// analytic
import {
    sendAnalyticLoginButtonClick,
    sendAnalyticLoginError,
    sendAnalyticLoginSuccess,
    sendAnalyticResetPasswordClick,
} from 'services/analytics';
import { useScreenLoad } from 'services/analytics/useScreenLoad';

type FormValue = {
    email: string;
    password: string;
};

export const Login = () => {
    useScreenLoad();

    const { t } = useTranslation();
    const [isFetching, setFetchingStatus] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isLoading = useSelector(createProcessingSelector([SIGN_IN]));
    const error = useSelector(createErrorSelector(SIGN_IN));
    const { redirectUrl } = useSelector((state) => state.auth);

    useEffect(() => {
        if (typeof error === 'object' && error?.message) {
            CONTENT.forEach((field) => setFieldError(field.name, error?.message));
        }
    }, [error]);

    const handleSuccessLogin = () => {
        sendAnalyticLoginSuccess();

        navigate(redirectUrl || routes.PRIVATE.MAIN.path);
    };

    const {
        errors,
        dirty,
        values,
        initialValues,
        setFieldTouched,
        setFieldValue,
        touched,
        handleSubmit,
        setFieldError,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,
        onSubmit: (userData: FormValue) => {
            setFetchingStatus(true);

            dispatch(
                signInRequest({
                    userData,
                    onSuccess: handleSuccessLogin,
                    onError: handleLoginError,
                })
            );
        },
    });

    const onSubmitHandler = (event?: FormEvent<HTMLFormElement> | undefined) => {
        handleSubmit(event);

        sendAnalyticLoginButtonClick();
    };

    const handleLoginError = () => {
        CONTENT.forEach((field) => setFieldError(field.name, t('Email or password is invalid')));

        setFetchingStatus(false);

        sendAnalyticLoginError();
    };

    return (
        <S.AuthContainer>
            <S.AuthImage>
                <Image src={ResetPassword} alt="reset-password" isForceVisible />
            </S.AuthImage>
            <S.AuthForm>
                <form>
                    <Box paddingBottom={32} paddingX={16}>
                        <Text type="h5" bold center mb={12} text={t('login.title')} />
                        <Text center mb={4} text={t('login.subTitle')} />

                        <Text type="overline" bold center>
                            <a href={appConfig.links.onboarding} data-locator="surveyButton">
                                {t('login.survey')}
                            </a>
                        </Text>
                        <Box mb={44}>
                            {CONTENT.map(({ name, label, type, icon, dataLocator, placeholder, analyticHandler }) => (
                                <FormikInput
                                    key={name}
                                    mb={32}
                                    type={type}
                                    field={name}
                                    label={label}
                                    setFieldTouched={setFieldTouched}
                                    initialValues={initialValues}
                                    setFieldValue={setFieldValue}
                                    setFieldFocused={analyticHandler}
                                    touched={touched}
                                    values={values}
                                    errors={errors}
                                    leftIcon={icon}
                                    dataLocator={dataLocator}
                                    placeholder={placeholder}
                                />
                            ))}
                        </Box>
                        <Button
                            onClick={onSubmitHandler}
                            disabled={!dirty || !isEmpty(errors) || isLoading || isFetching}
                            mb={24}
                            text={t('basics.login')}
                            isLoading={isLoading || isFetching}
                            dataLocator="loginButton"
                        />

                        <Link
                            onClick={sendAnalyticResetPasswordClick}
                            to={routes.PUBLIC.FORGOT_PASSWORD.path}
                            data-locator="forgotPasswordBtn"
                        >
                            <Text type="overline" center bold mb={20} text={t('basics.forgotPassword')} />
                        </Link>
                    </Box>
                </form>
            </S.AuthForm>
        </S.AuthContainer>
    );
};
