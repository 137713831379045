import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Image, Text } from 'wikr-core-components';

// components
import Modal from 'components/Modal';
import OutlinedButton from 'components/OutlinedButton';
import OverlineButton from 'components/OverlineButton';
import LifeTimeBadge from '../../LifeTimeAccessQuestionContainer/components/LifeTimeBadge';

// styled
import * as S from './styled';

// config
import { LOGO_LIST } from './config';

interface SurveyThankYouModalProps {
    onSubmit: () => void;
    onCancel: () => void;
    isLifetime?: boolean;
}

const SurveyThankYouModal = ({ onSubmit, onCancel, isLifetime }: SurveyThankYouModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen onClose={onCancel}>
            {isLifetime ? (
                <LifeTimeBadge />
            ) : (
                <S.ImagesWrapper>
                    {LOGO_LIST.map(({ logo, maxWidth }) => (
                        <Image key={logo} alt="logo" src={logo} maxWidth={maxWidth} />
                    ))}
                </S.ImagesWrapper>
            )}
            <Text text={t('subscription.cancellation.surveyFinal.title')} type="h5" mb={12} center />
            <Text type="medium-text" mb={32} center>
                <Trans i18nKey="subscription.cancellation.surveyFinal.subtitle" />
            </Text>
            <OutlinedButton
                onClick={onSubmit}
                text={t('subscription.text.cancel')}
                mb={20}
                dataLocator="cancelSubTrueBtn"
            />
            <OverlineButton
                onClick={onCancel}
                text={t('subscription.cancelDialog.discard')}
                mb={4}
                dataLocator="cancelSubFalseBtn"
            />
        </Modal>
    );
};

export default memo(SurveyThankYouModal);
