import { createSelector } from 'reselect';

// types
import { RootReducer } from '../rootReducer';
import { SubscriptionsState } from './reducer';

export const selectSubscriptions = (state: RootReducer) => state.subscriptions;

const selectCancellationCandidate = createSelector(
    selectSubscriptions,
    (subscriptions: SubscriptionsState) => subscriptions.cancellationCandidate
);

export { selectCancellationCandidate };
