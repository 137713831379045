import { FloaterProps, Step, Styles } from 'react-joyride';
import { useTranslation } from 'react-i18next';

// helpers
import { getWindowSize } from 'helpers/getWindowSize';

// constants
import { OnboardingClassNames } from 'constants/onboardingFlow';

const commonProps: Partial<Step> = {
    disableBeacon: true,
    spotlightClicks: false,
    offset: 0,
};

const commonPropsWithBottomPlacement: Partial<Step> = {
    ...commonProps,
    floaterProps: {
        placement: 'bottom',
        disableFlip: true,
    },
};

const isDesktop = getWindowSize(1240);

const plateStepProps: Partial<Step> = isDesktop ? { ...commonProps } : { ...commonPropsWithBottomPlacement };

export const FLOATER_PROPS: FloaterProps = {
    placement: 'auto',
    disableAnimation: true,
    styles: {
        arrow: { length: 16, spread: 19 },
        floater: {
            filter: 'none',
            zIndex: 110,
        },
    },
};

export const JOYRIDE_STYLES: Styles = {
    options: {
        arrowColor: '#e1dcf1',
    },
    spotlight: {
        borderRadius: 24,
    },
    overlay: { background: 'rgb(37 45 72 / 50%)' },
};

export const getSteps = (): Step[] => {
    const { t } = useTranslation();

    return [
        {
            target: `.${OnboardingClassNames.first}`,
            title: t('dashboard.onboarding2.title1'),
            content: t('dashboard.onboarding2.content1'),
            ...commonProps,
        },
        {
            target: `.${OnboardingClassNames.second}`,
            title: t('dashboard.onboarding2.title2'),
            content: t('dashboard.onboarding2.content2'),
            ...plateStepProps,
        },
        {
            target: `.${OnboardingClassNames.third}`,
            title: t('dashboard.onboarding2.title3'),
            content: t('dashboard.onboarding2.content3'),
            ...commonProps,
        },
        {
            target: `.${OnboardingClassNames.fourth}`,
            title: t('dashboard.onboarding2.title4'),
            content: t('dashboard.onboarding2.content4'),
            ...commonProps,
        },
        {
            target: `.${OnboardingClassNames.fifth}`,
            title: t('dashboard.onboarding2.title5'),
            content: t('dashboard.onboarding2.content5'),
            ...commonProps,
        },
        {
            target: `.${OnboardingClassNames.sixth}`,
            title: t('dashboard.onboarding2.title6'),
            content: t('dashboard.onboarding2.content6'),
            ...commonProps,
        },
        {
            target: `.${OnboardingClassNames.seventh}`,
            title: t('dashboard.onboarding2.title7'),
            content: t('dashboard.onboarding2.content7'),
            ...commonProps,
        },
    ];
};
