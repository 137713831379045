import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// components
import HeaderPublic from 'components/Header/HeaderPublic';

// types
import { LayoutProps } from '../types';

const AuthLayout = ({ children, titleKey }: LayoutProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet title={t(titleKey)} />
            <HeaderPublic />
            {children}
        </>
    );
};

export default AuthLayout;
