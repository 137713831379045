import styled from 'styled-components';

// components
import { Box } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ContentWrap = styled(Box)`
    width: 100%;
`;

export const ListWrap = styled(Box)`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    ${mediaQueriesGrid.desktop} {
        justify-content: center;
    }
`;

export const List = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`;

export const ListComponent = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 4px;
    gap: 8px;
    border: 1px solid ${color('border-default')};
    border-radius: 12px;
`;
