import styled from 'styled-components';

import { Box, Text, Image } from 'wikr-core-components';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const FoodList = styled.div<{ $flexStart?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 28px;
    width: 100%;
    margin-bottom: 20px;

    ${mediaQueriesGrid.desktop} {
        padding: 0 21px;
        max-width: 351px;
        align-items: ${({ $flexStart }) => ($flexStart ? 'flex-start' : 'flex-end')};
        margin-bottom: 20px;
    }
`;

export const OneFoodWrap = styled.div<{ $rowReverse?: boolean; $isSpaceBetween?: boolean }>`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${mediaQueriesGrid.desktop} {
        ${({ $isSpaceBetween }) => $isSpaceBetween && 'justify-content: space-between'};
        flex-direction: ${({ $rowReverse }) => ($rowReverse ? 'row-reverse' : 'row')};
    }
`;

export const FoodImage = styled(Image)<{ $marginLeft?: boolean }>`
    width: 40px;
    margin-right: 16px;

    ${mediaQueriesGrid.desktop} {
        width: 48px;
        margin-right: ${({ $marginLeft }) => ($marginLeft ? '0' : '16px')};
        margin-left: ${({ $marginLeft }) => ($marginLeft ? '16px' : '0')};
    }
`;

export const DescriptionWrap = styled.div`
    width: 100%;
`;

export const DescriptionTopPart = styled.div`
    display: flex;
    margin-bottom: 20px;

    ${mediaQueriesGrid.desktop} {
        display: block;
    }
`;

export const DescriptionImageWrap = styled(Box)`
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 20px;
        width: 48px;
        height: 48px;
    }
`;

export const Title = styled(Text)`
    font-size: 21px;
    line-height: 32px;

    ${mediaQueriesGrid.desktop} {
        font-size: 32px;
        line-height: 40px;
    }
`;

export const DescriptionImage = styled(Image)`
    width: 20px;
    height: 20px;

    ${mediaQueriesGrid.desktop} {
        width: 32px;
        height: 32px;
    }
`;

export const HoursWrap = styled.div`
    display: flex;
`;

export const HoursImage = styled(Image)`
    width: 16px;
    height: 16px;
    margin-right: 12px;
`;
