import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Image, Text } from 'wikr-core-components';

// types
import { INobelBlockProps } from './types';

export const NobelBlock = ({ content }: INobelBlockProps) => {
    const { t } = useTranslation();

    const { img, title, text } = content;

    return (
        <S.NobelInfoBlock>
            <S.OkImgWrap>
                <Image once isForceVisible alt="ok" src={img} />
            </S.OkImgWrap>
            <S.TextsWrap>
                <Text medium type="h5" text={t(title)} mb={8} />
                <Text text={t(text)} />
            </S.TextsWrap>
        </S.NobelInfoBlock>
    );
};
