import React from 'react';

// components
import { Image, Text } from 'wikr-core-components';

// styled
import * as S from './styled';

interface HeaderProps {
    title: string;
    icon: string;
    statusIndLabel: string;
    statusIndIcon: string;
    isDashboardBlock?: boolean;
}

const STAGE_TIMER = '02:35:02';

const Header = ({ title, icon, statusIndLabel, statusIndIcon, isDashboardBlock }: HeaderProps) => {
    return (
        <S.Header isDashboardBlock={isDashboardBlock}>
            <S.CurrentStatusTitle isDashboardBlock={isDashboardBlock}>
                <S.IconWrapper isDashboardBlock={isDashboardBlock}>
                    <Image alt={title} src={icon} dataLocator={isDashboardBlock ? '' : 'stageIcon'} />
                </S.IconWrapper>
                <Text
                    type="h5"
                    medium
                    text={title}
                    // @ts-ignore
                    dataLocator={isDashboardBlock ? 'fastingStageTitle' : 'stageTitle'}
                />
            </S.CurrentStatusTitle>
            <S.CurrentStatus isDashboardBlock={isDashboardBlock}>
                <Image
                    alt={statusIndLabel}
                    src={statusIndIcon}
                    dataLocator={isDashboardBlock ? 'fastingStageIndicator' : 'stageStatusIndicator'}
                />
                <Text type="caption" medium text={statusIndLabel} />
                <Text
                    type="caption"
                    color="text-secondary"
                    text={STAGE_TIMER}
                    // @ts-ignore
                    dataLocator={isDashboardBlock ? 'fastingStageTimer' : 'stageTimer'}
                />
            </S.CurrentStatus>
        </S.Header>
    );
};

export default Header;
