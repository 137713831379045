import styled from 'styled-components';
import { Box, Text } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const MainContainer = styled(Box)`
    padding: 0 16px;
    max-width: 100vw;
    margin: 0 auto;
    background: ${({ theme }) => theme['surface-secondary']};
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 57px);
`;

export const InnerContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 343px;
    margin-bottom: 80px;

    ${mediaQueriesGrid.tablet} {
        max-width: 768px;
    }

    ${mediaQueriesGrid.desktop} {
        max-width: 1028px;
        padding: 0 40px;
    }
`;

export const TitleBox = styled(Box)`
    display: flex;
    width: 100%;
`;

export const Title = styled(Text)`
    margin-left: 20px;
`;

export const ContentBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${mediaQueriesGrid.tablet} {
        max-width: 768px;
        margin-bottom: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, max-content);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    ${mediaQueriesGrid.desktop} {
        max-width: 1028px;
    }
`;
