export const getStepperPercent = (bmi: number) => {
    switch (true) {
        case bmi >= 25 && bmi < 28:
            return 47;
        case bmi >= 28 && bmi < 31:
            return 70;
        case bmi >= 31:
            return 83;
        default:
            return 11;
    }
};
