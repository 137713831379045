// store
import * as actionTypes from './actionTypes';
import * as action from './actions';

// types
import { InferValueTypes } from 'types/commonTypes';
import { ISubscription, IDiscountSubscription } from 'types/subscription';

import { CancelCandidate } from 'hooks/subscriptions/types';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

export interface SubscriptionsState {
    subscriptions: ISubscription[];
    isLoading: boolean;
    isDiscountLoading: boolean;
    discountSubscription: IDiscountSubscription | null;
    cancellationCandidate: CancelCandidate | null;
}

const initialState: SubscriptionsState = {
    subscriptions: [],
    isLoading: true,
    isDiscountLoading: false,
    discountSubscription: null,
    cancellationCandidate: null,
};

const subscriptionsReducer = (state = initialState, action: ActionType): SubscriptionsState => {
    switch (action.type) {
        case actionTypes.SET_FETCHING_STATUS:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_USER_SUBSCRIPTIONS:
            return { ...state, isLoading: false, subscriptions: action.payload };

        case actionTypes.SET_IS_DISCOUNT_LOADING:
            return { ...state, isDiscountLoading: action.payload };

        case actionTypes.SET_DISCOUNT_SUBSCRIPTION:
            return { ...state, discountSubscription: action.payload };
        case actionTypes.SET_CANCELLATION_CANDIDATE:
            return { ...state, cancellationCandidate: action.payload };
        case actionTypes.RESET_SUBSCRIPTIONS:
            return { ...initialState };

        default:
            return state;
    }
};
export default subscriptionsReducer;
