import styled from 'styled-components';
import { Box, Text } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

import { CONTENT_2 } from 'constants/customContentColors';

export const PractiseBlock = styled(Box)`
    background-color: ${CONTENT_2};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 122px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 120px 64px;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
    }
`;

export const ImgWrap = styled(Box)`
    width: 100%;
    max-width: 343px;
    margin-bottom: 40px;

    ${mediaQueriesGrid.desktop} {
        max-width: 482px;
    }
`;

export const Title = styled(Text)`
    margin-bottom: 20px;

    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const Subtitle = styled(Text)`
    margin-bottom: 40px;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 80px;
        font-size: 24px;
        line-height: 32px;
    }
`;

export const PointsBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    ${mediaQueriesGrid.desktop} {
        align-items: baseline;
        flex-direction: row;
    }
`;

export const BtnWrap = styled.div`
    display: block;
    max-width: 360px;
    margin: 0 auto;
    width: 100%;
`;
