import styled from 'styled-components';
import { Box } from 'wikr-core-components';

// theme
import mediaQueries from 'theme/mediaQueries';

export const MainContainer = styled.div`
    padding: 32px 16px;
    max-width: 1360px;
    margin: 0 auto;

    ${mediaQueries.tablet} {
        padding: 32px 24px;
    }

    ${mediaQueries.theSmallestDesktop} {
        padding: 32px 40px;
    }
`;

export const TitleContainer = styled(Box)`
    padding: 24px 16px 32px;

    ${mediaQueries.mobile} {
        padding: 24px 16px 0;
    }

    ${mediaQueries.laptop} {
        padding: 24px 24px 0;
    }

    ${mediaQueries.tabletLandscape} {
        padding: 24px 24px 16px;
    }

    ${mediaQueries.theSmallestDesktop} {
        padding: 24px 40px 0;
    }
`;

export const CategoryTitleContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    ${mediaQueries.laptop} {
        margin-bottom: 32px;
    }
`;
