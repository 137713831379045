import React from 'react';
import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

// config
import base from 'appConfig/base';

interface ContactUsLinkProps {
    subject: string;
    dataLocator?: string;
    onClick?: () => void;
}

const ContactUsLink = ({ subject, onClick, dataLocator }: ContactUsLinkProps) => {
    const { t } = useTranslation();

    return (
        <a
            href={`mailto:${base.supportEmail}?subject=${subject}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}
            data-locator={dataLocator}
        >
            <Text type="overline" center bold text={t('basics.contactUs')} />
        </a>
    );
};

export default ContactUsLink;
