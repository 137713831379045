import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

// components
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/SideBar';

// hooks
import useFrontChat from 'hooks/frontChat/useFrontChat';

// helpers
import { isUserLoggedIn } from 'helpers/verifiers';

// routes
import routes from '../routes';

// types
import { LayoutProps } from '../types';

// styles
import * as S from './styled';

const CabinetLayout = ({ children, titleKey }: LayoutProps) => {
    if (!isUserLoggedIn()) return <Navigate to={routes.PUBLIC.LOGIN.path} />;

    useFrontChat();

    const { t } = useTranslation();

    return (
        <>
            <Helmet title={t(titleKey)} />
            <Header />
            <Sidebar />
            <S.PageWrapper>{children}</S.PageWrapper>
        </>
    );
};

export default CabinetLayout;
