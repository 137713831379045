import React from 'react';

// styled
import {
    SkeletonPrevNextImg,
    SkeletonPrevNextTextBig,
    SkeletonPrevNextTextSmall,
    SkeletonPrevNextTextWrap,
} from '../styled';

// constants
import { SKELETON_INSIGHT_FIRST_COLOR, SKELETON_INSIGHT_SECOND_COLOR } from 'constants/theme';

export function SkeletonForPrevNextBlocks() {
    return (
        <>
            <SkeletonPrevNextImg
                foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
                backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
                animate
            >
                <rect x="0" y="0" width="100%" height="100%" />
            </SkeletonPrevNextImg>
            <SkeletonPrevNextTextWrap>
                <SkeletonPrevNextTextSmall
                    foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
                    backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
                    animate
                >
                    <rect x="0" y="0" width="100%" height="100%" />
                </SkeletonPrevNextTextSmall>
                <SkeletonPrevNextTextBig
                    foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
                    backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
                    animate
                >
                    <rect x="0" y="0" width="100%" height="100%" />
                </SkeletonPrevNextTextBig>
            </SkeletonPrevNextTextWrap>
        </>
    );
}
