import styled, { keyframes } from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const ChartHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
`;

export const BmiChartWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
    height: 54px;
    margin-bottom: 12px;
`;

export const BmiChart = styled.div`
    position: absolute;
    top: 50px;
    right: 21px;
    left: 21px;
`;

export const movingDot = keyframes`
  0% {
    left: 0;
  }
`;

export const DotWrapper = styled.div<{ stepperPercent: number }>`
    position: absolute;
    top: 50%;
    left: ${({ stepperPercent = 0 }) => `${stepperPercent}%`};
    width: 25px;
    height: 25px;
    transform: translateY(-50%);
    animation: movingDot 3s ease-in;
`;

export const BmiTooltip = styled.div<{ stepperPercent: number }>`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 10px));
    padding: 4px 11px;
    background-color: ${color('secondary-default')};
    white-space: nowrap;
    border-radius: 4px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 8px;
        height: 8px;
        transform: translate(-50%, 4px) rotate(45deg);
        border-radius: 2px;
        background-color: ${color('secondary-default')};
    }
`;

export const BmiDot = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #f2d148;
    border-radius: 50%;
    background: rgb(253 238 179 / 70%);

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        transform: translate(-50%, -50%);
        background: #f2d148;
        border-radius: 50%;
    }
`;

export const BmiTitles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
