// types
import * as actionTypes from './actionTypes';
import { InferValueTypes } from 'types/commonTypes';
import * as action from './actions';

interface AuthState {
    isAuthenticated: boolean;
    isComplete: boolean;
    redirectUrl: string | null;
}

const initialState: AuthState = {
    isAuthenticated: false,
    isComplete: false,
    redirectUrl: null,
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const userReducer = (state = initialState, action: ActionType): AuthState => {
    switch (action.type) {
        case actionTypes.SIGN_IN_SUCCESS:
            return { ...state, isAuthenticated: true, isComplete: true, redirectUrl: null };

        case actionTypes.SET_AUTHENTICATION_STATUS:
            return { ...state, isComplete: true, isAuthenticated: action.payload };

        case actionTypes.LOG_OUT:
            return { ...state, isAuthenticated: false, isComplete: true };

        case actionTypes.SET_AUTHENTICATION_REDIRECT_URL:
            return { ...state, redirectUrl: action.payload };

        default:
            return state;
    }
};
export default userReducer;
