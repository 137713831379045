import styled, { css } from 'styled-components';
import mediaQueries from 'theme/mediaQueries';

export const ImageStyled = styled.img`
    max-height: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
`;

export const TextWrapper = styled.div`
    height: 100%;
`;

export const ImageWrapper = styled.div<{ blockSize: string }>`
    max-height: 75%;
    max-width: 100%;
    margin: 0 auto;

    ${({ blockSize }) => {
        switch (blockSize) {
            case 'large':
                return css`
                    height: 80%;
                    ${ImageStyled} {
                        max-height: 100%;
                        max-width: 100%;
                    }
                `;
            case 'small':
                return css`
                    max-height: 100%;
                    height: 100%;
                    ${ImageStyled} {
                        height: 100%;
                    }
                `;
            case 'medium':
                return css`
                    ${mediaQueries.mobile} {
                        height: 80%;
                        ${ImageStyled} {
                            height: 100%;
                        }
                    }
                `;
        }
    }}};
`;

export const CategoryBlockItem = styled.div<{
    itemId: number;
    backgroundColor: string;
    blockSize: string;
    isSafari: boolean;
}>`
    grid-area: ${({ itemId }) => `blockItem${itemId}`};
    position: relative;
    padding: 16px 16px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: ${({ backgroundColor }) => `${backgroundColor}`};
    border-radius: 24px;
    overflow: hidden;
    text-align: left;

    transition: transform 0.3s, box-shadow 0.3s;

    ${({ blockSize }) => {
        switch (blockSize) {
            case 'large':
            default:
                return css`
                    &:nth-child(1n + 4) {
                        flex-direction: column;
                    }

                    ${mediaQueries.mobile} {
                        flex-direction: column;
                        padding: 0;

                        ${TextWrapper} {
                            padding: 16px 16px 0;
                        }
                    }
                    ${mediaQueries.laptop} {
                        padding: 16px 16px 0;
                        &:nth-child(1n + 2) {
                            flex-direction: column;
                        }

                        ${TextWrapper} {
                            height: auto;
                            padding: 0;
                        }
                    }
                `;
            case 'small':
                return css`
                    ${mediaQueries.mobile} {
                        padding: 16px 0 0 16px;
                    }
                    ${mediaQueries.tabletLandscape} {
                        ${ImageWrapper} {
                            margin: 0;
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                `;
            case 'medium':
                return css`
                    ${mediaQueries.mobile} {
                        padding: 16px 16px 0;
                        flex-direction: column;

                        ${TextWrapper} {
                            height: auto;
                            padding: 0;
                        }
                    }
                    ${mediaQueries.smallDesktop} {
                        ${TextWrapper} {
                            align-self: flex-start;
                            max-width: 40%;
                        }
                        ${ImageWrapper} {
                            max-height: 100%;
                            max-width: 60%;
                            height: 100%;
                            display: flex;
                            justify-content: flex-end;
                            margin: 0;
                        }
                    }
                `;
        }
    }};

    :first-child {
        ${({ blockSize }) =>
            blockSize === 'large' &&
            css`
                ${TextWrapper} {
                    padding: 20px 0 0 20px;
                }
                ${mediaQueries.laptop} {
                    padding: 32px 0 0 32px;
                    flex-direction: row;
                    justify-content: space-between;
                    ${TextWrapper} {
                        padding: 0;
                        max-width: 40%;
                        align-self: flex-start;
                    }
                }

                ${ImageWrapper} {
                    height: 100%;
                    margin-right: 0;
                    min-width: 60%;

                    ${ImageStyled} {
                        max-height: 100%;
                    }

                    ${mediaQueries.mobile} {
                        height: 80%;
                    }

                    ${mediaQueries.laptop} {
                        height: 100%;
                        max-height: 100%;
                        display: flex;
                        justify-content: flex-end;

                        ${ImageStyled} {
                            margin: 0;
                        }
                    }
                }
            `};
    }

    &:hover {
        ${({ isSafari }) =>
            !isSafari &&
            css`
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.26);
            `};
        cursor: pointer;
    }
`;

export const ButtonWrap = styled.div`
    position: absolute;
    left: 16px;
    bottom: 16px;

    ${mediaQueries.laptop} {
        left: 32px;
        bottom: 32px;
    }
`;
