/* eslint-disable max-lines */
export default {
    inline: {
        '--background-color': '#FFF',
        '--brand-primary': '#F09F8F',
        '--brand-secondary': '#4E69F9',
        '--brand-primary-light': '#4E69F9',
        '--some': '#000',
    },
    theme: {
        'primary-default': '#F09F8F',
        'primary-hovered': '#EE8F7D',
        'primary-active': '#EC8774',
        'primary-disabled': '#F5BDB3',

        'secondary-default': '#252D48',
        'secondary-hovered': '#343F65',
        'secondary-active': '#1D2439',
        'secondary-disabled': '#C2C4CC',

        'on-primary': '#FFFFFF',

        'text-main': '#252D48',
        'text-secondary': '#767A86',
        'text-secondary-subdued': '#767A86',
        'border-default': '#D6D7DB',
        'border-subdued': '#E9EAED',

        'surface-main': '#FFFFFF',
        'surface-secondary': '#F2EFEE',
        'surface-default': '#F2EFEE',
        'surface-hovered': '#E9E4E2',

        'success-default': '#7FB89D',
        'success-highlight': '#CBE2D7',
        'success-surface': '#F5FAF7',

        'warning-default': '#F7EC7C',
        'warning-highlight': '#FBF4B6',
        'warning-surface': '#FEFBE7',

        'critical-default': '#DF7373',
        'critical-highlight': '#F4CDCE',
        'critical-surface': '#FCF3F3',

        'action-main': '#303B5E',
        'action-secondary': '#93969F',
        'action-hovered': '#3E4C79',
        'action-pressed': '#1D2439',
        'action-disabled': '#C1C4CC',

        discount: '#64B990',
        rating: '#DDCDD0',
        safe: '#B7D4EC',
        'safe-surface': '#D4CEEA',

        'default-indent': '16',
        'border-radius-small': '8',
        'border-radius-medium': '12',
        'default-box-shadow': '0 8px 16px rgba(238, 143, 125, 0.25)',
    },
};
