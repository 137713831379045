import styled from 'styled-components';

// components
import { Box, Text } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ContentWrap = styled(Box)`
    width: 100%;
`;

export const ListWrap = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

export const ListComponent = styled.li`
    width: 159.5px;
    height: 159.5px;
    position: relative;

    ${mediaQueriesGrid.desktop} {
        width: 142px;
        height: 142px;
    }
`;

export const DayNameWrap = styled(Box)`
    position: absolute;
    top: 8px;
    left: 8px;
`;

export const LinkBlockWrap = styled(Box)`
    position: absolute;
    top: 10px;
    left: 10px;
`;

export const LinkText = styled(Text)`
    max-width: 72px;
`;

export const PDFLink = styled.a`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${color('border-subdued')};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08), 0 2px 34px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
`;
