import { createSelector } from 'reselect';

// types
import { RootReducer } from '../rootReducer';

export const selectModals = (state: RootReducer) => state.modals;

const selectModalId = createSelector(selectModals, (modals) => modals.modalId);
const selectModalProps = createSelector(selectModals, (modals) => modals.modalProps);

export { selectModalId, selectModalProps };
