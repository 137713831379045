import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useDispatch } from 'hooks';

// analytics
import { sendAnalyticInsStoryClick, sendAnalyticInsMainSeeAllClick } from 'services/analytics/trackers/insights';

// redux
import { fetchBlockPreview } from 'store/insights/actions';

export const useInsightsMainRedirect = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [currentBlockId, setCurrentBlockId] = useState<number | null>(null);

    const handleNavigateToCategory = (categoryId: number, categoryName: string) => {
        localStorage.setItem('currentCategory', categoryName);
        sendAnalyticInsMainSeeAllClick(categoryId);

        navigate(`/insights-category/${categoryId}`);
    };

    const getHandleBlockClick = (insightId: number) => (blockId: number) => {
        setCurrentBlockId(blockId);
        dispatch(fetchBlockPreview(blockId));
        sendAnalyticInsStoryClick(insightId, blockId);

        navigate(`/insights-main/${insightId}/${blockId}`);
    };

    return {
        currentBlockId,
        setCurrentBlockId,
        handleNavigateToCategory,
        getHandleBlockClick,
    };
};
