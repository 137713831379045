import React from 'react';
import { useNavigate } from 'react-router-dom';

// types
import { IGoalData } from '../ChartBlock/types';

// routes
import routes from 'router/routes';

// components
import { Text, Box } from 'wikr-core-components';

// styled
import * as S from './styled';

// images
import arrowUp from '../../img/arrowUp.svg';
import arrowDown from '../../img/arrowDown.svg';

interface ChartInfoProps {
    infoBlockData: IGoalData;
}

const ChartInfoBlock = ({ infoBlockData }: ChartInfoProps) => {
    const navigate = useNavigate();

    const handleStartInApp = () => {
        navigate(routes.PRIVATE.MAIN.path);
    };

    const { curResultTitle, curResult, isArrowUp, logTodayTitle, todayResult, logTodayBtnText } = infoBlockData;

    const imgPath = isArrowUp ? arrowUp : arrowDown;

    return (
        <S.GoalContainer>
            <Box>
                <Text text={curResultTitle} type="overline" color="text-secondary" mb={6} />
                <S.ResultWeightWrapper>
                    <Text
                        text={curResult}
                        type="h3"
                        // @ts-ignore
                        dataLocator="weightLost"
                    />
                    <S.ResultImgWrapper src={imgPath} alt="result" />
                </S.ResultWeightWrapper>
            </Box>
            <S.Divider />
            <Box>
                <Text text={logTodayTitle} type="overline" color="text-secondary" mb={12} />
                {todayResult && <Text type="h3" text={todayResult} />}
                {logTodayBtnText && (
                    <S.SmallButton
                        onClick={handleStartInApp}
                        backgroundColor="secondary-default"
                        hoverColor="secondary-hovered"
                        size="small"
                        text={logTodayBtnText}
                    />
                )}
            </Box>
        </S.GoalContainer>
    );
};

export default ChartInfoBlock;
