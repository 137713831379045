import apple from './img/apple.svg';
import grass from './img/grass.svg';
import avocado from './img/avocado.svg';
import beans from './img/beans.svg';
import acorn from './img/acorn.svg';
import egg from './img/egg.svg';

import glucose from './img/glucose.svg';
import insulin from './img/insulin.svg';
import freeFatty from './img/freeFatty.svg';
import ketosis from './img/ketosis.svg';
import humanGrowth from './img/humanGrowth.svg';
import autophagy from './img/autophagy.svg';

export const FOOD_LIST = [
    {
        img: apple,
        text: 'welcome.timerBlock.food.apple',
    },
    {
        img: grass,
        text: 'welcome.timerBlock.food.grass',
    },
    {
        img: avocado,
        text: 'welcome.timerBlock.food.avocadoAcorn',
    },
    {
        img: beans,
        text: 'welcome.timerBlock.food.beansEgg',
    },
    {
        img: egg,
        text: 'welcome.timerBlock.food.beansEgg',
    },
    {
        img: acorn,
        text: 'welcome.timerBlock.food.avocadoAcorn',
    },
];

export const GLUCOSE = {
    img: glucose,
    title: 'welcome.timerBlock.glucose.title',
    text: 'welcome.timerBlock.glucose.text',
    hours: 3,
};

export const INSULIN = {
    img: insulin,
    title: 'welcome.timerBlock.insulin.title',
    text: 'welcome.timerBlock.insulin.text',
    hours: 4,
};

export const FREE_FATTY = {
    img: freeFatty,
    title: 'welcome.timerBlock.freeFatty.title',
    text: 'welcome.timerBlock.freeFatty.text',
    hours: 6,
};

export const KETOSIS = {
    img: ketosis,
    title: 'welcome.timerBlock.ketosis.title',
    text: 'welcome.timerBlock.ketosis.text',
    hours: 8,
};

export const HUMAN_GROWTH = {
    img: humanGrowth,
    title: 'welcome.timerBlock.humanGrowth.title',
    text: 'welcome.timerBlock.humanGrowth.text',
    hours: 12,
};

export const AUTOPHAGY = {
    img: autophagy,
    title: 'welcome.timerBlock.autophagy.title',
    text: 'welcome.timerBlock.autophagy.text',
    hours: 14,
};
