import React from 'react';

// components
import * as S from './styled';

// constants
import { SKELETON_INSIGHT_FIRST_COLOR, SKELETON_INSIGHT_SECOND_COLOR } from 'constants/theme';

function SkeletonDashboardInsight() {
    return (
        <S.SkeletonDashboardInsight
            foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
            backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
            animate
        >
            <rect x="0" y="0" width="100%" height="100%" />
        </S.SkeletonDashboardInsight>
    );
}

export default SkeletonDashboardInsight;
