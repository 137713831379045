import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// styled
import * as S from './styled';

// components
import { Text, Box } from 'wikr-core-components';
import Skeleton from 'components/Skeleton';
import ButtonWithArrow from 'components/ButtonWithArrow';
import CategoryList from './components/CategoryList';
import CategoryListSkeleton from './components/CategoryListSkeleton/CategoryListSkeleton';
import InsightsMainModalContainer from './components/InsightsMainModalContainer';

// hooks
import { useCategories } from 'hooks/insights';
import { useInsightsMainRedirect } from './hooks/useInsightsMainRedirect';

// analytics
import { useInsightsScreenLoad } from 'services/analytics/useInsightsScreenLoad';

// constants
import { SKELETON_INSIGHTS } from './constants';

// helpers
import { getWindowSize } from 'helpers/getWindowSize';

export const InsightsMain = () => {
    useInsightsScreenLoad();

    const { t } = useTranslation();

    const isBigScreen = getWindowSize(768);

    const { categoryId: categoryIdFomParams } = useParams();
    const { userInsights, isLoading, getCategoryById } = useCategories();
    const { currentBlockId, setCurrentBlockId, handleNavigateToCategory, getHandleBlockClick } =
        useInsightsMainRedirect();

    return (
        <Box backgroundColor="surface-secondary" paddingBottom={48}>
            <S.TitleContainer>
                <Text bold={false} text={t('pagesTitles.insights')} type="h3" medium />
            </S.TitleContainer>
            {isLoading || userInsights.length === 0
                ? SKELETON_INSIGHTS.map((insight, insightIndex) => (
                      <S.MainContainer key={insightIndex}>
                          <Skeleton width={115} height={40} mb={38} />
                          <CategoryListSkeleton blocks={insight} insightIndex={insightIndex} />
                      </S.MainContainer>
                  ))
                : userInsights?.map((insight, insightIndex) => (
                      <S.MainContainer key={insight.id} data-locator="insightCategoryContainer">
                          <S.CategoryTitleContainer>
                              <Text
                                  text={insight?.name}
                                  type={isBigScreen ? 'h4' : 'h5'}
                                  medium
                                  // @ts-ignore
                                  dataLocator="insightCategoryTitle"
                              />
                              <ButtonWithArrow
                                  text="insights.seaAll"
                                  onClick={() => handleNavigateToCategory(insight.id, insight.name)}
                                  btnId={`category_${insight.id}`}
                                  dataLocator="seeAllButton"
                              />
                          </S.CategoryTitleContainer>
                          {insight && (
                              <CategoryList
                                  handleBlockClick={getHandleBlockClick(insight.id)}
                                  blocks={insight.blocks}
                                  insightIndex={insightIndex}
                              />
                          )}
                      </S.MainContainer>
                  ))}
            <InsightsMainModalContainer
                blockId={currentBlockId}
                blocks={getCategoryById(Number(categoryIdFomParams))?.blocks || []}
                setBlockId={setCurrentBlockId}
            />
        </Box>
    );
};
