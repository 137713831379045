import obImg1Mobile from './images/obImg1Mobile.webp';
import obImg2Mobile from './images/obImg2Mobile.webp';
import obImg3Mobile from './images/obImg3Mobile.webp';
import obImg1Desktop from './images/obImg1Desktop.webp';
import obImg2Desktop from './images/obImg2Desktop.webp';
import obImg3Desktop from './images/obImg3Desktop.webp';

export const getSliderImg = (isBigScreen: boolean) => {
    return isBigScreen ? [obImg1Desktop, obImg2Desktop, obImg3Desktop] : [obImg1Mobile, obImg2Mobile, obImg3Mobile];
};
