// types
import { SagaActionsProps } from 'types/commonInterfaces';
import { UserStore } from 'types/store/userStore';
import { ChangePasswordRequest, BaseSendEmailRequest, UpdateUserRequest, DeleteUserRequest } from './types';

// actionTypes
import * as actionTypes from 'store/user/actionTypes';

export const getCurrentUserRequest = (payload?: SagaActionsProps) => {
    return {
        type: actionTypes.GET_CURRENT_USER_REQUEST,
        payload,
    } as const;
};

export const getCurrentUserSuccess = (payload: UserStore) => {
    return {
        type: actionTypes.GET_CURRENT_USER_SUCCESS,
        payload,
    } as const;
};

export const getCurrentUserError = (payload: Error) => {
    return {
        type: actionTypes.GET_CURRENT_USER_ERROR,
        payload,
    } as const;
};

export const updateUserEmail = (payload: BaseSendEmailRequest) => {
    return {
        type: actionTypes.UPDATE_USER_EMAIL,
        payload,
    } as const;
};

export const updateUserRequest = (payload: UpdateUserRequest) => {
    return {
        type: actionTypes.UPDATE_USER_REQUEST,
        payload,
    } as const;
};

export const updateUserSuccess = (payload: UserStore) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        payload,
    } as const;
};

export const updateUserError = (payload: Error) => {
    return {
        type: actionTypes.UPDATE_USER_ERROR,
        payload,
    } as const;
};

export const forgotPasswordRequest = (payload: BaseSendEmailRequest) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_REQUEST,
        payload,
    } as const;
};

export const forgotPasswordSuccess = (payload: UserStore) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
        payload,
    } as const;
};

export const forgotPasswordError = (payload: Error) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_ERROR,
        payload,
    } as const;
};

export const changePasswordRequest = (payload: ChangePasswordRequest) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_REQUEST,
        payload,
    } as const;
};

export const changePasswordSuccess = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_SUCCESS,
    } as const;
};

export const changePasswordError = (payload: Error) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_ERROR,
        payload,
    } as const;
};

export const resetUserData = () => {
    return {
        type: actionTypes.RESET_USER_DATA,
    } as const;
};

export const deleteUser = (userId: DeleteUserRequest) => {
    return {
        type: actionTypes.DELETE_USER_DATA,
        payload: userId,
    } as const;
};
