import styled from 'styled-components';

// theme
import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const TabBarContainer = styled.div<{ isDashboardBlock?: boolean }>`
    align-items: center;
    padding: 0;
    gap: ${({ isDashboardBlock }) => (isDashboardBlock ? '8.4px' : '10px')};
    width: 100%;
    height: 48px;
    display: ${({ isDashboardBlock }) => (isDashboardBlock ? 'flex' : 'none')};
    justify-content: ${({ isDashboardBlock }) => (isDashboardBlock ? 'space-between' : 'flex-start')};

    ${mediaQueriesGrid.tablet} {
        display: flex;
    }
`;

export const TabButton = styled.div<{ isDashboardBlock?: boolean }>`
    display: flex;
    align-items: center;
    gap: ${({ isDashboardBlock }) => (isDashboardBlock ? '8.4px' : '10px')};
    width: ${({ isDashboardBlock }) => (isDashboardBlock ? '100%' : 'auto')};

    ${mediaQueriesGrid.tablet} {
        gap: 10px;
    }

    ${mediaQueriesGrid.largeTablet} {
        gap: ${({ isDashboardBlock }) => (isDashboardBlock ? '8.4px' : '10px')};
    }

    ${mediaQueriesGrid.desktop} {
        gap: 10px;
    }
`;

export const IconButton = styled.button<{ isDashboardBlock?: boolean }>`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 32px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08), 0 2px 34px rgba(0, 0, 0, 0.05);
    transition: min-height 0.3s, min-width 0.3s;

    img {
        width: 16px;
        cursor: pointer;
        transition: min-width 0.3s, width 0.3s;
    }

    &.active {
        border: 2px solid ${color('primary-disabled')};
    }

    &.active,
    &:hover {
        min-width: 48px;
        min-height: 48px;

        img {
            width: 32px;
            transition: min-width 0.3s, width 0.3s;
        }
    }
`;

export const Divider = styled.div<{ isDashboardBlock?: boolean }>`
    min-width: ${({ isDashboardBlock }) => (isDashboardBlock ? '5.4px' : '24px')};
    width: ${({ isDashboardBlock }) => (isDashboardBlock ? '100%' : '24px')};
    height: 3px;
    background: ${color('border-subdued')};
    border-radius: 2px;
`;
