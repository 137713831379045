import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Image, Text } from 'wikr-core-components';

// components
import Modal from 'components/Modal';
import OutlinedButton from 'components/OutlinedButton';
import OverlineButton from 'components/OverlineButton';

// styled
import * as S from './styled';

// config
import { LOGO_LIST } from './config';

interface ProductProposeModalProps {
    onSubmit: () => void;
    onViewProducts: () => void;
    onCancel: () => void;
}

const ProductProposeModal = ({ onSubmit, onViewProducts, onCancel }: ProductProposeModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen onClose={onCancel}>
            <S.ImagesWrapper>
                {LOGO_LIST.map(({ logo, maxWidth }) => (
                    <Image key={logo} alt="logo" src={logo} maxWidth={maxWidth} />
                ))}
            </S.ImagesWrapper>
            <Text text={t('subscription.cancellation.productPropose.title')} type="h5" mb={12} center />
            <Text type="medium-text" mb={32} center>
                <Trans i18nKey="subscription.cancellation.productPropose.subtitle" components={{ b: <b /> }} />
            </Text>
            <OutlinedButton
                onClick={onViewProducts}
                text={t('subscription.cancellation.productPropose.btn.viewProduct')}
                mb={20}
                dataLocator="viewProductTrueBtn"
            />
            <OutlinedButton
                onClick={onSubmit}
                text={t('subscription.text.cancel')}
                mb={20}
                dataLocator="cancelSubTrueBtn"
            />
            <OverlineButton
                onClick={onCancel}
                text={t('subscription.cancelDialog.discard')}
                mb={4}
                dataLocator="cancelSubFalseBtn"
            />
        </Modal>
    );
};

export default memo(ProductProposeModal);
