import styled from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const Button = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background-color: ${color('secondary-default')};
    border-radius: 50%;

    &:hover {
        background-color: ${color('secondary-hovered')};
    }

    &:active {
        background-color: ${color('secondary-active')};
    }

    &:disabled {
        cursor: not-allowed;
        background-color: ${color('secondary-disabled')};
    }
`;
