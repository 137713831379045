import styled from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const Button = styled.button<{ minWidth?: number }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: ${({ minWidth }) => (minWidth ? minWidth : 140)}px;
    min-height: 44px;
    padding: 0 16px;
    background-color: ${color('secondary-default')};
    border-radius: 100px;

    &:hover {
        background-color: ${color('secondary-hovered')};
    }

    &:active {
        background-color: ${color('secondary-active')};
    }

    &:disabled {
        cursor: not-allowed;
        background-color: ${color('secondary-disabled')};
    }
`;
