import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// components
import { Text } from 'wikr-core-components';

// hooks
import { useDispatch } from 'hooks';

// styled
import { BlockSmallPreview, BlockSmallPreviewWrapper, ImageWrapper, TextWrapper } from '../styled';

// types
import { IBlockInsightItem, IBlockItem } from 'types/insights';

// redux
import { fetchNextBlockPreview, fetchPrevBlockPreview } from 'store/insights/actions';
import { SkeletonForPrevNextBlocks } from './SkeletonForPrevNextBlocks';

interface SmallBlockPreviewProps {
    block: IBlockItem | IBlockInsightItem;
    onClick: () => void;
    isOpenModal: boolean;
    isLoading: boolean;
    isLoadingBlock: boolean;
    fetchSmallBlockPreview: typeof fetchNextBlockPreview | typeof fetchPrevBlockPreview;
}

export function SmallBlockPreview({
    block,
    onClick,
    isOpenModal,
    isLoading,
    isLoadingBlock,
    fetchSmallBlockPreview,
}: SmallBlockPreviewProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        isOpenModal && block?.id && dispatch(fetchSmallBlockPreview(block?.id));
    }, [block?.id]);

    return (
        <BlockSmallPreviewWrapper>
            {isLoadingBlock || isLoading ? (
                <SkeletonForPrevNextBlocks />
            ) : (
                <>
                    <BlockSmallPreview background={block?.color} onClick={onClick}>
                        <ImageWrapper>
                            <img style={{ maxHeight: '100%' }} src={block?.cover} alt="next block" />
                        </ImageWrapper>
                    </BlockSmallPreview>

                    <TextWrapper background={block?.color}>
                        <Text type="caption" text={t('insights.story')} uppercase />

                        <Text type="small-text" medium text={block?.name} />
                    </TextWrapper>
                </>
            )}
        </BlockSmallPreviewWrapper>
    );
}
