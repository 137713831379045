import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Text } from 'wikr-core-components';

// images
import hours from './img/hours.svg';

// types
import { IPageContentProps } from './types';

// helpers
import { getSplittedLists } from './helpers';

export const PageContent = ({ isFoodList, foodList, description }: IPageContentProps) => {
    const { t } = useTranslation();

    const { firstFoodHalf, secondFoodHalf } = getSplittedLists(foodList);

    return isFoodList ? (
        <>
            <S.FoodList $flexStart>
                {firstFoodHalf.map((food, index) => (
                    <S.OneFoodWrap key={index}>
                        <S.FoodImage isForceVisible once alt="food" src={food.img} />
                        <Text text={t(food.text)} bold type="large-text" />
                    </S.OneFoodWrap>
                ))}
            </S.FoodList>
            <S.FoodList>
                {secondFoodHalf.map((food, index) => (
                    <S.OneFoodWrap $rowReverse $isSpaceBetween key={index}>
                        <S.FoodImage isForceVisible once $marginLeft alt="food" src={food.img} />
                        <Text text={t(food.text)} bold type="large-text" />
                    </S.OneFoodWrap>
                ))}
            </S.FoodList>
        </>
    ) : (
        <>
            {description && (
                <S.DescriptionWrap>
                    <S.DescriptionTopPart>
                        <S.DescriptionImageWrap backgroundColor="surface-main">
                            <S.DescriptionImage once isForceVisible alt={description.title} src={description.img} />
                        </S.DescriptionImageWrap>
                        <div>
                            <S.Title medium text={t(description.title)} mb={7} />
                            <S.HoursWrap>
                                <S.HoursImage once isForceVisible alt="clock" src={hours} />
                                <Text
                                    bold
                                    type="small-text"
                                    text={t('welcome.timerBlock.beginsIn', { hours: description.hours })}
                                />
                            </S.HoursWrap>
                        </div>
                    </S.DescriptionTopPart>
                    <Text type="large-text" text={t(description.text)} />
                </S.DescriptionWrap>
            )}
        </>
    );
};
