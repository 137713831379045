import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// router
import routes from 'router/routes';

// hooks
import { useSelector, useDispatch } from 'hooks';

// redux
import { fetchBlockPreview, setBlockPreview } from 'store/insights/actions';

export const useDashboardInsightsModal = (setBlockId: (id: number | null) => void) => {
    const { blockId: blockIdFomParams, categoryId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { blockPreview } = useSelector(({ insights }) => insights);

    const handleCloseModal = () => {
        navigate(routes.PRIVATE.DASHBOARD.path);
        setBlockId(null);
        dispatch(setBlockPreview(null));
    };

    useEffect(() => {
        if (!blockPreview && Number(blockIdFomParams)) {
            setBlockId(Number(blockIdFomParams));
            dispatch(fetchBlockPreview(Number(blockIdFomParams)));
        }
    }, []);

    useEffect(() => {
        if (!blockIdFomParams && !categoryId) {
            handleCloseModal();
        }
    }, [blockIdFomParams, categoryId]);

    return {
        handleCloseModal,
        blockPreview,
    };
};
