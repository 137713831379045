// components
import CategoryModal from 'components/CategoryModal';

// types
import { InsightCategoryModalContainerProps } from './types';
import { useInsightCategoryModalContainer } from './useInsightCategoryModalContainer';

function InsightCategoryModalContainer({ blockId, setBlockId, handleCloseModal }: InsightCategoryModalContainerProps) {
    const { blockPreview, blocksByCategory, handleNextBlocks, hasMoreBlocks } = useInsightCategoryModalContainer({
        blockId,
        setBlockId,
        handleCloseModal,
    });

    return (
        <CategoryModal
            isOpenModal={Boolean(blockId)}
            onClose={handleCloseModal}
            articles={blockPreview?.articles || []}
            blockPreviewColor={blockPreview?.color || ''}
            skeletonColor={blocksByCategory?.find((el) => el.id === blockId)?.color || ''}
            blockId={blockId}
            handleNextBlocks={handleNextBlocks}
            hasMoreBlocks={hasMoreBlocks}
            blocksByCategory={blocksByCategory}
        />
    );
}

export default InsightCategoryModalContainer;
