import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// hooks
import { useDispatch, useSelector } from 'hooks';
import { useBlocksByCategory } from 'hooks/insights';

// redux
import { fetchBlockPreview } from 'store/insights/actions';

// types
import { InsightCategoryModalContainerProps } from './types';

export const useInsightCategoryModalContainer = ({
    blockId,
    setBlockId,
    handleCloseModal,
}: InsightCategoryModalContainerProps) => {
    const { blockPreview } = useSelector(({ insights }) => insights);

    const dispatch = useDispatch();

    const { categoryId, blockId: blockIdFomParams } = useParams();

    const { blocksByCategory, handleNextBlocks, hasMoreBlocks } = useBlocksByCategory(categoryId);

    useEffect(() => {
        if (!blockId && blockIdFomParams) {
            setBlockId(Number(blockIdFomParams));
            dispatch(fetchBlockPreview(Number(blockIdFomParams)));
        }
    }, []);

    useEffect(() => {
        if (!blockIdFomParams) {
            handleCloseModal();
        }
    }, [blockIdFomParams]);

    return {
        blockPreview,
        blocksByCategory,
        handleNextBlocks,
        hasMoreBlocks,
    };
};
