import styled, { css } from 'styled-components';

import { Box, Text } from 'wikr-core-components';

// mediaQueries
import mediaQueries from 'theme/mediaQueries';

export const PageWrap = styled(Box)`
    min-height: calc(100vh - 57px);
`;

export const MainContainer = styled.div`
    padding: 0 16px;
    max-width: 1360px;
    margin: 0 auto;
    background: ${({ theme }) => theme['surface-secondary']};

    ${mediaQueries.theSmallestDesktop} {
        padding: 0 40px;
    }
`;

export const ImgStyled = styled.img`
    max-width: 100%;
`;

export const BlockContent = styled.div<{ background: string }>`
    background: ${({ background }) => `${background}`};
    border-radius: 16px;
    overflow: hidden;
    position: relative;

    &:hover {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.26);
        cursor: pointer;
    }
`;

export const ButtonWrap = styled.div`
    display: none;
    position: absolute;
    left: 16px;
    bottom: 16px;

    ${mediaQueries.laptop} {
        left: 32px;
        bottom: 32px;
    }
`;

const SmallCard = css`
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    ${ImgStyled} {
        max-height: 150px;
    }
    ${mediaQueries.mobile} {
        height: 200px;

        > :first-child {
            padding: 16px 16px 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            align-self: flex-start;
        }
    }
    ${mediaQueries.laptop} {
        height: 260px;

        > :first-child {
            padding: 16px 16px 0 16px;
            font-size: 18px;
            line-height: 28px;
        }
        ${ImgStyled} {
            max-height: 175px;
        }
    }
`;

const BigCard = css`
    padding: 32px 12px 0 32px;
    height: 360px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > :first-child {
        padding: 0;
        font-size: 32px;
        line-height: 40px;
    }

    ${ImgStyled} {
        max-height: 100%;
    }

    ${ButtonWrap} {
        display: block;
    }
`;

const MediumCard = css`
    padding: 16px 0 0 16px;
    height: 160px;
    display: flex;
    justify-content: space-between;

    ${ImgStyled} {
        max-width: 150px;
    }
    ${mediaQueries.mobile} {
        height: 220px;
        flex-direction: row;
        align-items: self-start;

        > :first-child {
            padding: 0;
        }

        ${ImgStyled} {
            max-height: 100%;
            max-width: 60%;
            display: block;
            align-self: center;
        }
    }
    ${mediaQueries.smallDesktop} {
        height: 160px;
    }
`;

export const BlockGridContainer = styled.div<{ hasPadding: boolean }>`
    position: relative;
    display: grid;
    height: 100%;

    ${({ hasPadding }) =>
        hasPadding &&
        css`
            padding-bottom: 80px;
        `};

    ${mediaQueries.mobile} {
        margin: 0 auto;

        gap: 12px;

        > * {
            ${SmallCard};
        }

        > :nth-child(1),
        > :nth-child(3),
        > :nth-child(5),
        > :nth-child(9) {
            grid-column: 1 / 2;
        }
        > :nth-child(2),
        > :nth-child(4),
        > :nth-child(6),
        > :nth-child(10) {
            grid-column: 2 / 3;
        }

        > :nth-child(7),
        > :nth-child(8),
        > :nth-child(6n + 16),
        > :nth-child(6n + 17) {
            grid-column: 1 / 3;
            ${MediumCard};

            ${ButtonWrap} {
                display: block;
            }
        }

        // preview card
        > :nth-child(11) {
            grid-column: 1 / 3;
            height: 599px;
        }
    }

    ${mediaQueries.laptop} {
        max-width: 1360px;

        gap: 34px 24px;
        > :nth-child(7),
        > :nth-child(8),
        > :nth-child(6n + 16),
        > :nth-child(6n + 17) {
            padding: 0;
            ${ButtonWrap} {
                display: none;
            }
        }

        // row with 2 * 3 blocks
        > :nth-child(1),
        > :nth-child(4),
        > :nth-child(9),
        > :nth-child(8n + 13),
        > :nth-child(8n + 16) {
            grid-column: 1 / 2;
            ${SmallCard}
        }

        > :nth-child(2),
        > :nth-child(5),
        > :nth-child(10),
        > :nth-child(8n + 14),
        > :nth-child(8n + 17) {
            grid-column: 2 / 3;
            ${SmallCard}
        }

        > :nth-child(3),
        > :nth-child(6),
        > :nth-child(8),
        > :nth-child(11),
        > :nth-child(8n + 15),
        > :nth-child(8n + 18) {
            grid-column: 3 / 4;
            ${SmallCard}
        }

        > :nth-child(7),
        > :nth-child(8n + 19) {
            grid-column: 1 / 4;

            ${BigCard};
        }

        > :nth-child(8),
        > :nth-child(8n + 20) {
            grid-column: 1 / 4;

            ${BigCard};
        }

        // preview card
        > :nth-child(12) {
            grid-column: 1 / 4;
            height: auto;
        }
    }

    ${mediaQueries.smallDesktop} {
        max-width: 1360px;

        gap: 64px 24px;

        > :nth-child(7),
        > :nth-child(8),
        > :nth-child(6n + 16),
        > :nth-child(6n + 17),
        > :nth-child(8n + 19),
        > :nth-child(8n + 20) {
            padding: 0;
            > :first-child {
                font-size: 18px;
                line-height: 28px;
            }
            ${ButtonWrap} {
                display: none;
            }
        }
        > :nth-child(1),
        > :nth-child(11n + 13) {
            grid-column: 1 / 2;
            ${SmallCard}
        }
        > :nth-child(2),
        > :nth-child(11n + 14) {
            grid-column: 2 / 3;
            ${SmallCard}
        }
        > :nth-child(3),
        > :nth-child(11n + 15) {
            grid-column: 3 / 4;
            ${SmallCard}
        }
        > :nth-child(4),
        > :nth-child(11n + 16) {
            grid-column: 4 / 5;
            ${SmallCard}
        }
        > :nth-child(5),
        > :nth-child(11n + 17) {
            grid-column: 5 / 6;
            ${SmallCard}
        }
        > :nth-child(6),
        > :nth-child(11n + 18) {
            grid-column: 6 / 7;
            ${SmallCard}
        }
        > :nth-child(7),
        > :nth-child(11n + 19) {
            grid-column: 1 / 4;
            padding: 0;
            ${BigCard};
        }
        > :nth-child(8),
        > :nth-child(11n + 20) {
            grid-column: 4 / 7;
            padding: 0;
            ${BigCard};
        }
        > :nth-child(9),
        > :nth-child(11n + 21) {
            grid-column: 1 / 3;
            ${MediumCard}
        }
        > :nth-child(10),
        > :nth-child(11n + 22) {
            grid-column: 3 / 5;
            ${MediumCard}
        }
        > :nth-child(11),
        > :nth-child(11n + 23) {
            grid-column: 5 / 7;
            ${MediumCard}
        }
        // preview card
        > :nth-child(12) {
            grid-column: 1 / 7;
            height: auto;
        }
    }
`;

export const OutlinedButtonWrapper = styled.div<{ mb?: number }>`
    max-width: 322px;
    margin: 0 auto;
    margin-bottom: ${({ mb }) => mb && `${mb}px`};
    padding: 64px 0 80px 0;
`;

export const TitleBox = styled(Box)`
    display: flex;
`;

export const Title = styled(Text)`
    margin-left: 20px;
`;
