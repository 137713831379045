import { RefObject, useEffect, useRef } from 'react';
import { useIsInViewport } from './useIsInViewport';

type UseInViewRefReturn = [boolean, RefObject<HTMLDivElement>];

export const useInViewRef = (inViewHandler?: () => void): UseInViewRefReturn => {
    const ref = useRef<HTMLDivElement>(null);

    const isElementInView = useIsInViewport(ref);

    useEffect(() => {
        if (isElementInView) {
            inViewHandler && inViewHandler();
        }
    }, [isElementInView]);

    return [isElementInView, ref];
};
