import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Box, Image } from 'wikr-core-components';

// components
import OverlineButton from 'components/OverlineButton';
import Modal from 'components/Modal';

// constants
import { reasons } from './constants';

// styled
import * as S from './styled';

// helpers
import isMobile from 'helpers/isMobile';

// types
import { ICancellationReasonModal } from './types';

// analytics
import { sendAnalyticCancelReasonContinueClick } from 'services/analytics';

// img
import loader from './img/loader.svg';

const CancellationReasonModal = ({ isOpen, isLoading, onSubmit, onDiscard, onClose }: ICancellationReasonModal) => {
    const { t } = useTranslation();

    const [reason, setReason] = useState<string | null>(null);

    const handleSelectReason = (shortKey: string) => () => setReason(shortKey);

    const handleDiscard = () => {
        setReason(null);
        onDiscard();
    };

    const handleCancel = () => {
        if (reason) {
            sendAnalyticCancelReasonContinueClick(reason);

            onSubmit(reason);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            fullscreen={isMobile.any()}
            style={{ content: { maxHeight: '97%', overflow: 'scroll' } }}
        >
            <S.ReasonContainer paddingBottom={4}>
                {isLoading && (
                    <S.LoaderContainer>
                        <Image maxWidth={24} center alt="loader" src={loader} mb={24} isForceVisible />
                    </S.LoaderContainer>
                )}
                <Text type="h5" color="text-main" center mb={24} text={t('subscription.cancellationReason.why')} />
                <Box mb={32}>
                    {reasons.map(({ title, shortKey }) => (
                        <S.ReasonItem
                            key={shortKey}
                            onClick={handleSelectReason(shortKey)}
                            isSelected={shortKey === reason}
                            data-locator="option"
                        >
                            <Text type="medium-text" bold text={t(title)} />
                        </S.ReasonItem>
                    ))}
                </Box>

                <Button
                    disabled={!reason}
                    onClick={handleCancel}
                    mb={20}
                    text={t('basics.continue')}
                    dataLocator="obContinue"
                />
                <OverlineButton
                    text={t('subscription.cancelDialog.discard')}
                    onClick={handleDiscard}
                    dataLocator="keepSubBtn"
                />
            </S.ReasonContainer>
        </Modal>
    );
};

export default memo(CancellationReasonModal);
