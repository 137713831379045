import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// trackers
import { trackScreenLoad } from './trackers/mainTrackers';

export function useInsightsScreenLoad() {
    const params = useParams();

    useEffect(() => {
        trackScreenLoad();
    }, [params.categoryId, params.blockId]);
}
