import styled from 'styled-components';

// components
import { Box, Image } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const Container = styled(Box)`
    min-height: 352px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    gap: 8px;
    isolation: isolate;
    background: ${color('surface-main')};
    border-radius: 32px;
    position: relative;
    margin-bottom: 34px;

    ${mediaQueriesGrid.tablet} {
        flex-direction: row;
        justify-content: flex-start;
    }
`;

export const LogoBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 0;
    gap: 40px;
    border-radius: 24px;
    z-index: 1;

    background: linear-gradient(90deg, #ffffff 68.38%, rgba(255, 255, 255, 0) 100%);

    ${mediaQueriesGrid.tablet} {
        max-width: 392px;
        padding: 32px;
        gap: 92px;
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 12px;
`;

export const LogoIcon = styled(Image)`
    width: 40px;
`;

export const ImageBlock = styled.div`
    max-width: 628px;

    ${mediaQueriesGrid.tablet} {
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
    }
`;

export const StatisticsImg = styled(Image)`
    border-radius: 24px;
`;
