import styled, { css } from 'styled-components';
import { Box } from 'wikr-core-components';

interface IContainer {
    isDisplayFlex?: boolean;
    justifyContent?: string;
    isBackgroundWhite?: boolean;
}

export const Container = styled(Box)<IContainer>`
    width: 100%;
    display: ${({ isDisplayFlex = false }) => (isDisplayFlex ? 'flex' : 'block')};
    justify-content: ${({ isDisplayFlex = false, justifyContent = 'flex-start' }) => isDisplayFlex && justifyContent};
    ${({ isBackgroundWhite = false, theme }) =>
        css`
            > * {
                background-color: ${!isBackgroundWhite ? 'transparent' : theme['surface-main']} !important;
            }
        `};
`;
