import styled, { keyframes, css } from 'styled-components';

import { Text, Image } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

// theme
import { color } from 'theme/selectors';

const content3 = '#E1DCF1'; // TODO: move to common file

export const Container = styled.div`
    width: 100%;
    overflow: hidden;

    background: ${color('surface-default')};
`;

export const BlockContainer = styled.div`
    max-width: 1366px;
    padding: 40px 16px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
`;

const AnimationFrames = keyframes`
    from {
        transform: translateY(15px);
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const BlockAnimation = (delay: string) => css`
    opacity: 0;
    animation: 0.5s ${AnimationFrames} ${delay}s forwards;
`;

export const AppealTitle = styled(Text)`
    ${BlockAnimation('0.2')}
    margin-bottom: 20px;

    ${mediaQueriesGrid.desktop} {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const Title = styled(Text)`
    max-width: 464px;

    ${BlockAnimation('0.4')}

    ${mediaQueriesGrid.desktop} {
        max-width: 610px;
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const SubTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    ${BlockAnimation('0.6')}

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 24px;
    }
`;

export const SubTitle = styled(Text)`
    font-weight: 400;
    padding-left: 8px;

    ${mediaQueriesGrid.desktop} {
        font-size: 18px;
        line-height: 28px;
    }
`;

export const ScrollBtn = styled.button`
    display: flex;
    background: ${content3};
    padding: 32px 40px;
    border-radius: 64px;
    position: relative;
    width: 100%;
    max-width: 344px;
    justify-content: flex-end;
    align-items: center;
    margin-top: 80px;

    :hover {
        cursor: pointer;
    }

    ${BlockAnimation('0.8')}

    ${mediaQueriesGrid.desktop} {
        max-width: 400px;
    }
`;

export const BtnImage = styled.img`
    position: absolute;
    max-width: 120px;
    left: 0;
    bottom: 0;

    ${mediaQueriesGrid.desktop} {
        max-width: 128px;
    }
`;

export const BtnText = styled(Text)`
    max-width: 144px;
    margin-right: 12px;
    text-align: left;

    ${mediaQueriesGrid.desktop} {
        font-size: 21px;
        line-height: 32px;
        max-width: 178px;
    }
`;

const BtnArrowKeyFrames = keyframes`
    from {
        transform: translateY(-5px);
    }
    to {
        transform: translateY(5px);
    }
`;

export const BtnArrow = styled(Image)`
    animation: ${BtnArrowKeyFrames} 0.6s alternate infinite;
`;

const AnimationSidesFrames = (translateX: number) => keyframes`
    from {
        transform: translate(${translateX}%, 15px);
    }
    to {
        transform: translate(${translateX}%, 0);
        opacity: 1;
    }
`;

const SideImageAnimation = (translateX: number) => css`
    opacity: 0;

    animation: 0.5s ${AnimationSidesFrames(translateX)} 1s forwards;
`;

const SideImages = styled.img`
    position: absolute;
    display: none;

    ${mediaQueriesGrid.desktop} {
        display: block;
    }
`;

export const LeftImage = styled(SideImages)`
    max-width: 570px;
    left: 0;
    bottom: 0;
    transform: translateX(-40%);
    ${SideImageAnimation(-40)}
`;

export const RightImage = styled(SideImages)`
    max-width: 400px;
    right: 0;
    top: 0;
    transform: translateX(45%);
    ${SideImageAnimation(45)}
`;
