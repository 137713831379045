import styled from 'styled-components';

// components
import { Box } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const PointsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 32px;

    ${mediaQueriesGrid.desktop} {
        flex-direction: row;
    }
`;

export const Point = styled.div`
    display: flex;
    padding: 0;
    gap: 12px;
    min-width: 273px;

    img {
        width: 40px;
    }
`;

export const PointContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 8px;

    ol {
        font-family: 'Roboto', sans-serif;
        padding-left: 15px;
        list-style-type: decimal;
    }
`;
