import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

// mainTrackers
import { trackClick } from './mainTrackers';

export const sendAnalyticSaveChangesAccountClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.save,
    });
};
export const sendAnalyticSaveNewEmailClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.save,
    });
};
export const sendAnalyticSaveNewPasswordClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.save,
    });
};
