import { ActionEventAction, ElementEventAction, EventTrigger, EventTriggerFixed, Main } from 'wikr-core-analytics';

// mainTrackers
import { trackClick, trackEventFixed } from './mainTrackers';

const SPECIAL_OFFER_MODAL = 'cancel_offer';
const CHECK_OUR_APPS_MODAL = 'check_our_apps';
const FEATURE_IDEAS_SENT = 'feature_ideas_sent';

export const sendAnalyticCancelSubscriptionClick = () => {
    trackClick({
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.cancelSubscription,
    });
};

export const sendAnalyticSubscriptionCancelSuccess = () => {
    trackEventFixed(EventTriggerFixed.subscriptionCancelSuccess);
};

export const sendAnalyticSubscriptionCancelError = (eventLabel: string) => {
    // @ts-ignore
    trackEventFixed('subscription_cancel_error', eventLabel);
};

export const sendAnalyticCancelReasonContinueClick = (eventLabel: string) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.quizContinue,
        eventLabel,
    });
};

export const sendAnalyticSpecialOfferScreenLoad = () => {
    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId: SPECIAL_OFFER_MODAL },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const sendAnalyticSpecialOfferGetDiscountClick = () => {
    trackClick({
        actionEvent: ActionEventAction.continue,
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticSpecialOfferSuccess = (newSubscriptionId: string) => {
    Main.trackNew({
        // @ts-ignore
        eventData: { event: 'success', screenId: SPECIAL_OFFER_MODAL },
        data: {
            event_label: newSubscriptionId,
        },
    });
};

export const sendAnalyticSpecialOfferError = () => {
    Main.trackNew({
        // @ts-ignore
        eventData: { event: 'error', screenId: SPECIAL_OFFER_MODAL },
        data: {
            event_label: 'Subscription plan not changed, try again',
        },
    });
};

export const sendAnalyticProductProposeModalScreenLoad = () => {
    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId: SPECIAL_OFFER_MODAL },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const sendAnalyticCheckOurAppsModalScreenLoad = () => {
    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId: CHECK_OUR_APPS_MODAL },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const sendAnalyticProductProposeModalViewProductsClick = () => {
    trackClick({
        actionEvent: ActionEventAction.continue,
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticProductProposeModalCancelCancellationClick = () => {
    trackClick({
        // @ts-ignore
        actionEvent: 'continue_cancel',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticCheckOurAppsModalExpandClick = (eventLabel: string) => {
    trackClick({
        // @ts-ignore
        actionEvent: 'expand',
        elementEvent: ElementEventAction.button,
        screenIdParam: CHECK_OUR_APPS_MODAL,
        eventLabel,
    });
};

export const sendAnalyticCheckOurAppsModalGetAppClick = (eventLabel: string) => {
    trackClick({
        // @ts-ignore
        actionEvent: 'app',
        elementEvent: ElementEventAction.button,
        screenIdParam: CHECK_OUR_APPS_MODAL,
        eventLabel,
    });
};

export const sendAnalyticCheckOurAppsModalCancelCancellationClick = () => {
    trackClick({
        // @ts-ignore
        actionEvent: 'continue_cancel',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticSurveyModalContinueClick = (eventLabel: string) => {
    trackClick({
        // @ts-ignore
        actionEvent: 'continue_cancel',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
        eventLabel,
    });
};

export const sendAnalyticSurveyThankYouModalCancelClick = () => {
    trackClick({
        // @ts-ignore
        actionEvent: 'cancel_subscription',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticQuestionnaireModalCancelClick = (eventLabel: string | string[]) => {
    trackClick({
        // @ts-ignore
        actionEvent: 'quiz_continue_dissapointed',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
        eventLabel,
    });
};

export const sendAnalyticQuestionnaireModalCustomCancelClick = (eventLabel: string | string[]) => {
    // @ts-ignore
    trackEventFixed(FEATURE_IDEAS_SENT, eventLabel);
};
