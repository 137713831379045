import styled from 'styled-components';

// components
import { Button, Box } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const GoalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    padding: 32px;
    border-radius: 24px;
    background-color: ${color('surface-main')};
    flex-direction: column;
    min-height: 332px;

    ${mediaQueriesGrid.tablet} {
        flex-direction: row;
        min-height: 0;
    }
    ${mediaQueriesGrid.largeTablet} {
        flex-direction: column;
        min-height: 332px;
    }
`;

export const Divider = styled.div`
    border: 1px solid ${color('border-default')};
    margin-bottom: 18px;
`;

export const ResultWeightWrapper = styled(Box)`
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
`;

export const ResultImgWrapper = styled.img`
    margin-left: 8px;
    height: 20px;
    width: 20px;
`;

export const SmallButton = styled(Button)`
    max-width: initial;
    width: fit-content;
    padding: 14px 16px;
    min-height: 44px;
    border-radius: 100px;

    &:active {
        background-color: ${color('secondary-active')} !important;
    }

    &:disabled {
        background-color: ${color('secondary-disabled')} !important;
    }
`;
