import { useSelector } from 'hooks/store';
import { Text, Box } from 'wikr-core-components';

// components
import { Tabs } from 'components/Tabs';
import ProfileTab from 'components/ProfileTab';
import SettingsTab from 'components/SettingsTab';
import SubscriptionsTab from 'components/SubscriptionsTab';

// store
import { selectCurrentUser } from 'store/user/selectors';

// styled
import * as S from './styled';

// constants
import { accountTabs, TABS } from './constants';

// types
import { UserStore } from 'types/store/userStore';

// assets
import defaultUserPic from 'assets/images/default-avatar.svg';

interface IAccountSettings {
    tabId?: string;
    onChangeTab: (tabId: string) => void;
}

const getCurrentTab = (currentTab: string) => {
    switch (currentTab) {
        case accountTabs.Subscription:
            return <SubscriptionsTab />;
        case accountTabs.Settings:
            return <SettingsTab />;
        default:
            return <ProfileTab />;
    }
};

const AccountSettings = ({ tabId, onChangeTab }: IAccountSettings) => {
    const currentUser = useSelector(selectCurrentUser) as UserStore;

    const currentTabId = tabId || accountTabs.Profile;

    return (
        <Box mb={80}>
            <S.Container>
                <S.AvatarWrapper paddingTop={24} paddingBottom={16} paddingX={16}>
                    <S.Image alt="avatar" src={defaultUserPic} />
                    <Text type="h5" text={`${currentUser?.name} ${currentUser?.last_name}`} />
                </S.AvatarWrapper>
                <S.ContentWrapper>
                    <Tabs tabs={TABS} currentTab={currentTabId} onChange={onChangeTab} />
                </S.ContentWrapper>
            </S.Container>
            <S.ContentWrapper paddingX={16}>{getCurrentTab(currentTabId)}</S.ContentWrapper>
        </Box>
    );
};

export default AccountSettings;
