import { TFunction } from 'i18next';

interface IGetPurchasedGuideTitleTranslationKey {
    internalName?: string;
    name: string;
    t: TFunction;
}

export const getPurchasedGuideTitleTranslationKey = ({
    internalName,
    name,
    t,
}: IGetPurchasedGuideTitleTranslationKey) => {
    switch (internalName) {
        case 'weight_loss':
            return t('guides.item.fatBurning');
        case 'fat_burning':
            return t('guides.item.weightLoss');
        default:
            return name;
    }
};
