import { ReactComponent as Dashboard } from './img/Dashboard.svg';
import { ReactComponent as Timer } from './img/Timer.svg';
import { ReactComponent as Recipes } from './img/Recipes.svg';
import { ReactComponent as Insights } from './img/Insights.svg';
import { ReactComponent as Statistics } from './img/Statistics.svg';

export const FEATURES = [
    {
        img: () => <Dashboard />,
        title: 'main.featureBlock.feature1',
    },
    {
        img: () => <Timer />,
        title: 'main.featureBlock.feature2',
    },
    {
        img: () => <Recipes />,
        title: 'main.featureBlock.feature3',
    },
    {
        img: () => <Insights />,
        title: 'main.featureBlock.feature4',
    },
    {
        img: () => <Statistics />,
        title: 'main.featureBlock.feature5',
    },
];
