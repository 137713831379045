import React from 'react';
import { useTranslation } from 'react-i18next';

// types
import { IBlockInsightItem } from 'types/insights';
import { IInsightBlockSize } from 'pages/insights-main/types';

// components
import { Text } from 'wikr-core-components';
import ButtonWithArrow from 'components/ButtonWithArrow';

// styled
import * as S from './styled';

// helpers
import { getTextSize } from 'pages/insights-main/helpers';
import { isSafari } from './helpers';

interface CategoryBlockProps {
    block: IBlockInsightItem;
    index: number;
    blockSize: IInsightBlockSize;
    onBlockClick: (blockId: number) => void;
}

function CategoryBlock({ block, index, blockSize, onBlockClick }: CategoryBlockProps) {
    const { t } = useTranslation();

    const preparedIndex = index + 1;
    const blockTextSize = getTextSize(blockSize, preparedIndex === 1);

    const hasButton = (blockSize === 'large' && preparedIndex === 1) || blockSize === 'small';

    return (
        <S.CategoryBlockItem
            className={`blockItem${preparedIndex}`}
            key={block.id}
            itemId={preparedIndex}
            backgroundColor={block.color}
            onClick={() => onBlockClick(block.id)}
            blockSize={blockSize}
            isSafari={isSafari()}
            data-locator="insightCard"
        >
            <S.TextWrapper>
                <Text
                    text={block.name}
                    color="text-main"
                    type={blockTextSize}
                    medium
                    // @ts-ignore
                    dataLocator="insightCardTitle"
                />
            </S.TextWrapper>
            <S.ImageWrapper blockSize={blockSize}>
                <S.ImageStyled alt="InsightImg" src={block.cover} />
            </S.ImageWrapper>
            {hasButton && (
                <S.ButtonWrap>
                    <ButtonWithArrow
                        text={t('insights.discoverStory')}
                        btnId={`block_${block.id}`}
                        dataLocator="insightDiscoveryStoryButton"
                    />
                </S.ButtonWrap>
            )}
        </S.CategoryBlockItem>
    );
}

export default CategoryBlock;
