import { ComponentType, ComponentProps } from 'react';

import AlertModalContainer from 'components/ModalContainers/AlertModalContainer';
import CancellationReasonModalContainer from 'components/ModalContainers/CancellationReasonModalContainer';
import SpecialOfferModalContainer from 'components/ModalContainers/SpecialOfferModalContainer';
import WaitingModalContainer from 'components/ModalContainers/WaitingModalContainer';
import ProductProposeContainer from 'components/ModalContainers/ProductProposeContainer';
import CheckOurAppsContainer from 'components/ModalContainers/CheckOurAppsContainer';
import SurveyModalContainer from 'components/ModalContainers/SurveyModalContainer';
import SurveyThankYouModalContainer from 'components/ModalContainers/SurveyThankYouModalContainer';
import LifeTimeAccessQuestionContainer from 'components/ModalContainers/LifeTimeAccessQuestionContainer';
import LifeTimeAccessFinalModalContainer from 'components/ModalContainers/LifeTimeAccessFinalModalContainer';
import AdditionalQuestionContainer from 'components/ModalContainers/AdditionalQuestionContainer';

export enum ModalName {
    alertModal = 'alertModal',
    reasonModal = 'reasonModal',
    specialOfferModal = 'specialOfferModal',
    waitingModal = 'waitingModal',
    productPropose = 'productPropose',
    checkOurApps = 'checkOurApps',
    survey = 'survey',
    surveyThankYou = 'surveyThankYou',
    lifeTimeAccessQuestion = 'lifeTimeAccessQuestion',
    lifeTimeAccessFinal = 'lifeTimeAccessFinal',
    additionalQuestion = 'additionalQuestion',
}

export type ModalPropsType = {
    [ModalName.alertModal]: ComponentProps<typeof AlertModalContainer>;
    [ModalName.reasonModal]: ComponentProps<typeof CancellationReasonModalContainer>;
    [ModalName.specialOfferModal]: ComponentProps<typeof SpecialOfferModalContainer>;
    [ModalName.waitingModal]: ComponentProps<typeof WaitingModalContainer>;
    [ModalName.productPropose]: ComponentProps<typeof ProductProposeContainer>;
    [ModalName.checkOurApps]: ComponentProps<typeof CheckOurAppsContainer>;
    [ModalName.survey]: ComponentProps<typeof SurveyModalContainer>;
    [ModalName.surveyThankYou]: ComponentProps<typeof SurveyThankYouModalContainer>;
    [ModalName.lifeTimeAccessQuestion]: ComponentProps<typeof LifeTimeAccessQuestionContainer>;
    [ModalName.lifeTimeAccessFinal]: ComponentProps<typeof LifeTimeAccessFinalModalContainer>;
    [ModalName.additionalQuestion]: ComponentProps<typeof AdditionalQuestionContainer>;
};

export type ModalsMapType = {
    [key in ModalName]: ComponentType<ModalPropsType[key]>;
};
