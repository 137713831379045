import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Box, Image } from 'wikr-core-components';

// img
import water from './img/water.webp';

export const WaterBlock = () => {
    const { t } = useTranslation();

    return (
        <S.WaterBlock backgroundColor="surface-secondary" dataLocator="waterSection">
            <S.ContentContainer>
                <Box>
                    <S.Subtitle center text={t('welcome.water.subtitle')} medium mb={20} />
                    <S.Title center text={t('welcome.water.title')} medium mb={40} />
                </Box>
                <S.ImgWrap>
                    <Image once isForceVisible alt="water" src={water} />
                </S.ImgWrap>
            </S.ContentContainer>
        </S.WaterBlock>
    );
};
