import { useTranslation } from 'react-i18next';
// hooks
import { useDispatch, useModals, useSelector } from 'hooks';

// types
import { ModalName } from 'store/modals/types';

// redux
import { setCancellationCandidate } from 'store/subscriptions/actions';

// components
import AlertModal from './components/AlertModal';

// config
import { getCaptions } from './config';
import { selectCancellationCandidate } from 'store/subscriptions/selectors';

const AlertModalContainer = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();
    const { t } = useTranslation();

    const cancellationCandidate = useSelector(selectCancellationCandidate);

    const captions = getCaptions(t, cancellationCandidate?.expirationDateUI);

    const onSubmit = () => {
        openModal(ModalName.reasonModal);
    };

    const discardCancellationHandler = () => {
        closeModal();

        dispatch(setCancellationCandidate(null));
    };

    return <AlertModal isOpen onDiscard={discardCancellationHandler} onSubmit={onSubmit} captions={captions} />;
};

export default AlertModalContainer;
