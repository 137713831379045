import { TFunction } from 'i18next';

// helpers
import { isMaleGender } from 'helpers/utils';

// yoga
import yogaLogo from 'assets/projectsLogo/yogaLogo.webp';
import yogaPreview from './img/yoga/yogaPreview.webp';
import yoYoga from './img/yoga/yoYoga.webp';
import yoPilates from './img/yoga/yoPilates.webp';
import yoStretching from './img/yoga/yoStretching.webp';
import yoFace from './img/yoga/yoFace.webp';
import yoBreathing from './img/yoga/yoBreathing.webp';
import yoRelaxing from './img/yoga/yoRelaxing.webp';

// fc
import fcLogo from 'assets/projectsLogo/fcLogo.webp';
import fcPreview from './img/fc/fcPreview.webp';
import fcCardio from './img/fc/fcCardio.webp';
import fcStretching from './img/fc/fcStretching.webp';
import fcStrength from './img/fc/fcStrength.webp';
import fcMorning from './img/fc/fcMorning.webp';
import fcPilates from './img/fc/fcPilates.webp';
import fcFacelift from './img/fc/fcFacelift.webp';

// mb
import mbLogo from 'assets/projectsLogo/mbLogo.webp';
import mbPreview from './img/mb/mbPreview.webp';
import mbCardio from './img/mb/mbCardio.webp';
import mbHome from './img/mb/mbHome.webp';
import mbStrength from './img/mb/mbStrength.webp';
import mbGym from './img/mb/mbGym.webp';

// types
import { IProductCard } from './components/ProductCard/types';

export const getAppPreviewConfig = (t: TFunction, gender: string | null): IProductCard[] => {
    const isMale = isMaleGender(gender);

    const displayedProjects: IProductCard[] = [
        {
            header: {
                title: t('subscription.cancellation.checkOurApps.header.yogaSubtitle'),
                logo: yogaLogo,
                projectName: 'Yoga-Go',
            },
            rating: [
                {
                    downloads: '35 000+',
                    ratingKey: t('subscription.cancellation.checkOurApps.appleRating'),
                },
                {
                    downloads: '45 000+',
                    ratingKey: t('subscription.cancellation.checkOurApps.googleRating'),
                },
            ],
            appPreviewData: {
                title: t('subscription.cancellation.checkOurApps.yogaTitle'),
                img: yogaPreview,
            },
            programs: [yoYoga, yoPilates, yoStretching, yoFace, yoBreathing, yoRelaxing],
            programsDisclaimer: t('subscription.cancellation.checkOurApps.appPreview.disclaimer'),
            profits: [
                t('subscription.cancellation.checkOurApps.appPreview.yoga.option1'),
                t('subscription.cancellation.checkOurApps.appPreview.yoga.option2'),
                t('subscription.cancellation.checkOurApps.appPreview.yoga.option3'),
                t('subscription.cancellation.checkOurApps.appPreview.yoga.option4'),
            ],
        },
        {
            header: {
                title: t('subscription.cancellation.checkOurApps.header.fcSubtitle'),
                logo: fcLogo,
                projectName: 'FitCoach',
            },
            rating: [
                {
                    downloads: '100 000+',
                    ratingKey: t('subscription.cancellation.checkOurApps.appleRating'),
                },
                {
                    downloads: '95 000+',
                    ratingKey: t('subscription.cancellation.checkOurApps.googleRating'),
                },
            ],
            appPreviewData: {
                title: t('subscription.cancellation.checkOurApps.fcTitle'),
                img: fcPreview,
            },
            programs: [fcCardio, fcStretching, fcStrength, fcMorning, fcPilates, fcFacelift],
            programsDisclaimer: t('subscription.cancellation.checkOurApps.appPreview.disclaimer'),
            profits: [
                t('subscription.cancellation.checkOurApps.appPreview.fc.option1'),
                t('subscription.cancellation.checkOurApps.appPreview.fc.option2'),
                t('subscription.cancellation.checkOurApps.appPreview.fc.option3'),
                t('subscription.cancellation.checkOurApps.appPreview.fc.option4'),
                t('subscription.cancellation.checkOurApps.appPreview.fc.option5'),
                t('subscription.cancellation.checkOurApps.appPreview.fc.option6'),
            ],
        },
    ];

    if (isMale) {
        displayedProjects.push({
            header: {
                title: t('subscription.cancellation.checkOurApps.header.mbSubtitle'),
                logo: mbLogo,
                projectName: 'Muscle Booster',
            },
            rating: [
                {
                    downloads: '125 000+',
                    ratingKey: t('subscription.cancellation.checkOurApps.appleRating'),
                },
                {
                    downloads: '75 000+',
                    ratingKey: t('subscription.cancellation.checkOurApps.googleRating'),
                },
            ],
            appPreviewData: {
                title: t('subscription.cancellation.checkOurApps.mbTitle'),
                img: mbPreview,
            },
            programs: [mbCardio, mbHome, mbStrength, mbGym],
            profits: [
                t('subscription.cancellation.checkOurApps.appPreview.mb.option1'),
                t('subscription.cancellation.checkOurApps.appPreview.mb.option2'),
                t('subscription.cancellation.checkOurApps.appPreview.mb.option3'),
                t('subscription.cancellation.checkOurApps.appPreview.mb.option4'),
            ],
        });
    }

    return displayedProjects;
};
