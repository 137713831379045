import React from 'react';

// components
import * as S from './styled';
import { Text } from 'wikr-core-components';

// helpers
import { isSafari } from 'helpers/utils';
import { getWindowSize } from 'helpers/getWindowSize';

export interface OneInsight {
    item: { id: number; color: string; name: string; cover: string; position: number; category: number };
    onBlockClick: (value: number) => void;
}

function OneInsight({ item, onBlockClick }: OneInsight) {
    const isDesktop = getWindowSize(905);

    return (
        <S.OneInsight
            data-locator="insightCard"
            onClick={() => onBlockClick(item.id)}
            isSafari={isSafari()}
            backgroundColor={item.color}
        >
            <Text
                text={item.name}
                color="text-main"
                type={isDesktop ? 'large-text' : 'small-text'}
                medium
                // @ts-ignore
                dataLocator="insightTitle"
            />
            <S.ImageWrapper>
                <S.ImageStyled alt="InsightImg" src={item.cover} />
            </S.ImageWrapper>
        </S.OneInsight>
    );
}

export default OneInsight;
