import { useNavigate } from 'react-router-dom';
import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

// hooks
import { useSelector } from 'hooks';

// store
import { createProcessingSelector } from 'store/loading/selectors';
import { FORGOT_PASSWORD } from 'store/user/actionTypes';

// img
import checkMailBoxIcon from 'assets/img/checkMailbox.svg';

// router
import routes from 'router/routes';

// styles
import * as S from './styled';

// components
import OutlinedButton from 'components/OutlinedButton';
import ContactUsLink from 'components/ContactUsLink';

// analytic
import {
    sendAnalyticResetLoginButtonClick,
    sendAnalyticResendPasswordClick,
    sendAnalyticResetContactUsClick,
} from 'services/analytics';

const CheckMailBox = ({ email, onResendPassword }: { email: string; onResendPassword: () => void }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isLoading: boolean = useSelector(createProcessingSelector([FORGOT_PASSWORD]));

    const handleLogin = () => {
        sendAnalyticResetLoginButtonClick();

        navigate(routes.PUBLIC.LOGIN.path);
    };

    const onResendPasswordHandler = () => {
        sendAnalyticResendPasswordClick();

        onResendPassword();
    };

    return (
        <S.CheckMailBox paddingX={16}>
            <Image maxWidth={48} center alt="checkMailBoxIcon" src={checkMailBoxIcon} mb={24} isForceVisible />
            <Text type="h5" bold text={t('forgotPassword.checkMailBox')} mb={12} center />
            <Text text={t('forgotPassword.passwordWasSent')} center />
            {email && <Text bold text={email} mb={32} center />}
            <S.LogInButton text={t('basics.login')} mb={12} onClick={handleLogin} dataLocator="loginButton" />
            <OutlinedButton
                text={t('forgotPassword.resendPassword')}
                onClick={onResendPasswordHandler}
                mb={28}
                isLoading={isLoading}
                dataLocator="resendPasswordBtn"
            />
            <ContactUsLink subject={t('forgotPassword.title')} onClick={sendAnalyticResetContactUsClick} />
        </S.CheckMailBox>
    );
};

export default CheckMailBox;
