import styled from 'styled-components';
import { Text } from 'wikr-core-components';

import { color } from 'theme/selectors';

const content3 = '#CBE0DE';

export const ModalContainer = styled.div`
    background: radial-gradient(85% 85% at 50% 50%, #fefefe 26.94%, #ddcdd0 100%);
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    // TODO: change to fixed breakpoint after realization welcome page
    @media (min-width: 375px) {
        max-height: 640px;
    }
`;

export const TextWrapper = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    max-width: 368px;
`;

export const DividerWrapper = styled.div`
    min-height: 300px;
    display: flex;
    justify-content: center;
`;

export const ContentContainer = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 12px 16px 8px;
    background: ${color('surface-main')};
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 24px 24px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PriceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 284px;
    width: 100%;
    padding-bottom: 12px;
`;

export const OldPriceWrapper = styled.div`
    background: ${color('surface-secondary')};
    border-radius: 24px;
    padding: 12px 17px;
    margin-top: 6px;
`;

export const OldPrice = styled(Text)`
    position: relative;
    :before {
        content: '';
        position: absolute;
        left: 16.67%;
        top: 48.68%;
        width: 70%;
        border: 1px solid ${color('text-main')};
        transform: rotate(-11.44deg);
    }
`;

export const OldPriceTextWrapper = styled.div`
    width: 90px;
`;

export const NewPriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${content3};
    border-radius: 24px;
    padding: 16px 14px;
    position: relative;
`;

export const NewPriceAmount = styled.div`
    display: flex;
`;

export const NewPriceTextWrapper = styled.div`
    width: 118px;
`;
