import { delay, put, take, takeLatest, fork, cancel, cancelled, all, call } from 'redux-saga/effects';
import { Task } from '@redux-saga/types';
import { CancelledEffect } from '@redux-saga/core/effects';

// api
import api from 'api';

// actions
import {
    setSubscriptions,
    setSubscriptionsFetchingStatus,
    cancelSubscription,
    fetchSubscriptions,
    fetchDiscountSubscriptions,
    setIsDiscountLoading,
    setDiscountSubscription,
    updateSubscription as updateSubscriptionAction,
} from './actions';
import { notifyError, notifySuccess, notify } from '../notifications/actions';

// types
import * as actionTypes from './actionTypes';
import { ICancelSubscription } from './types';
import { IDiscountSubscription, ISubscription } from 'types/subscription';
import { SubscriptionCancelResponse } from 'api/types/response';

// helpers
import { sortFullAccessFirst } from './helpers';

// constants
import { DEFAULT_CANCEL_SUBSCRIPTION_ERROR } from 'constants/subscriptions';

function* getSubscription() {
    try {
        const response: ISubscription[] = yield call(api.subscriptions.getSubscriptions);

        yield put(setSubscriptions(sortFullAccessFirst(response)));
        yield put(setSubscriptionsFetchingStatus(false));
    } catch (error) {
        notifyError('getSubscription error');
    }
}

function* makeSubscriptionCancelling({ payload }: ReturnType<typeof cancelSubscription>) {
    try {
        const workerTask: Task = yield fork(callUnsubscribe, payload);
        yield take(actionTypes.DISCARD_SUBSCRIPTION_CANCELLATION);
        yield cancel(workerTask);
    } catch (error) {
        payload.onError();
    }
}

function* callUnsubscribe(payload: ICancelSubscription) {
    try {
        yield delay(3000);

        const response: SubscriptionCancelResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: payload.externalId,
        });

        if (!response.result) {
            throw new Error(DEFAULT_CANCEL_SUBSCRIPTION_ERROR);
        }

        yield put(fetchSubscriptions());
        yield put(notifySuccess('subscription.cancellation.response.success'));
        payload.onSuccess();
    } catch (error: any) {
        yield put(notifyError('subscription.cancellation.response.error'));
        payload.onError(error?.error || DEFAULT_CANCEL_SUBSCRIPTION_ERROR);
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();
        if (isCancelled) {
            yield put(notify('subscription.cancellation.response.abort'));
            payload.onCancel();
        }
    }
}

function* getDiscountSubscription({ payload }: ReturnType<typeof fetchDiscountSubscriptions>) {
    try {
        yield put(setIsDiscountLoading(true));

        const response: IDiscountSubscription = yield call(api.subscriptions.getDiscountSubscriptions, {
            external_id: payload,
        });

        yield put(setDiscountSubscription(response));
        yield put(setIsDiscountLoading(false));
    } catch (error) {
        yield put(setIsDiscountLoading(false));
    }
}

function* updateSubscription({ payload }: ReturnType<typeof updateSubscriptionAction>) {
    const { updatedSubscription, onSuccess, onError } = payload;
    try {
        yield put(setIsDiscountLoading(true));

        yield call(api.subscriptions.updateSubscription, updatedSubscription);

        yield put(setIsDiscountLoading(false));

        yield call(onSuccess);
        yield put(notifySuccess('subscription.cancellation.specialOffer.success'));
    } catch (error) {
        yield put(notifyError('subscription.cancellation.specialOffer.error'));

        yield call(onError);

        yield put(setIsDiscountLoading(false));
    }
}

export default function* watchSubscriptions() {
    yield all([
        takeLatest(actionTypes.FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(actionTypes.CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
        takeLatest(actionTypes.FETCH_DISCOUNT_SUBSCRIPTION, getDiscountSubscription),
        takeLatest(actionTypes.UPDATE_SUBSCRIPTION, updateSubscription),
    ]);
}
