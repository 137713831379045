import config from 'appConfig';

import { ActionEventAction } from 'wikr-core-analytics';

import routes from 'router/routes';

// helpers
import { getFaqLinks, getPolicyLink } from 'helpers/getFAQLink';
import { getDeviceOs } from 'helpers/getDeviceOs';

// images
import dashboard from './img/Dashboard.webp';
import insights from './img/Insights.webp';
import timer from './img/Timer.webp';
import settings from './img/Settings.webp';
import app from './img/App.webp';
import guide from './img/Guide.webp';
import plan from './img/Plan.webp';

const deviceOs = getDeviceOs();

export const PRIMARY_SIDEBAR_LINKS = [
    {
        to: routes.PRIVATE.DASHBOARD.path,
        title: 'pagesTitles.dashboard',
        icon: dashboard,
        dataLocator: 'burgerDashboard',
    },
    {
        to: routes.PRIVATE.MAIN.path,
        title: 'sidebar.app',
        icon: app,
        dataLocator: 'burgerFastEasyApp',
        analyticActionEvent: ActionEventAction.app,
    },
    {
        to: routes.PRIVATE.INSIGHTS_MAIN.path,
        title: 'pagesTitles.insights',
        icon: insights,
        dataLocator: 'burgerInsights',
    },
    {
        to: routes.PRIVATE.FITNESS_GUIDES.path,
        title: 'sidebar.guides',
        icon: guide,
        dataLocator: 'burgerGuides',
        analyticActionEvent: ActionEventAction.guides,
    },
    {
        to: routes.PRIVATE.WELCOME.path,
        title: 'sidebar.welcome', // What is fasting
        icon: timer,
        dataLocator: 'burgerWhatIsFasting',
    },
    {
        to: routes.PRIVATE.MY_FASTING_PLAN.path,
        title: 'sidebar.myFastingPlan',
        icon: plan,
        dataLocator: 'burgerMyFastingPlan',
    },
    {
        to: routes.PRIVATE.ACCOUNT_DEFAULT.path,
        title: 'sidebar.settings',
        icon: settings,
        dataLocator: 'burgerAccountSettings',
        analyticActionEvent: ActionEventAction.accountSettings,
    },
];

export const SECONDARY_SIDEBAR_LINKS = [
    {
        to: getFaqLinks(deviceOs),
        title: 'sidebar.faq',
        dataLocator: 'burgerFAQ',
        target: '_blank',
        rel: 'noopener noreferrer',
        withSvg: true,
        analyticActionEvent: ActionEventAction.faq,
    },
    {
        to: getPolicyLink(config.links.termsOfUse),
        title: 'sidebar.terms',
        dataLocator: 'burgerTermsOfUse',
        target: '_blank',
        rel: 'noopener noreferrer',
        analyticActionEvent: ActionEventAction.termsOfUse,
    },
    {
        to: getPolicyLink(config.links.privacyPolicy),
        title: 'sidebar.privacy',
        dataLocator: 'burgerPrivacyPolicy',
        target: '_blank',
        rel: 'noopener noreferrer',
        analyticActionEvent: ActionEventAction.privacyPolicy,
    },
    {
        to: getPolicyLink(config.links.refundPolicy),
        title: 'sidebar.refund',
        dataLocator: 'burgerRefundPolicy',
        target: '_blank',
        rel: 'noopener noreferrer',
        analyticActionEvent: ActionEventAction.refundPolicy,
    },
];
