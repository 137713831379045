import { RouterProvider } from 'react-router-dom';

// theme
import ThemeProvider from './theme/provider';

// pages
import router from './router';

// containers
import NotificationsContainer from 'containers/Notifications';

// components
import ErrorBoundary from 'components/ErrorBoundary';

function App() {
    return (
        <ThemeProvider>
            <NotificationsContainer />
            <ErrorBoundary>
                <RouterProvider router={router} />
            </ErrorBoundary>
        </ThemeProvider>
    );
}

export default App;
