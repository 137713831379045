import styled from 'styled-components';

// components
import { Box, Image } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;

    min-width: 48%;

    background: ${color('surface-hovered')};
    border-radius: 24px;

    ${mediaQueriesGrid.desktop} {
        max-width: 399px;
    }
`;

export const Expert = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 12px;

    max-width: 311px;
`;

export const ExpertIcon = styled(Image)`
    width: 51px;
    margin-bottom: -10px;
    margin-right: -7px;
`;

export const ExpertDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 2px;

    max-width: 251px;
`;
