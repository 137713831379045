import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BrowserStorage from 'api/utils/browserStorage';

// components
import * as S from './styled';
import { Text } from 'wikr-core-components';
import { HelpfulOption } from './subcomponents';

// config
import { HELPFUL_ANSWERS } from './config';

// services
import { sendAnalyticUserChoiceButtonClick } from 'services/analytics/trackers/welcome';

export const WasThisHelpfulBlock = () => {
    const [checkedAnswer, setCheckedAnswer] = useState<string | null>(null);

    useEffect(() => {
        const lsAnswer = BrowserStorage.getItem('wifHelpfulAnswer');

        setCheckedAnswer(lsAnswer);
    }, []);

    const handleAnswer = (value: string) => {
        if (value !== checkedAnswer) {
            localStorage.setItem('wifHelpfulAnswer', value);
            setCheckedAnswer(value);

            sendAnalyticUserChoiceButtonClick(value === 'Yes');
        }
    };

    const { t } = useTranslation();

    return (
        <S.WasThisHelpfulBlock backgroundColor="surface-secondary" dataLocator="feedbackSection">
            <S.ContentContainer borderRadius={40}>
                <S.Title center text={t('welcome.wasThisHelpful.title')} medium mb={16} />
                <Text center type="large-text" text={t('welcome.wasThisHelpful.subtitle')} mb={32} />
                <S.OptionsWrap>
                    {HELPFUL_ANSWERS.map((answer, index) => (
                        <HelpfulOption
                            key={index}
                            svg={answer.svg}
                            value={answer.value}
                            text={answer.text}
                            checked={checkedAnswer === answer.value}
                            onChange={() => handleAnswer(answer.value)}
                            dataLocator={answer.dataLocator}
                        />
                    ))}
                </S.OptionsWrap>
            </S.ContentContainer>
        </S.WasThisHelpfulBlock>
    );
};
