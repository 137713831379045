import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// styled
import * as S from './styled';

// img
import { ReactComponent as ExternalLinkSvg } from './img/ExternalLink.svg';

interface SidebarNavLinkProps {
    to?: string;
    title: string;
    dataLocator?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    target?: string;
    rel?: string;
    withSvg?: boolean;
}

const SidebarNavLink = ({ to, title, onClick, dataLocator, target = '_self', rel, withSvg }: SidebarNavLinkProps) => {
    const { t } = useTranslation();

    return (
        <a href={to} data-locator={dataLocator} onClick={onClick} target={target} rel={rel}>
            <S.NavLink>
                <div>
                    <Text ellipsis={1} type="small-text" color="text-secondary" text={t(title)} />
                </div>
                {withSvg && (
                    <S.SVGWrap>
                        <ExternalLinkSvg />
                    </S.SVGWrap>
                )}
            </S.NavLink>
        </a>
    );
};

export default SidebarNavLink;
