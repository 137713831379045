import { useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// components
import ButtonWithArrow from 'components/ButtonWithArrow';
import OutlinedButton from 'components/OutlinedButton';
import BlocksSkeleton from './components/BlocksSkeleton';
import InsightCategoryModalContainer from './components/InsightCategoryModalContainer';
import InsightCategoryBlockPreview from './components/InsightCategoryBlockPreview';

// styled
import * as S from './styled';

// hooks
import { useInsightsCategory } from './hooks/useInsightsCategory';

// analytics
import { useInsightsScreenLoad } from 'services/analytics/useInsightsScreenLoad';

const InsightsCategory = () => {
    useInsightsScreenLoad();

    const { t } = useTranslation();

    const {
        blockId,
        blocksByCategory,
        blockPreviewLayout,
        hasMoreBlocks,
        isLoading,
        isPreviewLayoutLoading,
        isBlocksNextLoading,
        previewPosition,
        setBlockId,
        handleNextBlocks,
        handleNavigateBackToAllCategories,
        handleCloseModal,
        handleOpenModal,
    } = useInsightsCategory();

    return (
        <S.PageWrap backgroundColor="surface-secondary">
            <S.MainContainer>
                <S.TitleBox paddingTop={26} paddingBottom={34}>
                    <ButtonWithArrow
                        onClick={() => handleNavigateBackToAllCategories()}
                        backArrow
                        dataLocator="backButton"
                    />
                    <S.Title
                        text={localStorage.getItem('currentCategory') || ''}
                        medium
                        type="h4"
                        // @ts-ignore
                        dataLocator="pageTitle"
                    />
                </S.TitleBox>
                <S.BlockGridContainer hasPadding={!hasMoreBlocks}>
                    {isLoading || blocksByCategory.length === 0 ? (
                        <BlocksSkeleton />
                    ) : (
                        blocksByCategory.map((block, blockIndex) =>
                            blockIndex === previewPosition ? (
                                <InsightCategoryBlockPreview
                                    key={block.id}
                                    isPreviewLayoutLoading={isPreviewLayoutLoading}
                                    block={block}
                                    blockPreviewLayout={blockPreviewLayout}
                                />
                            ) : (
                                <S.BlockContent
                                    key={block.id}
                                    background={block.color}
                                    className={blockIndex === 11 ? 'preview' : ''}
                                    onClick={() => handleOpenModal(block.id)}
                                    data-locator="insightCard"
                                >
                                    <Text
                                        text={block.name}
                                        type="large-text"
                                        // @ts-ignore
                                        dataLocator="insightCardTitle"
                                    />
                                    <S.ImgStyled alt="block preview" src={block.cover} />
                                    <S.ButtonWrap>
                                        <ButtonWithArrow
                                            text={t('insights.discoverStory')}
                                            btnId={`block_${block.id}`}
                                            dataLocator="insightDiscoveryStoryButton"
                                        />
                                    </S.ButtonWrap>
                                </S.BlockContent>
                            )
                        )
                    )}
                </S.BlockGridContainer>
                {hasMoreBlocks && !isLoading && (
                    <S.OutlinedButtonWrapper>
                        <OutlinedButton
                            isBackgroundWhite
                            onClick={handleNextBlocks}
                            text={t('insights.showMoreBtn')}
                            isDisplayFlex
                            justifyContent="center"
                            isLoading={isBlocksNextLoading}
                            dataLocator="showMoreButton"
                        />
                    </S.OutlinedButtonWrapper>
                )}

                <InsightCategoryModalContainer
                    blockId={blockId}
                    setBlockId={setBlockId}
                    handleCloseModal={handleCloseModal}
                />
            </S.MainContainer>
        </S.PageWrap>
    );
};

export default InsightsCategory;
