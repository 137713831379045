import styled from 'styled-components';
import { Box, Text } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const WaterBlock = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 122px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 120px 64px;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
        flex-direction: row-reverse;
    }
`;

export const ImgWrap = styled(Box)`
    width: 100%;
    max-width: 343px;

    ${mediaQueriesGrid.desktop} {
        max-width: 613px;
        margin-right: 20px;
    }
`;

export const Title = styled(Text)`
    font-size: 36px;
    line-height: 44px;
    max-width: 524px;

    ${mediaQueriesGrid.desktop} {
        max-width: 100%;
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const Subtitle = styled(Text)`
    font-size: 18px;
    line-height: 28px;

    ${mediaQueriesGrid.desktop} {
        font-size: 24px;
        line-height: 32px;
    }
`;
