import React from 'react';
import { Image } from 'wikr-core-components';

// images
import ResetPassword from 'assets/images/reset-password.webp';

// styles
import * as S from 'containers/AuthFlow/styled';

// components
import ForgotPasswordForm from 'components/ForgotPasswordForm';

// analytics
import { useScreenLoad } from 'services/analytics/useScreenLoad';

export const ForgotPassword = () => {
    useScreenLoad();

    return (
        <S.AuthContainer>
            <S.AuthImage>
                <Image src={ResetPassword} alt="reset-password" isForceVisible />
            </S.AuthImage>
            <S.AuthForm>
                <ForgotPasswordForm />
            </S.AuthForm>
        </S.AuthContainer>
    );
};
