import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IOption, Text } from 'wikr-core-components';

// components
import Modal from 'components/Modal';
import MultipleReasonOptionsBlock from './components/MultipleReasonOptions';
import OverlineButton from 'components/OverlineButton';
import TextFieldBlock from './components/TextFieldBlock';

const MAX_INPUT_LENGTH = 200;

interface AdditionalQuestionModalProps {
    title: string;
    optionsData: IOption[];
    onSubmit: (fixedReason: string[], customReason?: string) => void;
    onDiscard: () => void;
}

const AdditionalQuestionModal = ({ title, optionsData, onSubmit, onDiscard }: AdditionalQuestionModalProps) => {
    const { t } = useTranslation();
    const [isValid, setIsValid] = useState(true);
    const [fixedReason, setFixedReason] = useState<string[]>([]);
    const [customReason, setCustomReason] = useState('');

    const onChangeHandler = (value: string) => {
        if (value.length > MAX_INPUT_LENGTH && isValid) {
            setIsValid(false);
        } else if (value.length <= MAX_INPUT_LENGTH && !isValid) {
            setIsValid(true);
        }

        setCustomReason(value);
    };

    const onSubmitHandler = () => {
        onSubmit(fixedReason, customReason);
    };

    return (
        <Modal
            isOpen
            onClose={onDiscard}
            dataLocator="cancelSubModal"
            style={{ content: { maxHeight: '97%', overflow: 'scroll' } }}
        >
            <Text text={title} type="h5" center mb={20} />
            <MultipleReasonOptionsBlock optionsData={optionsData} onChange={setFixedReason} />

            <TextFieldBlock value={customReason} onChange={onChangeHandler} hasError={!isValid} />
            <Button
                onClick={onSubmitHandler}
                mb={20}
                text={t('basics.continue')}
                dataLocator="obContinue"
                disabled={!isValid}
            />
            <OverlineButton
                text={t('subscription.cancelDialog.discard')}
                onClick={onDiscard}
                dataLocator="keepSubBtn"
            />
        </Modal>
    );
};

export default memo(AdditionalQuestionModal);
