import { ActionEventAction, ElementEventAction, EventTriggerFixed } from 'wikr-core-analytics';

// mainTrackers
import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticEmailUpdate = () => {
    trackEventFixed(EventTriggerFixed.emailUpdateSuccess);
};

export const sendAnalyticPasswordUpdate = () => {
    trackEventFixed(EventTriggerFixed.passwordUpdateSuccess);
};

export const sendAnalyticProfileUpdateSuccess = (updatedFields: string[]) => {
    trackEventFixed(EventTriggerFixed.profileUpdateSuccess, updatedFields);
};

export const sendAnalyticProfileUpdateError = () => {
    trackEventFixed(EventTriggerFixed.profileUpdateError);
};

export const sendAnalyticDeleteUserClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.accountDelete,
    });
};

export const sendAnalyticDeleteUserSuccess = () => {
    trackEventFixed(EventTriggerFixed.accountDeleteSuccess);
};

export const sendAnalyticTakeQuizClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.takeQuiz,
    });
};
