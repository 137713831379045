import styled from 'styled-components';

// components
import { Box } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ContentBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${mediaQueriesGrid.desktop} {
        max-width: 1028px;
        flex-direction: row;
    }
`;

export const AdviceBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${mediaQueriesGrid.tablet} {
        flex-direction: row;
    }

    ${mediaQueriesGrid.desktop} {
        flex-direction: column;
        min-width: 359px;
    }
`;
