import styled from 'styled-components';

// components
import { Text } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HoursContainer = styled.div`
    border-radius: 50%;
    background: ${color('surface-secondary')};
    height: 132px;
    width: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

export const HoursTitle = styled.span`
    display: block;
    font-weight: 900;
    font-size: 40px;
    line-height: 52px;
`;

export const TextHours = styled(Text)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const TextDescription = styled(Text)`
    font-weight: 400;
`;
