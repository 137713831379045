import * as S from './styled';

import { Image } from 'wikr-core-components';

interface ProgramsBlockProps {
    programs: string[];
}

const ProgramsBlock = ({ programs }: ProgramsBlockProps) => {
    return (
        <S.ProgramsContainer>
            {programs.map((img) => (
                <S.ProgramImage key={img}>
                    <Image alt="program" src={img} />
                </S.ProgramImage>
            ))}
        </S.ProgramsContainer>
    );
};

export default ProgramsBlock;
