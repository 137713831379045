import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { StoreButton } from 'wikr-core-components';

// theme
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const SkeletonWrapper = styled(Skeleton)<{ isMobile: boolean }>`
    margin-left: ${(props) => !props.isMobile && '10px'};
`;

export const StoreBtn = styled(StoreButton)<{ isMobile: boolean; smallBtn: boolean }>`
    max-width: ${({ smallBtn }) => (smallBtn ? '162px !important' : '216px !important')};
    margin-bottom: ${(props) => props.isMobile && '21px !important'};
    display: block;

    ${mediaQueriesGrid.tablet} {
        max-width: 162px !important;
    }
`;
