import { animated } from '@react-spring/web';

// styled
import styled from 'styled-components';

// components
import { Text } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const OneDefinitionWrap = styled(animated.div)`
    margin-bottom: 64px;

    &:last-of-type {
        margin-bottom: 0;
    }

    &:nth-of-type(2) {
        width: 50%;
        align-self: flex-end;
    }

    ${mediaQueriesGrid.tablet} {
        margin-bottom: 124px;
        display: flex;
        align-items: center;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    ${mediaQueriesGrid.desktop} {
        &:nth-of-type(1),
        &:nth-of-type(3) {
            width: 57%;
        }

        &:nth-of-type(2) {
            width: 34%;
        }
    }
`;

export const DefinitionImgWrap = styled.div`
    margin-bottom: 16px;
    max-width: 166px;
    width: 100%;

    ${mediaQueriesGrid.tablet} {
        max-width: 163px;
        margin-bottom: 0;
        margin-right: 16px;
    }

    ${mediaQueriesGrid.desktop} {
        max-width: 295px;
        margin-right: 20px;
    }
`;

export const DefinitionText = styled(Text)`
    ${mediaQueriesGrid.desktop} {
        font-size: 32px;
        line-height: 40px;
    }
`;
