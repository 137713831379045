import { InstallApp } from 'pages/install-app';
import { Guides } from 'pages/guides';
import { Login } from 'pages/login';
import { ForgotPassword } from 'pages/forgot-password';
import { AccountSettingsPage } from 'pages/account';
import { ChangeEmail } from 'pages/change-email';
import { ChangePassword } from 'pages/change-password';
import { AuthorisedForgotPassword } from 'pages/forgot-password-a';
import { Dashboard } from 'pages/dashboard';
import { FastingProtocol } from 'pages/fasting-protocol';
import { InsightsMain } from 'pages/insights-main/InsightsMain';
import BodyStatus from 'pages/body-status';
import InsightsCategory from 'pages/insights-category';
import MyFastingPlan from 'pages/my-fasting-plan';
import Welcome from 'pages/welcome';

export const PRIVATE = {
    ACCOUNT: {
        path: '/account/:tabId',
        component: AccountSettingsPage,
        titleKey: 'pagesTitles.account',
    },
    ACCOUNT_DEFAULT: {
        path: '/account',
        component: AccountSettingsPage,
        titleKey: 'pagesTitles.account',
    },
    CHANGE_EMAIL: {
        path: '/change-email',
        component: ChangeEmail,
        titleKey: 'pagesTitles.changeEmail',
    },
    CHANGE_PASSWORD: {
        path: '/change-password',
        component: ChangePassword,
        titleKey: 'pagesTitles.changePassword',
    },
    AUTHORISED_FORGOT_PASSWORD: {
        path: '/forgot-password-a',
        component: AuthorisedForgotPassword,
        titleKey: 'pagesTitles.forgotPassword',
    },
    MAIN: {
        path: '/main',
        component: InstallApp,
        titleKey: 'pagesTitles.installApp',
    },
    FITNESS_GUIDES: {
        path: '/guides',
        component: Guides,
        titleKey: 'pagesTitles.guides',
    },
    INSIGHTS_MAIN: {
        path: '/insights-main',
        component: InsightsMain,
        titleKey: 'pagesTitles.insights',
    },
    INSIGHTS_MAIN_ITEM: {
        path: '/insights-main/:categoryId/:blockId',
        component: InsightsMain,
        titleKey: 'pagesTitles.insights',
    },
    INSIGHTS_CATEGORY: {
        path: '/insights-category/:categoryId',
        component: InsightsCategory,
        titleKey: 'pagesTitles.insights',
    },
    INSIGHTS_CATEGORY_BLOCK: {
        path: '/insights-category/:categoryId/:blockId',
        component: InsightsCategory,
        titleKey: 'pagesTitles.insights',
    },
    MY_FASTING_PLAN: {
        path: '/my-fasting-plan',
        component: MyFastingPlan,
        titleKey: 'pagesTitles.fastingPlan',
    },
    WELCOME: {
        path: '/welcome',
        component: Welcome,
        titleKey: 'pagesTitles.welcome', // TODO: needs clarification about tab title
    },
    DASHBOARD: {
        path: '/dashboard',
        component: Dashboard,
        titleKey: 'pagesTitles.dashboard',
    },
    DASHBOARD_ONBOARDING_1: {
        path: '/dashboard/onboarding1',
        component: Dashboard,
        titleKey: 'pagesTitles.dashboard',
    },
    DASHBOARD_ONBOARDING_2: {
        path: '/dashboard/onboarding2',
        component: Dashboard,
        titleKey: 'pagesTitles.dashboard',
    },
    DASHBOARD_ITEM: {
        path: '/dashboard/:categoryId/:blockId',
        component: Dashboard,
        titleKey: 'pagesTitles.dashboard',
    },
    BODY_STATUS: {
        path: '/dashboard/body-status/:statusId',
        component: BodyStatus,
        titleKey: 'pagesTitles.bodyStatus',
    },
    FASTING_PROTOCOL: {
        path: '/dashboard/fasting-protocol/',
        component: FastingProtocol,
        titleKey: 'pagesTitles.fastingProtocol',
    },
    FASTING_PROTOCOL_ITEM: {
        path: '/dashboard/fasting-protocol/:protocolId',
        component: FastingProtocol,
        titleKey: 'pagesTitles.fastingProtocol',
    },
};

export const PUBLIC = {
    LOGIN: {
        path: '/',
        component: Login,
        titleKey: 'pagesTitles.login',
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        component: ForgotPassword,
        titleKey: 'pagesTitles.forgotPassword',
    },
};

export default {
    PRIVATE,
    PUBLIC,
};
