import styled from 'styled-components';

// components
import { Image } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    background: ${color('surface-main')};
    border-radius: 24px;

    ${mediaQueriesGrid.tablet} {
        &.full {
            min-width: 609px;
            height: fit-content;
        }
    }

    ${mediaQueriesGrid.desktop} {
        &.full {
            min-width: 569px;
        }
        min-width: 464px;
    }
`;

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
`;

export const HoursAmount = styled.span`
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
`;

export const Timer = styled(Image)`
    width: 96px;
    height: 96px;
`;
