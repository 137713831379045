import React from 'react';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

// hooks
import { useGraphicData } from '../../hooks/useGraphicData';

// components
import { Text } from 'wikr-core-components';
import WeightInfoBlock from '../WeightInfoBlock';

// styled
import * as S from './styled';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    annotationPlugin
);

const DEFAULT_WEIGHT_LOSS = 0;

interface WeightLostGraphBlockProps {
    isDemo: boolean;
}

const WeightLostGraphBlock = ({ isDemo }: WeightLostGraphBlockProps) => {
    const { t } = useTranslation();
    const { data, options, targetWeight, unit, startWeekDate, endWeekDate, weekLostWeight } = useGraphicData(isDemo);

    return (
        <S.ContentWrapper>
            <S.GraphicContainer data-locator="weightLossGraphic">
                <S.HeaderWrapper>
                    <Text type="small-text" color="secondary-default" medium text={t('dashboard.statistics.weight')} />
                    <S.StatisticsWrapper>
                        <Text
                            text={t('dashboard.statistics.goal', { targetWeight, unit })}
                            type="small-text"
                            // @ts-ignore
                            dataLocator="goalWeight"
                        />
                        <Text
                            text={`${startWeekDate} – ${endWeekDate}`}
                            type="small-text"
                            // @ts-ignore
                            dataLocator="dateRange"
                        />
                    </S.StatisticsWrapper>
                </S.HeaderWrapper>
                <S.GraphicWrapper>
                    <Line data={data} options={options} />
                </S.GraphicWrapper>
            </S.GraphicContainer>
            <WeightInfoBlock
                targetWeight={targetWeight}
                unit={unit}
                weekLostWeight={weekLostWeight || DEFAULT_WEIGHT_LOSS}
                isDemo={isDemo}
            />
        </S.ContentWrapper>
    );
};

export default WeightLostGraphBlock;
