import styled, { css } from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const SideBarWrapper = styled.div<{ isOpen: boolean; isDesktop: boolean }>`
    position: fixed;
    top: 57px;
    left: 0;
    width: ${({ isDesktop }) => (isDesktop ? '240px' : '100vw')};
    height: calc(100vh - 56px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    z-index: 999;

    ${({ isOpen }) =>
        isOpen &&
        css`
            opacity: 1;
            visibility: visible;

            ${SideBarOverlay} {
                opacity: 0.8;
            }

            ${SideBarMenu} {
                left: 0;
            }
        `}
`;

export const SideBarOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(37, 45, 72, 0.5);
    opacity: 0;
    transition: opacity 0.4s ease;
`;

export const SideBarMenu = styled.div`
    position: absolute;
    top: 0;
    left: -100%;
    width: 240px;
    height: 100%;
    box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
    background: ${color('surface-hovered')};
    transition: all 0.3s;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
`;

export const User = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 12px 12px;
    gap: 12px;
    margin-bottom: 12px;
`;

export const Avatar = styled.div`
    cursor: pointer;
`;
