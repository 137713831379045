import styled from 'styled-components';

export const SkeletonBlock = styled.div`
    padding: 0 !important;
    border-radius: 16px;
    overflow: hidden;

    > svg {
        padding: 0 !important;
    }
`;
