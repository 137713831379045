// hooks
import { useCancelSubscription, useModals, useSelector } from 'hooks';

// types
import { ModalName } from 'store/modals/types';

// redux
import { selectSubscriptions } from 'store/subscriptions/selectors';

const VIP_SUPPORT = 'vip_support';
const SPECIFIC_REASONS = [
    {
        reasons: ['app_not_meets_expectations', 'no_app_need'],
        modal: ModalName.productPropose,
    },
    {
        reasons: ['disappointed_with_the_content'],
        modal: ModalName.additionalQuestion,
    },
    {
        reasons: ['dont_want_to_auto-renew'],
        modal: ModalName.lifeTimeAccessQuestion,
    },
];

export const useCancelSubscriptionModals = () => {
    const { openModal } = useModals();
    const { discountSubscription, cancellationCandidate } = useSelector(selectSubscriptions);
    const { onCancelSubscription } = useCancelSubscription();

    const defaultCancelSubscriptionHandler = () => {
        openModal(ModalName.waitingModal);
        onCancelSubscription();
    };

    const specificCancelSubscriptionHandler = (reason: string) => {
        const specReason = SPECIFIC_REASONS.find((specReason) => specReason.reasons.includes(reason));

        if (specReason) {
            openModal(specReason.modal);
        } else if (discountSubscription) {
            openModal(ModalName.specialOfferModal);
        } else {
            defaultCancelSubscriptionHandler();
        }
    };

    const cancelSubscriptionHandler = (reason: string) => {
        if (cancellationCandidate?.productCode === VIP_SUPPORT) {
            defaultCancelSubscriptionHandler();
        } else {
            specificCancelSubscriptionHandler(reason);
        }
    };

    return { cancelSubscriptionHandler };
};
