import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Text } from 'wikr-core-components';

// img
import { ReactComponent as Check } from './img/Check.svg';

// types
import { IOnePointProps } from './types';

export const OnePoint = ({ title, text }: IOnePointProps) => {
    const { t } = useTranslation();

    return (
        <S.OnePointWrap>
            <S.CheckWrap>
                <Check />
            </S.CheckWrap>
            <Text text={t(title)} medium type="h4" mb={20} />
            <Text text={t(text)} type="large-text" />
        </S.OnePointWrap>
    );
};
