import styled from 'styled-components';

// theme
import { color } from 'theme/selectors';

// constants
import { CONTENT_1, CONTENT_3 } from 'constants/customContentColors';

// components
import { Text } from 'wikr-core-components';

export const FullComponent = styled.div<{ demoOn: boolean }>`
    padding: 14px 16px;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 24px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: ${({ demoOn }) => (demoOn ? CONTENT_3 : color('surface-hovered'))};
`;

export const Name = styled(Text)`
    margin-right: 12px;
`;

export const ToggleLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
`;

export const ToggleSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${color('surface-main')};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 12px;

    &:before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: ${CONTENT_1};
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }
`;

export const ToggleInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${ToggleSlider}:before {
        background-color: ${color('primary-default')};
    }

    &:checked + ${ToggleSlider}:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }
`;
