import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Image, Text } from 'wikr-core-components';

// config
import { getTargetZonesContent } from './config';

interface ITargetZonesProps {
    targetZones: string[];
    gender: string | null;
}

const TargetZonesBlock = ({ targetZones, gender }: ITargetZonesProps) => {
    const { t } = useTranslation();

    const zones = getTargetZonesContent(targetZones, gender);

    return (
        <S.ContentWrap mb={48}>
            <Text center type="large-text" medium text={t('myFastingPlan.targetZones.title')} mb={20} />
            <S.ListWrap
                backgroundColor="surface-main"
                paddingBottom={16}
                paddingX={16}
                paddingTop={16}
                borderRadius={16}
            >
                <S.List>
                    {zones.map((zone, index) => (
                        <S.ListComponent key={index}>
                            <Image isForceVisible once maxWidth={40} src={zone.img} alt="zone" />
                            <Text medium text={t(zone.transKey)} />
                        </S.ListComponent>
                    ))}
                </S.List>
            </S.ListWrap>
        </S.ContentWrap>
    );
};

export default TargetZonesBlock;
