import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Button, Image } from 'wikr-core-components';
import { OnePoint } from './subcomponents';

// img
import doctor from './img/doctor.webp';

// config
import { POINTS } from './config';

export const PracticeBlock = ({ btnHandler }: { btnHandler: () => void }) => {
    const { t } = useTranslation();

    return (
        <S.PractiseBlock dataLocator="fastingPracticeSection">
            <S.ContentContainer>
                <S.ImgWrap>
                    <Image once isForceVisible alt="doctor" src={doctor} />
                </S.ImgWrap>
                <S.Title type="h2" center text={t('welcome.practice.title')} medium />
                <S.Subtitle type="large-text" text={t('welcome.practice.subtitle')} medium />
                <S.PointsBlock>
                    {POINTS.map((point, index) => (
                        <OnePoint text={point} key={index} />
                    ))}
                </S.PointsBlock>
                <S.BtnWrap>
                    <Button
                        onClick={btnHandler}
                        textColor="action-main"
                        backgroundColor="surface-main"
                        hoverColor="surface-hovered"
                        text={t('welcome.intermittentFasting.btn')}
                        dataLocator="readMoreInAppButton"
                    />
                </S.BtnWrap>
            </S.ContentContainer>
        </S.PractiseBlock>
    );
};
