import { useTranslation } from 'react-i18next';

// components
import AdditionalQuestionModal from './components/AdditionalQuestionModal';

// config
import { getModalConfig } from './config';

// analytics
import {
    sendAnalyticQuestionnaireModalCancelClick,
    sendAnalyticQuestionnaireModalCustomCancelClick,
} from 'services/analytics';

// hooks
import { useCancelSubscription } from 'hooks';

const AdditionalQuestionContainer = () => {
    const { t } = useTranslation();
    const { discardCancellation, onCancelSubscription } = useCancelSubscription();

    const { title, optionsData } = getModalConfig(t);

    const onSubmitHandler = (fixedReason: string[], customReason?: string) => {
        sendAnalyticQuestionnaireModalCancelClick(fixedReason);
        customReason && sendAnalyticQuestionnaireModalCustomCancelClick(customReason);

        onCancelSubscription();
    };

    return (
        <AdditionalQuestionModal
            title={title}
            optionsData={optionsData}
            onSubmit={onSubmitHandler}
            onDiscard={discardCancellation}
        />
    );
};

export default AdditionalQuestionContainer;
