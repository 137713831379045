import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// router
import routes from 'router/routes';

// components
import * as S from './styled';
import { Box, Text } from 'wikr-core-components';
import OneInsight from './components/OneInsight';
import ButtonWithArrow from 'components/ButtonWithArrow';
import DashboardInsightsModal from './components/DashboardInsightsModal';
import SkeletonDashboardInsight from './components/SkeletonDashboardInsight';

// hooks
import { useBlocksByCategory } from 'hooks/insights';
import { useDashboardInsights } from './hooks/useDashboardInsights';

// constants
import { OnboardingClassNames } from 'constants/onboardingFlow';

const TRENDING_CATEGORY_ID = '5';

function DashboardInsightsBlock() {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const { isLoading, blocksByCategory } = useBlocksByCategory(TRENDING_CATEGORY_ID);
    const { currentBlockId, setCurrentBlockId, getHandleBlockClick } = useDashboardInsights();

    const [insightsAmount, setInsightsAmount] = useState(4);

    useEffect(() => {
        const width = window.innerWidth;

        if (width >= 600 && width < 905) {
            setInsightsAmount(3);
        }
    }, []);

    const insights = blocksByCategory.slice(0, insightsAmount);

    return (
        <Box dataLocator="insightsContainer" mb={48} className={OnboardingClassNames.sixth}>
            <S.TitleContainer>
                <Text text={t('dashboard.insights.title')} type="large-text" medium />
                <ButtonWithArrow
                    text="insights.seaAll"
                    btnId="dashboardInsights1"
                    onClick={() => navigate(routes.PRIVATE.INSIGHTS_MAIN.path)}
                    dataLocator="seeAllInsightsButton"
                />
            </S.TitleContainer>
            {isLoading ? (
                <S.InsightsWrap isLoading={isLoading}>
                    {Array.from(Array(4).keys()).map((_, index) => (
                        <SkeletonDashboardInsight key={index} />
                    ))}
                </S.InsightsWrap>
            ) : (
                <>
                    <S.InsightsWrap isLoading={isLoading}>
                        {insights.map((item) => (
                            <OneInsight
                                onBlockClick={getHandleBlockClick(Number(TRENDING_CATEGORY_ID))}
                                item={item}
                                key={item.id}
                            />
                        ))}
                    </S.InsightsWrap>
                    <DashboardInsightsModal
                        blockId={currentBlockId}
                        blocks={blocksByCategory || []}
                        setBlockId={setCurrentBlockId}
                    />
                </>
            )}
        </Box>
    );
}

export default DashboardInsightsBlock;
