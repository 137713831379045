import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Image } from 'wikr-core-components';

// img
import phone from './img/phone.webp';

export const HowItWorksBlock = () => {
    const { t } = useTranslation();

    return (
        <S.HowItWorksBlock backgroundColor="surface-secondary" dataLocator="howItWorksSection">
            <S.ContentContainer>
                <S.Title type="h2" center text={t('welcome.howItWorks.title')} medium />
                <S.ImgWrap>
                    <Image once isForceVisible alt="phone" src={phone} />
                </S.ImgWrap>
            </S.ContentContainer>
        </S.HowItWorksBlock>
    );
};
