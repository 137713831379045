import React from 'react';
import { useTranslation } from 'react-i18next';

// types
import { IChartPlusGoalBlockProps } from './types';

// styled
import * as S from './styled';

// components
import { Text } from 'wikr-core-components';
import ChartImg from '../ChartImg';
import ChartInfoBlock from '../ChartInfoBlock';

const ChartBlock = ({ chartImgData, infoBlockData }: IChartPlusGoalBlockProps) => {
    const { t } = useTranslation();

    const { headerText, goalText, goalReachedBg, goalNotReachedBg, period, chartData } = chartImgData;

    const DEFAULT_PERIOD = `${chartData.xData[0].date} – ${chartData.xData.at(-1)?.date}`;

    return (
        <S.ContentWrapper>
            <S.GraphicContainer>
                <S.HeaderWrapper>
                    <Text type="small-text" color="secondary-default" medium text={headerText} />
                    <S.StatisticsWrapper>
                        <Text text={goalText} type="small-text" />
                        <S.TextWithPoint>
                            <S.Point background={goalReachedBg} />
                            <Text type="small-text" text={t('dashboard.statistics.goalReached')} />
                        </S.TextWithPoint>
                        <S.TextWithPoint>
                            <S.Point background={goalNotReachedBg} />
                            <Text type="small-text" text={t('dashboard.statistics.goalNotReached')} />
                        </S.TextWithPoint>
                        <Text text={period || DEFAULT_PERIOD} type="small-text" />
                    </S.StatisticsWrapper>
                </S.HeaderWrapper>
                <S.GraphicWrapper>
                    <ChartImg data={chartData} />
                </S.GraphicWrapper>
            </S.GraphicContainer>
            <ChartInfoBlock infoBlockData={infoBlockData} />
        </S.ContentWrapper>
    );
};

export default ChartBlock;
