import styled from 'styled-components';
import { Box, Text } from 'wikr-core-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const BlockWrap = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 16px 64px;

    ${mediaQueriesGrid.tablet} {
        padding: 0 32px 80px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 160px 64px;
    }
`;

export const ColoredContainer = styled.div<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => `${backgroundColor}`};
    width: 100%;
    max-width: 844px;
    position: relative;
    border-radius: 40px;
    padding: 32px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
        padding: 64px 80px;
        border-radius: 64px;
        align-items: flex-start;
    }
`;

export const ContentBlock = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    ${mediaQueriesGrid.desktop} {
        justify-content: flex-start;
        max-width: 522px;
    }
`;

export const TitleBig = styled(Text)`
    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const Title = styled(Text)`
    ${mediaQueriesGrid.desktop} {
        font-size: 40px;
        line-height: 52px;
    }
`;

export const Subtitle = styled(Text)`
    margin-bottom: 40px;
    display: flex;

    ${mediaQueriesGrid.tablet} {
        justify-content: center;
    }

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 64px;
        justify-content: flex-start;
    }
`;

export const ImageWrap = styled.div`
    max-width: 303px;
    margin-bottom: 40px;

    ${mediaQueriesGrid.desktop} {
        max-width: 540px;
        position: absolute;
        right: 0;
        bottom: -52px;
        margin-bottom: 0;
    }
`;
