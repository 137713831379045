// styled
import { PaginationContainer, SpanPagination } from './styled';

interface IArticleHeaderProps {
    articlesLength?: number;
    currentIndex: number;
    dataLocator?: string;
}

function ArticleHeader({ articlesLength, currentIndex, dataLocator }: IArticleHeaderProps) {
    const arrLength = articlesLength ? articlesLength - 1 : 8;

    return (
        <PaginationContainer data-locator={dataLocator}>
            {Array.from(Array(arrLength).keys()).map((_, articleId) => (
                <SpanPagination key={articleId} isFilled={articleId <= currentIndex - 1 && Boolean(articlesLength)} />
            ))}
        </PaginationContainer>
    );
}

export default ArticleHeader;
