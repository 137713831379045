// hooks
import { useCancelSubscription, useSelector } from 'hooks';
import { useLifeTimeAccess } from 'hooks/subscriptions';
import { selectSubscriptions } from 'store/subscriptions/selectors';

// components
import LifeTimeAccessModal from './components/LifeTimeAccessModal';

// services
import { sendAnalyticCancelAfterLifeTimeOfferClick } from 'services/analytics';

const LifeTimeAccessQuestionContainer = () => {
    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const { onCancelSubscription } = useCancelSubscription();
    const { onGetLifeTimeAccess } = useLifeTimeAccess();

    if (!cancellationCandidate) {
        return null;
    }
    const handleCancelSubscription = () => {
        sendAnalyticCancelAfterLifeTimeOfferClick();

        onCancelSubscription();
    };

    return (
        <LifeTimeAccessModal
            isOpen
            externalId={cancellationCandidate?.externalId}
            onCancelSubscription={handleCancelSubscription}
            onGetLifeTimeAccess={onGetLifeTimeAccess}
        />
    );
};

export default LifeTimeAccessQuestionContainer;
