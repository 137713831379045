import { useTranslation } from 'react-i18next';

// images
import statusIcon1 from './img/bodyStatus1.webp';
import statusIcon2 from './img/bodyStatus2.webp';
import statusIcon3 from './img/bodyStatus3.webp';
import statusIcon4 from './img/bodyStatus4.webp';
import statusIcon5 from './img/bodyStatus5.webp';
import statusIcon6 from './img/bodyStatus6.webp';
import statusIndIcon from './img/curStatIcon.webp';

export const getContent = () => {
    const { t } = useTranslation();

    return {
        title: t('bodyStatus.title'),
        benefitLabel: t('bodyStatus.benefitLabel'),
        howToMaxLabel: t('bodyStatus.howToMaxLabel'),
        exercisesLabel: t('bodyStatus.exercisesLabel'),
        statusIndLabel: t('bodyStatus.curStateLabel'),
        statusIndIcon,
        statuses: {
            '1': {
                icon: statusIcon1,
                title: t('bodyStatus.stateTitle1'),
                hours: t('bodyStatus.stateHours1'),
                meaning: t('bodyStatus.stateMeaning1'),
                benefit: t('bodyStatus.stateBenefit1'),
                howToMax: t('bodyStatus.stateHowToMax1'),
                exercises: t('bodyStatus.stateExercises1'),
            },
            '2': {
                icon: statusIcon2,
                title: t('bodyStatus.stateTitle2'),
                hours: t('bodyStatus.stateHours2'),
                meaning: t('bodyStatus.stateMeaning2'),
                benefit: t('bodyStatus.stateBenefit2'),
                howToMax: t('bodyStatus.stateHowToMax2'),
                exercises: t('bodyStatus.stateExercises2'),
            },
            '3': {
                icon: statusIcon3,
                title: t('bodyStatus.stateTitle3'),
                hours: t('bodyStatus.stateHours3'),
                meaning: t('bodyStatus.stateMeaning3'),
                benefit: t('bodyStatus.stateBenefit3'),
                howToMax: [
                    t('bodyStatus.stateHowToMax3.liItem1'),
                    t('bodyStatus.stateHowToMax3.liItem2'),
                    t('bodyStatus.stateHowToMax3.liItem3'),
                ],
                exercises: t('bodyStatus.stateExercises3'),
            },
            '4': {
                icon: statusIcon4,
                title: t('bodyStatus.stateTitle4'),
                hours: t('bodyStatus.stateHours4'),
                meaning: t('bodyStatus.stateMeaning4'),
                benefit: t('bodyStatus.stateBenefit4'),
                howToMax: t('bodyStatus.stateHowToMax4'),
                exercises: t('bodyStatus.stateExercises4'),
            },
            '5': {
                icon: statusIcon5,
                title: t('bodyStatus.stateTitle5'),
                hours: t('bodyStatus.stateHours5'),
                meaning: t('bodyStatus.stateMeaning5'),
                benefit: t('bodyStatus.stateBenefit5'),
                howToMax: [
                    t('bodyStatus.stateHowToMax5.liItem1'),
                    t('bodyStatus.stateHowToMax5.liItem2'),
                    t('bodyStatus.stateHowToMax5.liItem3'),
                ],
                exercises: t('bodyStatus.stateExercises5'),
            },
            '6': {
                icon: statusIcon6,
                title: t('bodyStatus.stateTitle6'),
                hours: t('bodyStatus.stateHours6'),
                meaning: t('bodyStatus.stateMeaning6'),
                benefit: t('bodyStatus.stateBenefit6'),
                howToMax: [
                    t('bodyStatus.stateHowToMax6.liItem1'),
                    t('bodyStatus.stateHowToMax6.liItem2'),
                    t('bodyStatus.stateHowToMax6.liItem3'),
                    t('bodyStatus.stateHowToMax6.liItem4'),
                ],
                exercises: t('bodyStatus.stateExercises6'),
            },
        },
    };
};
