export const reasons = [
    {
        title: 'subscription.cancellationReason.noAppNeed2',
        shortKey: 'no_app_need',
    },
    {
        title: 'subscription.cancellationReason.disappointed',
        shortKey: 'disappointed_with_the_content',
    },
    {
        title: 'subscription.cancellationReason.tooExpensive2',
        shortKey: 'too_expensive',
    },
    {
        title: 'subscription.cancellationReason.autoRenew',
        shortKey: 'dont_want_to_auto-renew',
    },
    {
        title: 'subscription.cancellationReason.unawarePaymentTerms2',
        shortKey: 'unaware_payment_terms',
    },
    {
        title: 'subscription.cancellationReason.notMeetsExpectations2',
        shortKey: 'app_not_meets_expectations',
    },
    {
        title: 'subscription.cancellationReason.appDifferent',
        shortKey: 'app_different_from_ad',
    },
    {
        title: 'subscription.cancellationReason.onlyVegetarian',
        shortKey: 'only_vegetarian_recipes',
    },
    {
        title: 'subscription.cancellationReason.other',
        shortKey: 'other',
    },
];
