import { FC } from 'react';
import { Text } from 'wikr-core-components';

// styled
import * as S from './styled';
import { Download } from 'components/Icon';

// analytics
import { sendAnalyticDownloadGuideClick } from 'services/analytics';

interface GuideProps {
    img?: string;
    link?: string;
    title: string;
    name?: string;
    dataLocator?: string;
}

export const Guide: FC<GuideProps> = ({ title, img, link, name, dataLocator }) => {
    const onDownloadClick = () => name && sendAnalyticDownloadGuideClick(name);

    return (
        <S.Container>
            {img && <S.GuideImg alt="guide-img" src={img} />}
            <S.GuideTitleContainer>
                <Text center={!img} type="medium-text" text={title} />
            </S.GuideTitleContainer>
            {link && (
                <S.DownloadGuideLink
                    href={link}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={onDownloadClick}
                    data-locator={dataLocator}
                >
                    <Download />
                </S.DownloadGuideLink>
            )}
        </S.Container>
    );
};
