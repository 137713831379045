import { GENDERS } from 'constants/user';
import { uaParser } from 'wikr-core-analytics';

const UAParser = new uaParser();

export const isEmpty = (value: null | undefined | string | number | object): boolean => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0) ||
        (typeof value === 'number' && Number(value) === Infinity)
    );
};

export const replaceUnderscoreToDash = (string: string): string => string.replace(/_/g, '-');

export const isMaleGender = (gender: string | null): boolean => {
    return gender === GENDERS.MALE;
};

export const getToFixedNumber = (number: string | number, numbersAfterComma = 2): number => {
    const numberValue = Number(number);

    return Number(numberValue.toFixed(numbersAfterComma));
};

export const isSafari = () => {
    const userAgent = UAParser.setUA(window.navigator.userAgent);
    const deviceBrowser = userAgent?.getBrowser().name;

    return deviceBrowser === 'Safari' || deviceBrowser === 'Mobile Safari';
};

export const getParamFromUrl = (paramName: string) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};
