import styled from 'styled-components';
import { Box, Text } from 'wikr-core-components';

import { CONTENT_1 } from 'constants/customContentColors';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const WantToTryBlock = styled(Box)<{ isShow: boolean }>`
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-row-start: 1;
    grid-column-start: 1;
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    padding: 44px 0 12px;

    transition: opacity 0.5s, height 0.5s;

    ${mediaQueriesGrid.desktop} {
        margin-top: 160px;
    }
`;

export const ColoredContainer = styled.div`
    background-color: ${CONTENT_1};
    width: 100%;
    max-width: 844px;
    position: relative;
    border-radius: 40px;
    padding: 32px 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
        padding: 64px 128px 80px;
        border-radius: 64px;
    }
`;

export const Title = styled(Text)`
    font-size: 36px;
    line-height: 44px;

    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const ImageWrap = styled.div`
    max-width: 128px;
    margin-bottom: 32px;
`;
