import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Image, Text } from 'wikr-core-components';

// config
import { WEEK_RECIPES } from './config';

// helpers
import { getGuideForMyFastingPlan } from 'helpers/getGuideForMyFastingPlan';

// img
import guide from './img/guide.webp';
import { ReactComponent as DownloadSVG } from './img/Download.svg';

interface IMealsBlockProps {
    handleGuideClick: () => void;
    lang: string;
}

const MealsBlock = ({ handleGuideClick, lang }: IMealsBlockProps) => {
    const { t } = useTranslation();

    return (
        <S.ContentWrap
            backgroundColor="on-primary"
            paddingX={8}
            paddingTop={20}
            paddingBottom={8}
            borderRadius={24}
            mb={112}
        >
            <Text center type="large-text" medium text={t('myFastingPlan.mealsBlock.title')} mb={20} />
            <S.ListWrap>
                {WEEK_RECIPES.map((recipe, index) => (
                    <S.ListComponent key={index}>
                        <Image isForceVisible once maxWidth={160} src={recipe.img} alt="recipe" />
                        <S.DayNameWrap
                            paddingTop={2}
                            paddingBottom={2}
                            paddingX={12}
                            backgroundColor="on-primary"
                            borderRadius={20}
                        >
                            <Text type="small-text" medium text={t(recipe.dayName)} />
                        </S.DayNameWrap>
                    </S.ListComponent>
                ))}
                <S.ListComponent>
                    <Image isForceVisible once maxWidth={160} src={guide} alt="guide" />
                    <S.LinkBlockWrap>
                        <S.LinkText
                            type="caption"
                            bold
                            color="text-secondary"
                            text={t('myFastingPlan.mealsBlock.linkTitle')}
                            mb={12}
                        />
                        <S.PDFLink
                            href={getGuideForMyFastingPlan(lang)}
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={handleGuideClick}
                            data-locator="downloadRecipesButton"
                        >
                            <DownloadSVG />
                        </S.PDFLink>
                    </S.LinkBlockWrap>
                </S.ListComponent>
            </S.ListWrap>
        </S.ContentWrap>
    );
};

export default MealsBlock;
