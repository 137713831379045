import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';

// config
import { FASTING_TYPES } from './config';
import { FastingType } from './subcomponents';

export const TrueCheatFastingBlock = () => {
    const { t } = useTranslation();

    return (
        <S.TrueCheatFastingBlock dataLocator="trueFastingSection">
            <S.ContentContainer>
                <S.Title type="h2" center text={t('welcome.trueCheat.title')} medium mb={40} />
                <S.FastingTypesWrap>
                    {FASTING_TYPES.map((type, index) => (
                        <FastingType
                            key={index}
                            svg={type.svg}
                            points={type.points}
                            emoji={type.emoji}
                            name={type.name}
                        />
                    ))}
                </S.FastingTypesWrap>
            </S.ContentContainer>
        </S.TrueCheatFastingBlock>
    );
};
