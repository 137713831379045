import React from 'react';

// hooks
import { useSelector } from 'hooks';

// store
import { createProcessingSelector } from 'store/loading/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';
import { selectDeepLink } from 'store/deepLink/selectors';

// types
import { InstallAppButtonProps } from './types';

// styled
import * as S from './styled';

const InstallAppButton: React.FC<InstallAppButtonProps> = ({
    type,
    isMobile = false,
    dataLocator,
    smallBtn = false,
}) => {
    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));
    const deepLinkUrl = useSelector(selectDeepLink);

    return (
        <>
            {isLoading ? (
                <S.SkeletonWrapper isMobile={isMobile} width={192} height={64} borderRadius={8} />
            ) : (
                <S.StoreBtn
                    isMobile={isMobile}
                    smallBtn={smallBtn}
                    link={deepLinkUrl}
                    type={type}
                    dataLocator={dataLocator}
                />
            )}
        </>
    );
};

export default InstallAppButton;
