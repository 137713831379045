import React from 'react';
import { useTranslation } from 'react-i18next';

// styled
import * as S from './styled';

// components
import TabBar from 'pages/body-status/subcomponents/TabBar';
import DemoBodyStatus from './components/DemoBodyStatus';
import DetailsButton from './components/DetailsButton';

// constants
import { OnboardingClassNames } from 'constants/onboardingFlow';

interface BodyStatusBlockProps {
    isDemo: boolean;
}

const BodyStatus = ({ isDemo }: BodyStatusBlockProps) => {
    const { t } = useTranslation();

    return (
        <S.Container className={OnboardingClassNames.fourth}>
            {isDemo ? (
                <DemoBodyStatus />
            ) : (
                <>
                    <S.Title type="h5" medium text={t('dashboard.bodyStatus.title')} />
                    <S.Footer>
                        <TabBar isDashboardBlock />
                        <DetailsButton />
                    </S.Footer>
                </>
            )}
        </S.Container>
    );
};

export default BodyStatus;
