import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// components
import * as S from './styled';
import { Text } from 'wikr-core-components';

interface IFastingTypeProps {
    emoji: () => ReactElement;
    name: string;
    points: string[];
    svg: () => ReactElement;
}

export const FastingType = ({ emoji, name, points, svg }: IFastingTypeProps) => {
    const { t } = useTranslation();

    return (
        <S.FastingType backgroundColor="surface-secondary" borderRadius={40}>
            <S.EmojiWrap>{emoji()}</S.EmojiWrap>
            <S.Title center medium type="h5" text={t(name)} />
            {points.map((point, index) => (
                <S.PointWrap key={index}>
                    <S.SVGWrap>{svg()}</S.SVGWrap>
                    <Text type="large-text" text={t(point)} />
                </S.PointWrap>
            ))}
        </S.FastingType>
    );
};
