import { useEffect } from 'react';

// components
import BlockPreview from 'components/BlockPreview';
import Modal from 'components/Modal';
import { SmallBlockPreview } from './components/SmallBlockPreview';

// types
import { IBlockByIdArticlesItem, IBlockInsightItem, IBlockItem } from 'types/insights';

// styled
import { BlockPreviewWrap, ModalContentWrapper } from './styled';

// hooks
import { useDispatch, useSelector } from 'hooks';

// redux
import {
    changeCurrentNextBlockPreview,
    changeCurrentPrevBlockPreview,
    fetchNextBlockPreview,
    fetchPrevBlockPreview,
    resetBlockPreview,
} from 'store/insights/actions';

// z-index need to rewrite front chat
const MODAL_OVERLAY_STYLES = { padding: '80px 0 0 0', zIndex: '999999999' };
const MODAL_CONTENT_STYLES = {
    maxWidth: '100%',
    width: '100%',
    height: 'calc(100% - 40px)',
    marginTop: '20px',
    padding: '0',
    borderRadius: '20px 20px 0 0',
};

const TO_NEXT_BLOCKS_DIFF = 3;

interface CategoryModalProps {
    isOpenModal: boolean;
    hasMoreBlocks?: boolean;
    onClose: () => void;
    handleNextBlocks?: () => void;
    articles: IBlockByIdArticlesItem[];
    blockPreviewColor: string;
    skeletonColor: string;
    blockId: number | null;
    blocksByCategory: IBlockItem[] | IBlockInsightItem[];
}

function CategoryModal({
    isOpenModal,
    onClose,
    articles,
    blockPreviewColor,
    skeletonColor,
    handleNextBlocks,
    hasMoreBlocks,
    blocksByCategory,
}: CategoryModalProps) {
    const dispatch = useDispatch();
    const { blockPreview, isNextLoading, isPrevLoading, blockPrevPreview, blockNextPreview } = useSelector(
        ({ insights }) => insights
    );

    const blockPreviewIndex = blocksByCategory.findIndex((block) => block.id === blockPreview?.id);

    const isFirst = blockPreviewIndex === 0;
    const isLast = blockPreviewIndex === blocksByCategory.length - 1;

    useEffect(() => {
        if (isOpenModal && isLast && hasMoreBlocks) {
            handleNextBlocks?.();
        }
    }, [blockPreviewIndex]);

    const handleClose = () => {
        onClose();
        setTimeout(() => dispatch(resetBlockPreview()), 200);
    };

    const handleDownloadNextBlocks = () => {
        handleNextBlocks && blockPreviewIndex === blocksByCategory.length - TO_NEXT_BLOCKS_DIFF && handleNextBlocks();
    };

    const handleNextClick = () => {
        if (!isNextLoading && Boolean(blockNextPreview)) {
            handleDownloadNextBlocks();

            dispatch(changeCurrentNextBlockPreview());
        }
    };
    const handlePrevClick = () => {
        if (!isPrevLoading && Boolean(blockPrevPreview)) {
            dispatch(changeCurrentPrevBlockPreview());
        }
    };

    const loadingStatusForSkeletons = !(isOpenModal && blockPreviewIndex >= 0);

    return (
        <Modal
            withCloseButton
            withCloseBtnForInsights
            closeOnOverlayClick
            isOpen={isOpenModal}
            onClose={handleClose}
            style={{
                content: MODAL_CONTENT_STYLES,
                overlay: MODAL_OVERLAY_STYLES,
            }}
        >
            <ModalContentWrapper>
                <SmallBlockPreview
                    isLoading={loadingStatusForSkeletons}
                    isLoadingBlock={isPrevLoading}
                    block={blocksByCategory[!isFirst ? blockPreviewIndex - 1 : blocksByCategory.length - 1]}
                    onClick={handlePrevClick}
                    isOpenModal={isOpenModal}
                    fetchSmallBlockPreview={fetchPrevBlockPreview}
                />
                <BlockPreviewWrap>
                    <BlockPreview
                        isPreviewLayoutLoading={loadingStatusForSkeletons}
                        articles={articles || []}
                        blockPreviewColor={blockPreviewColor || ''}
                        skeletonColor={skeletonColor}
                        onNextClick={handleNextClick}
                        onPrevClick={handlePrevClick}
                        withStoryEnd={false}
                    />
                </BlockPreviewWrap>
                <SmallBlockPreview
                    isLoading={loadingStatusForSkeletons}
                    isLoadingBlock={isNextLoading}
                    block={blocksByCategory[!isLast ? blockPreviewIndex + 1 : 0]}
                    onClick={handleNextClick}
                    isOpenModal={isOpenModal}
                    fetchSmallBlockPreview={fetchNextBlockPreview}
                />
            </ModalContentWrapper>
        </Modal>
    );
}

export default CategoryModal;
