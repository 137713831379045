import { useSelector } from 'hooks';

const getSeparatePrice = (amount: number) => {
    const [integer, cents] = `${Number(amount) / 100}`.split('.');

    return { integer, cents };
};

export const useSpecialOfferModal = (externalId: string) => {
    const { subscriptions, discountSubscription, isDiscountLoading } = useSelector(
        ({ subscriptions }) => subscriptions
    );

    const cancelCandidate = subscriptions.find(({ external_id }) => external_id === externalId);

    if (!cancelCandidate || !discountSubscription) {
        return { candidatePriceData: null, discountPriceData: null, isDiscountLoading };
    }

    const product = cancelCandidate.product;

    const candidatePriceData = {
        currency: product.currency,
        period: product.subscription_period,
        ...getSeparatePrice(product.amount),
    };
    const discountPriceData = {
        currency: discountSubscription?.currency,
        ...getSeparatePrice(discountSubscription?.amount),
    };

    return { candidatePriceData, discountPriceData, isDiscountLoading };
};
