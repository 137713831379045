import { Trans } from 'react-i18next';

// components
import * as S from './styled';
import { Text } from 'wikr-core-components';

// img
import { ReactComponent as Cross } from './img/Cross.svg';

// types
import { IOnePointProps } from './types';

export const OnePoint = ({ text }: IOnePointProps) => {
    return (
        <S.OnePointWrap>
            <S.CheckWrap>
                <Cross />
            </S.CheckWrap>
            <Text type="large-text">
                <Trans i18nKey={text} components={{ bold: <b /> }} />
            </Text>
        </S.OnePointWrap>
    );
};
