import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

// styled
import * as S from './styled';

interface SidebarNavButtonProps {
    to: string;
    title: string;
    icon?: string;
    dataLocator?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const SidebarNavButton = ({ to, title, icon, onClick, dataLocator }: SidebarNavButtonProps) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const isActive = pathname === to || pathname.startsWith(`${to}/`);

    return (
        <NavLink to={to} data-locator={dataLocator} onClick={onClick}>
            <S.NavButton isActive={isActive}>
                {icon && <S.Icon alt="icon" src={icon} />}
                <S.BtnText ellipsis={1} text={t(title)} />
            </S.NavButton>
        </NavLink>
    );
};

export default SidebarNavButton;
