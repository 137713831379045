import { CategoryContainer } from './styled';

// helpers
import { getBlocksSize } from 'pages/insights-main/helpers';

// components
import CategoryBlock from '../CategoryBlock';

// types
import { IBlockInsightItem } from 'types/insights';

interface CategoryListProps {
    blocks: IBlockInsightItem[];
    insightIndex: number;
    handleBlockClick: (blockId: number) => void;
}

function CategoryList({ blocks, insightIndex, handleBlockClick }: CategoryListProps) {
    const blockSize = getBlocksSize(insightIndex);

    return (
        <CategoryContainer blockSize={blockSize}>
            {blocks.map((item, index) => (
                <CategoryBlock
                    onBlockClick={handleBlockClick}
                    block={item}
                    index={index}
                    blockSize={blockSize}
                    key={item.id}
                />
            ))}
        </CategoryContainer>
    );
}

export default CategoryList;
