import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

// mainTrackers
import { trackClick } from './mainTrackers';

export const sendAnalyticSideBarElementClick = (actionEvent: ActionEventAction) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent,
    });
};

export const sendAnalyticSideBarLogoutClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.logOut,
    });
};
