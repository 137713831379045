import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

// services
import Sentry from 'services/Sentry/SentryInstance';

import App from './App';

import reportWebVitals from './reportWebVitals';

// store
import { store } from 'store/configureStore';

Sentry.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

reportWebVitals(() => {});
